<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <form v-on:submit.prevent="updateAnnouncement" class="form-fix">
        <span class="form-box-title">{{$t('announcement.announcement_text')}}</span>
        <div id="announcement-form-box" class="form-box">
          <span class="form-box-name">{{$t('announcement.title_text')}}</span><b-form-input v-model="announcement.title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" id="announcement-title" class="form-box-input" required></b-form-input>
          <span class="form-box-name">{{$t('announcement.description_text')}}</span><b-form-textarea v-model="announcement.message" pattern="[^<>]*" :title="$t('global.pattern_title')" id="announcement-description" placeholder="" class="form-box-input" :rows="2" :max-rows="2" />
          <span class="form-box-name">{{$t('announcement.date_text')}}</span>
          <b-form-datepicker id="announcement-day" v-model="announcement.day" :locale="$i18n.locale"></b-form-datepicker>
          <div id="announcement-check">
            <b-form-checkbox v-model="announcement.disabled" value="1" unchecked-value="0"> 
              {{$t('announcement.disabled_text')}}
            </b-form-checkbox>
          </div>

        </div>
        <div class="submit-box-2">
          <b-btn v-b-modal.modal-center v-if="announcement.id>0" class="action-button" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-btn>
          <b-button class="action-button" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('announcement.delete_title')">
          <p class="my-4">{{$t('announcement.delete_text')}}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="deleteAnnouncement" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
      </form>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
#annoucement-title{
  max-width: 300px;
}

#annoucement-description{
  margin-bottom: 10px;
}

#announcement-day{
  margin: 5px 0px;
}

#announcement-form-box{
  grid-template-columns: 20% 70%;
}

  #announcement-check {
    grid-column-start: 2;
    grid-column-end: 4;
    margin: 10px;
    color: #999;
  }
  
</style>
<script>
    
  import router from "../router"
    export default {
    name: "Announcement",
    watch: {
      'announcement': {
        handler: function () {
          this.saveVariant = 'blue';
        },
        deep: true
      }
    },
      data() {
        return {
          showDelete: false,
          saveVariant : 'blue',
          selected: [],
          announcement: { id: 0, title: "", message: "", disabled: 0, day: new Date().toISOString().slice(0, 10)}
        }
      },
    methods: {
      getAnnouncement() {
        this.announcement.id = this.$route.params.id;
        if (this.announcement.id > 0) {
          this.$http.post("/api/organization/announcement/get", this.announcement).then((response) => {
            if (response.data.success) {
              this.announcement = response.data.announcement;
            }
          }).catch(() => { });
        }
      },
      updateAnnouncement() {
        this.$http.post("/api/organization/announcement/update", this.announcement).then((response) => {
          if (response.data.success === true) {
            this.saveVariant = 'success';
            this.back();
          } else {
            this.saveVariant = 'danger';
          }
        }).catch(() => { this.$bvToast.show('serverError');});
      },
      deleteAnnouncement() {
        this.$http.post("/api/organization/announcement/delete", this.announcement).then((response) => {
          if (response.data.success === true) {
            this.back();
          }
        }).catch(() => { }); 
      },
      back() {
        window.history.length > 1 ? router.go(-1) : router.push("/admin/announcements");
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-announce"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-announce"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.getAnnouncement();
      }
    }
</script>
