<template>
<div v-if="tip.disabled==0 && this.permissions.organization.official && tip.content && tip.content.length>0" :style="{'max-width': tip.width+'px'}" class="auto-margin">
    <div class="inner-wrapper">
        <b-alert class="tip-alert" :show="true" variant="light" dismissible @dismissed="hideTip">
          <div class="inner-alert-wrapper">
            <img class="tip-image" src="../assets/icon-Tip.png" /><span class="tip-title">{{tip.title}}</span>
            <span class="tip-text pre-format tip-content" v-html="tip.content"></span>
          </div> 
        </b-alert>
    </div>
  </div>
</template>
<style scoped>
.tip-content{
  grid-column:1/3;font-size: 14px;
}
.tip-title{
  line-height: 40px;margin-left: 10px;
}
.tip-image{
  height:30px;
}
.inner-alert-wrapper{
  display:grid; grid-template-columns: 30px auto;
}
.tip-alert{
  border:1px solid #999;
}
.inner-wrapper{
  margin-top: 20px;text-align:left
}
.tip-text{
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
<script>
    export default {
    name: "Tip",
     props: {tipNumber: Number},
     watch: {
    '$i18n.locale': { 
      handler: function () {
          this.getTipEnabled();
      },
      deep: true
    }
  },
  data() {
    return {
      tip: {width: 650, content: "", title: "", disabled: 1}
    };
  },
   mounted() {
     this.getTipEnabled();
  },
  destroyed(){
     this.permissions.help_url = "";
  },
  methods: {
     getTipEnabled() {
       this.$http.post("/api/organization/tips/get",{n:this.tipNumber}).then(response  => {
        if(response.data.success){
          this.tip = response.data.tip;
          this.permissions.help_url = this.tip.url? this.tip.url : response.data.tip_url;
          this.tip.disabled = (this.getCookie("tips",0) & (Math.pow(2,this.tipNumber-1)));
        }else{
          this.tip.disabled = 1;
          this.permissions.help_url = response.data.tip_url;
        }
        
      }).catch((errors) => {console.log(errors)});
    },
    hideTip(){
      this.setCookie("tips", this.getCookie("tips",0) | (Math.pow(2,this.tipNumber-1)));
    }
  }
}
</script>