<template>
  <div>
    <Header v-if="memberReturned" :resethash="this.$route.params.h" :title="has_pass? $t('reset.reset_login') : $t('reset.create_login')" :showDate="false"></Header>
    <div v-if="memberFound && memberReturned" id="reset-page-wrapper">
      <div id="reset-description">
        <div class="fit-width auto-margin">
          <span id="member-name" class="form-box-name">{{$t('reset.member_text')}}:</span> <b>{{member_name}}</b>
        </div>
      </div>
      <form v-on:submit.prevent="checkAndSetPassword" class="form-fix">
        <span class="form-box-title">{{has_pass? $t('reset.password_reset') : $t('reset.create_password')}}</span>
        <div class="form-box grid-auto-2column">
          <span class="form-box-name">{{$t('reset.username_text')}}:</span><span id="reset-name" class="form-box-input">{{admin? member_name : email}}</span>
          <span class="form-box-name">{{$t('reset.new_password')}}</span><b-form-input :state="password.length==0? null : password.length>=8 " v-model="password" type="password" class="form-box-input" required></b-form-input>
          <span class="form-box-name">{{$t('reset.retype_password')}}</span><b-form-input :state="password.length==0? null : (password===password_retype && password_retype.length>0)" v-model="password_retype" type="password" class="form-box-input" required></b-form-input>
          <span id="password-note">*{{$t('global.password_requirement')}}</span>
        </div>
        <div v-if="showAlert" >
          <b-alert variant="danger"
                   dismissible
                   :show="showAlert"
                   @dismissed="showAlert=false">
            {{alertText}}
          </b-alert>
        </div>
        <div class="submit-box-2">
          <b-button id="set-password-button" class="action-button" type="submit" variant="clear-blue"><div class="login-button"></div><span>{{$t('reset.set_password')}}</span></b-button>
        </div>
      </form>
      <div class="div-space"></div>
    </div>
    <div v-if="!memberFound && memberReturned" id="reset-alert-wrapper">
          <b-alert variant="warning"
                   :show="true">
            {{$t('reset.link_expired')}}
          </b-alert>
    </div>
  </div>
</template>
<style scoped>
#reset-alert-wrapper{
  width: 400px; margin: 50px auto;
}
#set-password-button{
  margin:20px auto;
}
#password-note{
  font-size: 12px;text-align:left;color:#aaa;grid-column: 2/3;
}
#reset-name{
  margin: 10px 5px; color:#555;
}
#member-name{
  margin: 0px 5px;
}
#reset-page-wrapper{
  top: 40px;position: relative;
}
#reset-description{
  background-color: #eeeeee;padding: 10px; margin: 20px auto; width: 800px;
}
#rest-description >div{
  display: grid;grid-template-columns: max-content max-content;margin: auto;
}
#reset-logo-box{
  display: grid;grid-template-columns: max-content max-content max-content;grid-gap: 20px;margin: auto; 
}
  
</style>
<script>
    
    import router from "../router"
    export default {
    name: "Password",
      data() {
        return {
          saveVariant: 'blue',
          showAlert: false,
          password: "",
          password_retype: "",
          alertText: "",
          member_name: "",
          organization_name: "",
          has_pass: false,
          admin: false,
          email: "",
          memberFound: false,
          memberReturned: false
        }
      },
    methods: {
      getMember() {
        var hash = this.$route.params.h;
        this.$http.post("/api/member/get/bypasswordhash", {hash: hash}).then((response) => {
          if (response.data.success == true) {
            this.memberFound = true;
            this.member_name = response.data.member.member_name;
            this.organization_name = response.data.member.organization_name;
            this.has_pass = response.data.member.has_pass==1;
            this.admin = response.data.member.admin==1;
            this.email = response.data.member.email;
          } 
          this.memberReturned = true;
        }).catch(() => { this.memberReturned= true;});
      },
      checkAndSetPassword(){
        var hash = this.$route.params.h;
        this.$http.post("/api/member/check/account/byhash", {hash: hash}).then((response) => {
          if (response.data.success && response.data.account) {
            this.showAlert = true;
            this.alertText = this.$t('reset.account_error');
          }else{
            this.setPassword();
          }
        }).catch(() => {
          
        });
      },
      setPassword() {
        var hash = this.$route.params.h;
        this.showAlert = false;
        if(this.password.length<8){
          this.showAlert = true;
          this.alertText = this.$t('reset.alert_length');
          return;
        }
        if (this.password === this.password_retype) {
          this.$http.post("/api/password/update", { hash: hash, new_pass: this.password, new_pass_retype: this.password_retype }).then((response) => {
            if (response.data.success == true) {
              router.push("/login");
            } else {
              this.showAlert = true;
              this.alertText = response.data.error;
            }
          }).catch(() => { this.$bvToast.show('serverError');});
        } else {
          this.showAlert = true;
          this.alertText = this.$t('reset.alert_match');
        }
      }
    },
    mounted() {
      this.getMember();
    }
    }
</script>
