<template>
  <div id="public-container" :style="{'grid-template-rows': isCorporateConnections? 'auto 150px max-content auto' : 'auto max-content auto'}">
      <div></div>
      <div v-if="isCorporateConnections"><img id="cc-banner" src="../assets/public-cc-banner.png" /></div>
      <div id="event-page-wrapper">
        <div id="event-organization-name">
            {{event.organization_name}}
        </div>
        <div id="event-wrapper" class="fit-width">
            <img v-if="event.image" id="event-image" :src="event.image"/>
            <div id="event-info-box">
                <div class="event-title" :style="{'color': isCorporateConnections? '#FFD700' : '#CF2030'}">{{event.title}}</div>
                <div id="event-description">{{event.when_description}}</div>
                <br/>
                <div class="left-text">{{event.description}}</div>
                <div><a :href='(event.link.startsWith("http") ? "" : "http://") + event.link' target="_blank">{{event.link}}</a></div>
            </div>
        </div>
        <div id="event-ask">
           {{event.ask}}
        </div>
      </div>
      <div></div>
  </div>
</template>
<style scoped>
#event-ask{
  color: #777;font-size: 20px;
}
#event-description{
  font-size: 35px;
}
#event-info-box{
  font-size: 30px;min-width: 400px;text-align: center;line-height: 35px;
}
#event-image{
  height:200px;margin: 20px auto;
}
#event-wrapper{
  display:grid;grid-template-rows: auto auto;padding: 40px 0;margin:auto;display: grid;
}
#event-organization-name{
  font-size: 40px; text-align: center;font-weight: bold;
}
#event-page-wrapper{
  max-width: 800px; margin: auto;
}
#cc-banner{
  margin:auto;display:block;
}
#public-container{
  display:grid;  height: 100%; 
}
.event-title{
  font-size: 40px;font-weight:bold;margin-bottom:10px;
}
</style>
<script>
    export default {
    name: "PublicEvent",
      data() {
        return {
            event: {title: "", when_description: '', description: '', link: '',image: '', organization_name: ''}
        }
      },
    methods: {
      getEvent(){
          var hash = this.$route.params.h;
          this.$http.post("/api/organization/event/get/bypublichash", {hash: hash}).then((response) => {
            this.event = response.data.event;
        }).catch(() => {});
      }
    },
    mounted() {
        this.getEvent();
    }
    }
</script>
