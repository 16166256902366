<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <form v-on:submit.prevent="updateLanguage" class="form-fix">
        <span class="form-box-title">{{$t('language.language_text')}}</span>
        <div id="language-box" class="form-box">
          <span class="form-box-name">{{$t('language.name_text')}}</span><b-form-input :state="states.name" v-model="language.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input" required></b-form-input>
          <span class="form-box-name">{{$t('language.abbrev_text')}}</span><b-form-input :disabled="language.id>0" :state="states.abbrev" v-model="language.abbrev" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input" required maxlength="2"></b-form-input>
          <span class="form-box-name">{{$t('language.flag_text')}}</span>
          <b-dropdown id="flag-dropdown" class="fit-width" size="lg"  variant="link" toggle-class="no-padding" no-caret >
          <template v-slot:button-content >
            <img :src="getImgUrl('flags/'+language.flag+'.png')" v-bind:alt="language.flag+' flag'">
          </template>
            <b-dropdown-item-button v-for="c in countries" :key="c.alpha_2" v-on:click="language.flag= c.alpha_2"><img :src="getImgUrl('flags/'+c.alpha_2+'.png')" v-bind:alt="c.name+' flag'"></b-dropdown-item-button>
          </b-dropdown>
        </div>
        <div v-if="showAlert">
            <b-alert variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false">
            {{this.$t('language.alert_text')}}
            </b-alert>
        </div>
        <div class="submit-box-2">
          <b-button v-b-modal.modal-center v-if="language.id>0" class="action-button" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          <b-button class="action-button" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('language.delete_title')">
          <p class="my-4">{{$t('language.delete_text')}}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="deleteLanguage" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
      </form>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style>
.no-padding{
  padding: 0px;
}
#flag-dropdown .dropdown-menu.show{
 display: grid !important;grid-template-columns: repeat(5,1fr) !important;max-height: 400px !important;overflow: auto !important;top: 32px !important;transform: translateX(-170px);
}
#language-box{
  grid-template-columns: repeat(3,1fr);
}
#language-box .dropdown:hover .dropdown-menu {
  display: grid !important;grid-template-columns: repeat(5,1fr) !important;max-height: 400px !important;overflow: auto !important;top: 32px !important;transform: translateX(-170px);
}
</style>
<script>
    
  import router from "@/router"
    export default {
    name: "Language",
   
      data() {
        return {
          showAlert: false,
          showDelete: false,
          countries: [],
          language: {id: 0, name: '', flag: 'ca', abbrev: ''},
          states: {name: null,abbrev: null}
        }
      },
        methods: {
          getCountries(){
              this.$http.post("/api/countries", this.language).then((response) => {
                if (response.data.success) {
                  this.countries = response.data.countries;
                }
              }).catch(() => {});
          },
          getLanguage() {
            this.language.id = this.$route.params.id;
            if (this.language.id > 0) {
              this.$http.post("/api/superadmin/language/get", this.language).then((response) => {
                if (response.data.success) {
                  this.language = response.data.language;
                }
              }).catch(() => {});
            } 
          },
          checkData(){
              this.showAlert = false;
              this.states ={
                  name: null,
                  abbrev: null
              };
              var allgood = true;
              if(this.language.name.length==0){
                  allgood = false;
                  this.states.name = false;
              }
             if(this.language.abbrev.length==0){
                  allgood = false;
                  this.states.abbrev = false;
              }
              this.language.abbrev = this.language.abbrev.toLocaleLowerCase();
              this.showAlert = !allgood;
              return allgood;
          },
          updateLanguage() {
            if(!this.checkData()){
              return;
            }
            this.$http.post("/api/superadmin/language/update", this.language).then((response) => {
              if (response.data.success === true) {
                this.saveVariant = 'success';
                this.back();
              } else {
                this.saveVariant = 'danger';
              }
            }).catch(() => {this.$bvToast.show('serverError');});
          },
          deleteLanguage() {
            this.$http.post("/api/superadmin/language/delete", this.language).then((response) => {
              if (response.data.success === true) {
                this.back();
              }
            }).catch(() => {});
          },
          back(){
            window.history.length > 1 ? router.go(-1) : router.push("/admin/languages");
          }
    },
     destroyed() {
      this.removeClass(document.getElementById("menu-languages"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-languages"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.getCountries();
      this.getLanguage();
    }
    }
</script>
