<template>
  <div >
      <div class="rel-grid-container">
      <div ></div>
      <div >
        <div id="assistant-search-options" class="fit-width">
            <span class="form-box-name" >{{$t('assistant.member_filter')}}</span>
            <b-form-input  v-model="asksFilter.member" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"></b-form-input>
            <span class="form-box-name">{{$t('assistant.dates_text')}}</span>
            <multiselect style="min-width: 300px;" v-model="asksFilter.searchDates" :options="dates" :placeholder="$t('general.filter_dates')" label="day" track-by="day" class="blueselect" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="day" :close-on-select="false" :searchable="true">
              <template v-slot:option="{ option }">
                    {{option.day? $d(new Date("" + option.day.split("-").join("/") + " 00:01"), 'short') : "date error"}}
                </template>
            </multiselect>
        </div>
        <b-table class="table-hover-new" small bordered :items="asks" :fields="fields" :per-page="perPage" @filtered="onFiltered" :filter="asksFilter" :filter-function="tableFilter" :current-page="currentPage" >
            <template v-slot:cell(day)="data" >
                <span class="no-wrap-text">{{data.item.day? $d(new Date("" + data.item.day.split("-").join("/") + " 00:01"), 'short') : ""}}</span>
            </template>
            <template v-slot:cell(asks)="data" >
              <div v-for="(ask, index) in data.item.asks" :key="index">
                <pre v-if="ask != ''" class="pre-format assistant-ask">{{index+1}}. {{ask}}</pre>
              </div>
            </template>
            <template v-slot:cell(helpers)="data" >
              <div v-for="(helper, index) in data.item.helpers" :key="index">
                <span v-if="helper != ''" class="no-wrap-text">{{index+1}}. {{helper}}</span> 
              </div>
            </template>
              <template v-slot:cell(connections)="data" >
                <div v-for="(connection, index) in data.item.connections" :key="index">
                    <b>{{connection.name}}</b><span v-if="connection.company"> > {{ connection.company }}</span> <span v-if="connection.relationship">({{ $t('assistant.assistant_relationship'+connection.relationship) }})</span><span v-if="connection.info">: <i>{{ connection.info }}</i></span>
                  <br/>
                  </div>
            </template>
            <template v-slot:cell(type)="data" >
              <span v-if="data.item.type==1">{{ $t('global.yes_text') }}</span>
            </template>
            <template v-slot:cell(purpose)="data" >
              <span v-if="data.item.purpose">{{ $t('assistant.assistant_purpose'+data.item.purpose) }}</span>
            </template>
            <template v-slot:cell(contact)="data" >
              <span v-if="data.item.type==1">{{ $t(data.item.contact==1? 'global.yes_text': 'global.no_text') }}</span>
            </template>
        </b-table>
        <b-pagination  v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0" ></b-pagination>
        <br/><br/>
        <b-alert variant="success" dismissible :show="showSuccess"  @dismissed="showSuccess=false">
          {{$t('attendance.export_success')}}
        </b-alert>
        <b-alert variant="warning" dismissible :show="showAlert"  @dismissed="showAlert=false">
          {{$t('attendance.export_failure')}}
        </b-alert>
        <div id="assistant-buttons" class="fit-width">
            <b-button class="action-button auto-margin" v-if="!showSuccess" v-on:click="getAsksExport" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_export')}}</span></b-button>
            <div v-if="exportFile.filename.length>0 && showSuccess" class="fit-width auto-margin"> 
                <b-button class="action-button" v-on:click="downloadAsksExport" variant="clear-blue" ><div class="import-button"></div><span>{{$t('attendance.download_export')}}</span></b-button>
                <a id="export-file" :download="exportFile.filename" :href="exportFile.content" hidden></a>
            </div>
        </div>
      </div>
      <div></div>
      </div>
  </div>
</template>
<style scoped>
#assistant-search-options{
  display:grid; grid-template-columns: repeat(4,max-content); margin: auto;margin-bottom:20px;
}

.assistant-ask{
  font-family: Avenir,Helvetica,Arial,sans-serif;font-size:100%;
}

#assistant-buttons{
  display:grid; grid-template-columns: max-content max-content; margin: auto;
}

</style>

<script>

export default {
  name: "AssistantAsks",
  data() {
        return {
            asksFilter: {searchDates: [], member: ""},
            perPage: 20,
            currentPage:1,
            totalRows:0,
            showAlert: false,
          showSuccess: false,
          exportFile: {filename: '', content: ''},
            dates: [],
           asks: [],
           fields: [
               { key: 'day',
              label: this.$t('assistant.date_text'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'name',
              label: this.$t('assistant.name_text'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
             { key: 'company',
              label: this.$t('assistant.company_text'),
              sortable: false,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff"},
            },
             { key: 'asks',
              label: this.$t('assistant.ask_text'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
            },
             { key: 'helpers',
              label: this.$t('assistant.can_help'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
            },
            { key: 'feedback',
              label: this.$t('assistant.feedback_text'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
            },
            { key: 'connections',
              label: this.$t('assistant.opportunities_text'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
            },
             { key: 'type',
              label: this.$t('assistant.is_guest'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
             { key: 'purpose',
              label: this.$t('assistant.purpose_text'),
              sortable: false,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff"},
            },
            { key: 'contact',
              label: this.$t('assistant.contact_text'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
            },
          ],
        }
  },
  methods: {
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      clearFilters(){
        this.asksFilter= {searchDates: [], member: ""};
      },
      tableFilter(row, filter){
        if((filter.searchDates.length==0 || filter.searchDates.filter(v=> v == row.day).length>0) && (filter.member.length==0 || row.name.toLocaleLowerCase().includes(filter.member.toLocaleLowerCase()))){
          return true;
        }
        return false;
      },
    getAsks(){
      this.$http.post("/api/organization/assistant/asks",{}).then((response) => {
          if(response.data.success){
              this.asks = response.data.asks;
              var x = new Set();
              for(var i=0; i<this.asks.length; i++){
                  x.add(this.asks[i].day);
              }
              x = Array.from(x);
              for(i=0; i<x.length; i++){
                  this.dates.push({day: x[i]});
              }
          }
      }).catch((e) => { 
       console.log(e);
      })
    },
    getAsksExport(){
        this.$http.post("/api/organization/assistant/asks/export", {dates: this.asksFilter.searchDates, member: this.asksFilter.member}).then((response) => {
          if (response.data.success === true) {
            this.exportFile = response.data.file;
            this.showSuccess = true;
          }else{
            this.showAlert = true;
          }
        }).catch(() => { });
      },
      downloadAsksExport(){
        document.getElementById("export-file").click();
        this.showSuccess = false;
        this.showAlert = false;
      },
  },
  destroyed() {
      this.removeClass(document.getElementById("menu-asks"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
  created() {
    this.addClass(document.getElementById("menu-asks"), "active-menu");
    this.addClass(document.getElementById("menu-chapter"), "active-menu");
    this.getAsks();
  }
}
</script>
