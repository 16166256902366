<template>
    <div>
      <div class="rel-grid-container">
        <div ></div>
        <form  class="form-fix">
          <span class="form-box-title">{{$t('corevalue.speaker_text')}}</span>
          <div class="form-box grid-fitfirst-2column">
            <span class="form-box-name">{{$t('corevalue.date_text')}}</span>
            <b-form-datepicker class="input-vmargins" v-model="speaker.day" :locale="$i18n.locale" :date-disabled-fn="dateDisabled"></b-form-datepicker>
            <span v-if="speaker.id>0" class="form-box-name">{{$t('corevalue.core_value')}}</span>
            <b-form-select v-if="speaker.id>0" required v-model="speaker.core_value_id" :options="coreOptions.filter(v => isCorporateConnections? v.value>7 : v.value<=7)"  />
            <span class="form-box-name">{{$t('corevalue.member_text')}}</span>
            <b-form-select required v-model="speaker.member_id" :options="members" value-field="id" text-field="name" />
          </div>
          <b-alert variant="danger" id="alert-box" class="fit-width" dismissible :show="showAlert" @dismissed="showAlert=false">
              {{alertText}}
            </b-alert>
          <div class="submit-box-2">
            <b-button v-b-modal.modal-center v-if="speaker.id>0" class="action-button" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            <b-button class="action-button" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="updateCoreValueSpeaker" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
          <b-modal id="modal-center" v-model="showDelete" centered :title="$t('corevalue.delete_title')">
            <p class="my-4">{{$t('corevalue.delete_text')}}</p>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="action-button" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="action-button" v-on:click="deleteCoreValueSpeaker" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            </div>
          </b-modal>
        </form>
        <div class="div-space"></div>
      </div>
    </div>
  </template>
  <style scoped>
  #alert-box{
    margin: 10px auto;
  }
  #disabled-option{
    grid-column: 2;
  }
  #name-input{
    max-width: 300px;
  }
    
  </style>
  <script>
    import router from "../router"
      export default {
      name: "CoreValueSpeaker",
        data() {
          return {
            showDelete: false,
            speaker: {id:0, member_id: 0, core_value_id:0, date:null},
            showAlert: false,
            alertText: '',
            meet_day_of_week: 0,
            meeting_days: [],
            showNoMeetingDates: false,
            coreOptions:[
              { value: 0, text: this.$t('corevalue.select_core') },
              { value: 1, text: this.$t('newsletter.core_option_1') },
              { value: 2, text: this.$t('newsletter.core_option_2') },
              { value: 3, text: this.$t('newsletter.core_option_3') },
              { value: 4, text: this.$t('newsletter.core_option_4') },
              { value: 5, text: this.$t('newsletter.core_option_5') },
              { value: 6, text: this.$t('newsletter.core_option_6') },
              { value: 7, text: this.$t('newsletter.core_option_7') },
              { value: 8, text: this.$t('newsletter.core_option_8') },
              { value: 9, text: this.$t('newsletter.core_option_9') },
              { value: 10, text: this.$t('newsletter.core_option_10') },
              { value: 11, text: this.$t('newsletter.core_option_11') },
              { value: 12, text: this.$t('newsletter.core_option_12') },
            ],
            members: []
          }
        },
          methods: {
            dateDisabled(ymd, date) {
                if(this.meeting_days.length>0){
                return !this.meeting_days.includes(ymd);
                }
                const weekday = date.getDay();
                return weekday !== this.meet_day_of_week;
            },
            getMeetDay() {
                this.$http.post("/api/organization/get/meet_day_of_week", {}).then((response) => {
                if (response.data.success === true) {
                    this.meet_day_of_week = (response.data.meet_day_of_week + 1) % 7;
                    this.meeting_days = response.data.meeting_days;
                    if((!response.data.official || this.isCorporateConnections) && this.meeting_days.length==0){
                    this.meeting_days = [-1];
                    this.showNoMeetingDates = true;
                    }
                }
                }).catch(() => { });
            },
            getCoreValueSpeaker() {
              this.speaker.id = this.$route.params.id;
              if (this.speaker.id > 0) {
                this.$http.post("/api/organization/corevaluespeakers/get", this.speaker).then((response) => {
                  if (response.data.success) {
                    this.speaker = response.data.speaker;
                  }
                }).catch(() => {});
              } 
            },
            updateCoreValueSpeaker() {
              this.showAlert = false;
              if( this.speaker.member_id==0 || this.speaker.day ==null){
                this.showAlert = true;
                this.alertText = this.$t('global.complete_fields');
                return;
              }
              this.$http.post("/api/organization/corevaluespeakers/update", this.speaker).then((response) => {
                if(response.data.errorcode == 2){
                  this.showAlert = true;
                  this.alertText = this.$t('corevalue.duplicate_date');
                }
                if (response.data.success === true) {
                  this.back();
                }
              }).catch(() => {this.$bvToast.show('serverError');});
            },
            deleteCoreValueSpeaker() {
              this.$http.post("/api/organization/corevaluespeakers/delete", this.speaker).then((response) => {
                if (response.data.success === true) {
                  this.back();
                }
              }).catch(() => {});
            },
            back(){
                router.push("/admin/corevalues");
            },
            getMembers() {
          this.$http.post("/api/organization/members/corevalue", {}).then((response) => {
             for(var member of response.data.members){
                member.name = member.name+(member.last_day? ": "+member.last_day: "");
              }
              this.members = response.data.members;
              this.members.unshift({ id: 0, name: this.$t('corevalue.select_member') });
          }).catch(() => { });
      },
      },
      destroyed() {
        this.removeClass(document.getElementById("menu-corevalues"), "active-menu");
        this.removeClass(document.getElementById("menu-meeting"), "active-menu");
      },
      mounted() {
        this.addClass(document.getElementById("menu-corevalues"), "active-menu");
        this.addClass(document.getElementById("menu-meeting"), "active-menu");
        this.getMeetDay();
        this.getMembers();
        this.getCoreValueSpeaker();
      }
      }
  </script>
  