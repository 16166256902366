<template>
  <div>
    <Header :useruid="this.$route.params.useruid" :title="''" :showDate="true"></Header>
    <div class="rel-grid-container">
      <div ></div>
      <form v-on:submit.prevent="updateTopic" id="topic-main-box" class="form-fix">
        <div>
          <span  class="form-box-title">{{$t('topic.topic_title')}}</span>
          <div  class="form-box grid-fitfirst-2column">
            <span class="form-box-name">{{$t('topic.title_text')}}</span><b-form-input :state="topic.title_state"  v-model="topic.title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"></b-form-input>
             <span class="form-box-name">{{$t('topic.description_text')}}</span><b-form-textarea id="topic-description" v-model="topic.description" :rows="8"/>
            <span  class="form-box-name">{{$t('topic.picture_text')}}</span>
            <ImageSelector id="topic-image" :imageObject="topic" :imageField="'image'" :maxWidth="1000" :maxHeight="1000" :smallImageField="'image'" :clearButton="true" :minText="true"/>
            <span  v-if="useruid==''" class="form-box-name">{{$t('section.contacts_text')}}</span>
            <multiselect v-if="useruid==''" v-model="value" :options="contacts" :placeholder="$t('section.select_contacts')" label="name" track-by="name" class="blueselect" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="id" :close-on-select="false" :searchable="true">
              <template v-slot:option="{ option }">
                <div><span>{{ option.name }}</span> - <span>{{ option.email.length>0 ? option.email : $t('global.no_email')  }}</span></div>
                </template>
            </multiselect>
          </div>
        </div>
        <div >
          <Fileuploads :obj="topic" page="topic" :useruid="useruid"></Fileuploads>
        </div>
        <div v-if="showPresentationAlert">
          <b-alert variant="danger" dismissible :show="showPresentationAlert" @dismissed="showPresentationAlert=false">
              <span v-html="alertText"></span>
          </b-alert>
        </div>
        <div v-if="showSuccess">
          <b-alert variant="success" :show="showSuccess">
            {{$t('topic.successful_save')}}
          </b-alert>
        </div>
        <div v-if="showAlert" id="topic-alert-wrapper" class="auto-margin">
          <b-alert variant="warning" dismissible :show="showAlert" @dismissed="showAlert=false">
            {{$t('performance.error_type1')}}
          </b-alert>
        </div>
        <div v-if="showUpload">
          <b-alert class="alert-line-height" variant="warning" :show="showUpload">
            {{$t('topic.uploading_text')}}
            <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
          </b-alert>
        </div>
        <div class="submit-box-2" >
          <b-button v-b-modal.modal-center v-if="topic.id>0 && useruid==''" class="action-button" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          <b-button class="action-button" v-if="!showSuccess && useruid==''" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button" type="submit" v-if="!showSuccess" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('topic.delete_title')">
          <p class="my-4">{{$t('topic.delete_text')}}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="deleteTopic" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
      </form>
      <div class="div-space"></div>
    </div>
    <Footer :useruid="this.$route.params.useruid"></Footer>
  </div>
</template>
<style scoped>
#topic-image{
  margin-left:10px;margin-bottom: 10px;
}
#topic-description{
  margin-bottom: 7px;
}
#topic-main-box{
  width:800px;
}
#topic-alert-wrapper{
  max-width: 600px;
}

</style>
<script>
  import router from "../router"
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';
  import Fileuploads from './Fileuploads.vue';
export default {
    name: "Topic",
    components: {
      VueCropper,
      Fileuploads
    },
      data() {
        return {
            showDelete: false,
            topic: {id: 0, title : '', description: "", image: "", title_state: null, disabled: 0, assigned_ids: '', files: [{ presentation_id: 0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null, new: true}] },
            useruid: '',
            showSuccess: false,
            members: [],
            isManager: false,
            organization_name: '',
            contacts: [],
            value: [],
            showUpload: false,
            showPresentationAlert: false,
        }
      },
    methods: {
      getTopic(){
        if(this.topic.id>0){
          this.topic.useruid = this.useruid;
           this.$http.post("/api/organization/topics/get"+(this.useruid==''? "" : "/byuseruid"), this.topic).then((response) => {
              if (response.data.success) {
                response.data.title_state=null; 
                this.topic = response.data.topic;
                this.value = this.topic.assigned_ids.split(",");
                for (var i = 0; i < this.topic.files.length; i++) {
                  this.$set(this.topic.files[i], 'file', null);
                  this.$set(this.topic.files[i], 'filename', "");
                }
                if(this.topic.files.length == 0){
                  this.topic.files.push({ presentation_id: 0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null, new: true});
                }
              }
            }).catch(() => {});
        }
      },
        updateTopic(){
          this.topic.title_state=null;
          var topicCheck = true;
          if(this.topic.title.length==0){
            topicCheck = false;
            this.topic.title_state = false;
          }
          if(topicCheck){
            this.topic.assigned_ids = this.value.join(",");
            this.topic.useruid = this.useruid;
            this.showAlert = false;
            this.showUpload = true;
            this.alertText = this.$t('powerteam.alert_text');
            this.saveVariant = "";
            this.$http.post("/api/organization/topics/update"+(this.useruid==''? "" : "/byuseruid"), this.topic).then((response) => {
                  if (response.data.success) {
                    this.topic.id = response.data.id;
                      let formData = new FormData();
                      var presentationUploads = [];
                      for (var i = 0; i < this.topic.files.length; i++) {
                          if (this.topic.files[i].type == 1 && this.topic.files[i].file && this.topic.files[i].file != null) {
                              formData.append("files", this.topic.files[i].file);
                              this.topic.files[i].link = "";
                              this.topic.files[i].name = this.topic.files[i].file.name;
                              this.topic.files[i].file = null;
                              presentationUploads.push( this.topic.files[i]);
                          }else if (this.topic.files[i].link != ""){
                              presentationUploads.push( this.topic.files[i]);
                          }
                      }
                      formData.append("presentation_files", JSON.stringify(presentationUploads));
                      formData.append("hash", this.topic.hash);
                      formData.append("id", this.topic.id);
                      formData.append("useruid", this.useruid);
                      this.$http.post("/api/organization/powerteams/files/update"+(this.useruid==''? "" : "/byuseruid"), formData, {
                      headers: {
                          "Content-Type" : "multipart/form-data"
                      }
                      }).then((response) => {
                          this.showUpload = false;
                          this.saveVariant = "blue";
                          if (response.data.success == true) {
                              if(this.useruid!= ''){
                                this.showSuccess = true;
                              }else{
                                this.back();
                              }
                          } else {
                              this.showAlert = true;
                              this.alertText = response.data.error;
                          }
                      }).catch((errors) => {
                          this.showUpload = false;
                          this.saveVariant = "blue";
                          this.showAlert = true;
                          this.alertText = JSON.stringify(errors);
                      });
                  }else{
                     this.showUpload = false;
                      this.showAlert = true;
                      this.alertText = response.data.errors;
                  }
                }).catch((errors) => {
                    this.showUpload = false;
                    this.saveVariant = "blue";
                    this.showAlert = true;
                    this.alertText = JSON.stringify(errors);
                });
          }
        },
        deleteTopic(){
          this.topic.useruid = this.useruid;
          this.$http.post("/api/organization/topics/delete"+(this.useruid==''? "" : "/byuseruid"), this.topic).then((response) => {
            if (response.data.success) {
              this.back();
            }
          }).catch(() => {});
        },
          back(){
            router.push(this.useruid==''? "/admin/topics": "/topics/"+this.useruid);
          },
      getContacts() {
        this.$http.post("/api/topic/contacts/assigned"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid, id: this.topic.id}).then((response) => {
            this.contacts = response.data.contacts;
            this.getTopic();
        }).catch((errors) => {console.log(errors);});
    },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-topics"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.topic.id = this.$route.params.id;
      this.getContacts();
      this.addClass(document.getElementById("menu-topics"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
    }
  }
</script>
