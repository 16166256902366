<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <div v-if="!needsDate">
        <b-table id="quotes-tables" class="table-hover-new" small bordered :items="quotes" :fields="fields" v-on:row-clicked="editQuote" :tbody-tr-class="rowClass">
          </b-table>
      </div>
      <div v-if="needsDate" id="need-date">{{$t('global.need_date')}}</div>
      <div class="submit-box-2" v-if="!needsDate">
        <b-button v-if="permissions.access" class="action-button" v-on:click="newQuote" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
#need-date{
  text-align: center;font-size: 20px;
}
 #quotes-tables{
  max-width:500px;margin: 50px auto;
 }
</style>
<script>
    
  import router from "../router"
    export default {
      name: "Quotes",
      props:{
        chapter_id: {type: Number, default: 0},
        quoteObject: {type: Object, default: {id: -1}}
      },
      data() {
        return {
          needsDate: false,
          quotes: [],
          fields: [
            {
              key: 'name',
              label: this.$t('quote.quote_set_by'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            {
              key: 'source',
              label: this.$t('quote.source_title'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            }
          ],
        }
      },
      watch: {
      '$i18n.locale': {
        handler: function () {
          this.getQuotes();
        }
      }
    },
    methods: {
      rowClass(item) {
        if (!item) return
        if (item.isParent) return 'grey-row'
      },
      getQuotes() {
        this.$http.post("/api/organization/quotes"+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id}).then((response) => {
          if (response.data.success) {
            this.quotes = response.data.quotes;
          }else{
            this.needsDate = true;
          }
        }).catch(() => {
        });
      },
      newQuote() {
        if(this.chapter_id>0){
          this.quoteObject.id = 0;
        }else{
          router.push("/admin/quotes/0");
        }
      },
      editQuote(item) {
        if (!item.dis) {
          if(item.isParent==0 && this.chapter_id>0){
            this.quoteObject.id = item.id;
          }else if(item.isParent==0){
            router.push("/admin/quotes/" + item.id);
          }
        }
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-quotes"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-quotes"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getQuotes();
    }
    }
</script>
