<template>
<div >
    <div v-if="!embedded" id="image-buttons-preview-box">
        <img id="small-photo" v-if="!(imageObject[imageField] && imageObject[imageField].length>0) && (imageObject[smallImageField] && imageObject[smallImageField].length>0)" :src="imageObject[smallImageField]">
        <img id="large-photo" v-if="imageObject[imageField] && imageObject[imageField].length>0" :src="imageObject[imageField]">
        <b-button class="action-button image-edit-button" v-on:click="editImage" variant="clear-blue"><div class="preview-button"></div><span>{{minText? $t('global.button_set') : $t('global.set_image')}}</span></b-button>
        <b-button v-if="clearButton" class="action-button image-edit-button" v-on:click="clearImage" variant="clear-blue"><div class="cancel-button"></div><span>{{minText? $t('global.button_clear') : $t('global.clear_image')}}</span></b-button>
        <b-modal id="slide-modal" centered  v-model="showPhotoEdit" :title="$t('global.set_image')" >
            <b-form-file id='image-selector-upload' accept="image/jpeg, image/png, image/gif, image/webp" @change="setImage" plain hidden></b-form-file>
            <div class="image-editor-wrapper" v-on:dragover="dragHandler" v-on:dragleave="dragLeaveHandler" v-on:dragenter="dragEnterHandler">
                <div id="rotate-options">
                    <b-button v-if="imageEdit.preview && imageEdit.preview.length>0" v-on:click="rotateImageRight" class="image-button" variant="clear-blue"><div class="rotate-left-button"></div></b-button>
                    <b-button v-if="imageEdit.preview && imageEdit.preview.length>0" v-on:click="rotateImageLeft" class="image-button" variant="clear-blue"><div class="rotate-right-button"></div></b-button>
                    <b-button v-if="imageEdit.preview && imageEdit.preview.length>0" v-on:click="flipImageX"  class="image-button" variant="clear-blue"><div class="flip-y-button"></div></b-button>
                    <b-button v-if="imageEdit.preview && imageEdit.preview.length>0" v-on:click="flipImageY" class="image-button" variant="clear-blue"><div class="flip-x-button"></div></b-button>
                </div>
                <div id="crop-box" v-on:drop="dropHandler" @dragover.prevent  @dragenter.prevent @dragleave.prevent>
                    <vue-cropper v-show="!showDrag && imageEdit.preview" ref='cropper' :guides="true" :view-mode="2" drag-mode="crop" :auto-crop="true" :auto-crop-area="1"
                                :min-container-width="300" :min-container-height="300" :background="true" :rotatable="true"
                                :src="imageEdit.preview" :aspectRatio="ratio" :cropmove="cropMove" alt="Source Image"
                                :img-style="{ 'width': 'auto', 'height': '300px', 'width':'300px' }">
                    </vue-cropper>
                    <div v-if="!showDrag && !imageEdit.preview" class="no-image-wrapper">
                         <div class="fit-content no-image">{{$t('global.no_image')}}</div>
                    </div>
                    <div v-show="showDrag" class="drop-image-box">
                      <div></div>
                      <div>{{$t('global.drop_image')}}</div>
                      <div></div>
                  </div>
                </div>
                <div v-if="isCorporateConnections && circular" class="circular-member" :style="{'background-image': 'url('+imageEdit.preview+')', width: '300px', height: '300px'}"></div>
                <img v-if="!(isCorporateConnections && circular)" class="image-preview" v-show="imageEdit.preview"  :src="imageEdit.preview" />
                <div id="zoom-text">{{$t('member.zoom_text')}}</div>
                <b-button class="action-button image-upload-button" v-on:click="openDialog" variant="blue">{{$t('memberphoto.upload_photo')}}</b-button>
            </div>
            <div slot="modal-footer" class="submit-box-2">
                <b-button class="action-button" v-on:click="showPhotoEdit=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
                <b-button class="action-button" v-on:click="cropImage()" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.set_image')}}</span></b-button>
            </div>
        </b-modal>
    </div>
    <div v-if="embedded">
        <b-form-file id='image-selector-upload' accept="image/jpeg, image/png, image/gif, image/webp" @change="setImage" plain hidden></b-form-file>
        <div class="image-editor-wrapper" v-on:dragover="dragHandler" v-on:dragleave="dragLeaveHandler" v-on:dragenter="dragEnterHandler">
            <div id="rotate-options">
                <b-button v-if="imageObject[imageField] && imageObject[imageField].length>0" v-on:click="rotateImageRight" class="image-button" variant="clear-blue"><div class="rotate-left-button"></div></b-button>
                <b-button v-if="imageObject[imageField] && imageObject[imageField].length>0" v-on:click="rotateImageLeft" class="image-button" variant="clear-blue"><div class="rotate-right-button"></div></b-button>
                <b-button v-if="imageObject[imageField] && imageObject[imageField].length>0" v-on:click="flipImageX"  class="image-button" variant="clear-blue"><div class="flip-y-button"></div></b-button>
                <b-button v-if="imageObject[imageField] && imageObject[imageField].length>0" v-on:click="flipImageY" class="image-button" variant="clear-blue"><div class="flip-x-button"></div></b-button>
            </div>
            <div id="crop-box" v-on:drop="dropHandler" @dragover.prevent  @dragenter.prevent @dragleave.prevent>
                <vue-cropper v-show="!showDrag && imageEdit.data" ref='cropper' :guides="true" :view-mode="2" drag-mode="crop" :auto-crop="true" :auto-crop-area="1"
                    :min-container-width="300" :min-container-height="300" :background="true" :rotatable="true"
                    :src="imageObject[imageField]" :aspectRatio="ratio" :cropmove="cropMove" alt="Source Image"
                    :img-style="{ 'width': 'auto', 'height': '300px', 'width':'300px' }">
                </vue-cropper>
                <div v-if="!showDrag && !imageEdit.data" class="no-image-wrapper">
                      <div class="fit-content no-image">{{$t('global.no_image')}}</div>
                </div>
                <div v-show="showDrag" class="drop-image-box">
                    <div></div>
                    <div>{{$t('global.drop_image')}}</div>
                    <div></div>
                </div>
            </div>
            <div v-if="isCorporateConnections && circular" class="circular-member" :style="{'background-image': 'url('+imageObject[imageField]+')', width: '300px', height: '300px', margin: '20px'}"></div>
                <img v-if="!(isCorporateConnections && circular)" class="image-preview" v-show="imageObject[imageField]"  :src="imageObject[imageField]" />
            <div id="zoom-text">{{$t('member.zoom_text')}}</div>
            <b-button class="action-button image-upload-button" v-on:click="openDialog" variant="blue">{{$t('memberphoto.upload_photo')}}</b-button>
        </div>
    </div>
</div>
</template>
<style scoped>
.image-upload-button{
  grid-column:2/3;margin: auto;
}
.image-preview{
  width:100%;height:100%;object-fit: contain;max-height: 400px;
}
.drop-image-box{
  width:300px;height:300px;border: 1px dashed lightgray;display:grid;grid-template-rows: auto max-content auto;text-align: center;
}
.no-image{
  margin:auto;top: 140px;position: relative;
}
.no-image-wrapper{
  width:300px;height:100%;
}
.image-editor-wrapper{
  display: grid; grid-template-columns: min-content 1fr 1fr;
}
.image-edit-button{
  margin: auto 20px;
}
#small-photo, #large-photo{
  max-height:60px;
  max-width: 100px;
  margin: auto;
}
#image-buttons-preview-box{
  display:grid; grid-template-columns: max-content max-content max-content;
}
#crop-box{
  width: auto; height:300px; margin: auto; border: 1px solid gray; display: inline-block;
}

</style>
<script>
import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
export default {
    name: "ImageSelector",
    props: {imageObject: Object, 
      ratio: {type: Number, default: NaN}, 
      maxWidth: {type: Number, default: 1000}, 
      maxHeight: {type: Number, default: 1000}, 
      embedded: {type: Boolean, default: false}, 
      circular: {type: Boolean, default: false},
      clearButton: {type: Boolean, default: false},
      minText: {type: Boolean, default: false}, 
      imageField: {type: String, default: "photo"}, 
      smallImageField: {type: String, default: "photo_small"}, 
    },
    components: {
      VueCropper
    },
  data() {
    return {
      showPhotoEdit: false,
      image: '',
      imageEdit: {preview: ''},
      hasUploaded: false,
      showDrag: false,
      dragging: false,
      dragTimeout: null
    };
  },
  methods: {
    clearImage(){
        this.imgage = '';
        this.$set(this.imageObject, this.imageField, '');
        this.$set(this.imageObject, this.smallImageField, '');
        this.imageEdit.preview = '';
    },
    editImage(){
        this.showPhotoEdit = true;
        this.openPhotoEdit((s => this.$set(this.imageObject, this.imageField, s)),this.imageObject[this.imageField],this.ratio,this.maxWidth, this.maxHeight)
    },
    resizeDataUrl(filedata, imagetype, scale_width, scale_height, onEnd){
      var img = document.createElement("img");
      img.src = filedata;
      img.onload = function(){
        var width = img.width;
        var height = img.height;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        
        ctx.drawImage(img, 0, 0);
        const MAX_WIDTH = scale_width>0? scale_width : width;
        const MAX_HEIGHT = scale_height>0? scale_height : height;

        let hs = MAX_HEIGHT/height;
        let ws = MAX_WIDTH/width;
        let ms = Math.min(1,Math.min(hs, ws));

        canvas.width = ms*width;
        canvas.height = ms*height;
        if(imagetype.includes("j")){
          ctx.fillStyle = '#fff'; 
          ctx.fillRect(0, 0, canvas.width, canvas.height);
        }
        ctx.drawImage(img, 0, 0, ms*width, ms*height);
        var dataurl = canvas.toDataURL(imagetype, 0.8);
        onEnd(dataurl);
      }
    },
    cropImage(callback) {
      var self = this;
      if(this.$refs.cropper.getCroppedCanvas()){
        var image_data = self.$refs.cropper.getCroppedCanvas().toDataURL('image/png', 0.8);
        if(this.imageField != this.smallImageField){
          this.imageObject[this.imageField] = image_data;
          this.resizeDataUrl(image_data,'image/png',216,216, function(dataurl){
            self.$set(self.imageObject, self.smallImageField, dataurl);
            self.resizeDataUrl(image_data, 'image/png', self.imageEdit.maxwidth, self.imageEdit.maxheight, function(d){
              self.imageEdit.successFunc(d);
              if(callback && callback != undefined){
                callback(d);
              }
            });
          });
        }else{
          self.resizeDataUrl(image_data, 'image/png', self.imageEdit.maxwidth, self.imageEdit.maxheight, function(d){
            self.imageEdit.successFunc(d);
            if(callback && callback != undefined){
              callback(d);
            }
          });
        }
      }
      this.showPhotoEdit = false;
    },
    openPhotoEdit(sf, d,ratio, maxwidth, maxheight){
      this.imageEdit= {data: "", preview: "", successFunc: null, ratio: null,maxwidth:0,maxheight:0}
      this.imageEdit.ratio = ratio;
      this.imageEdit.successFunc = sf;
      this.imageEdit.maxwidth = maxwidth;
      this.imageEdit.maxheight = maxheight;
      var self = this;
      if(d && d.length>0){
        this.imageEdit.preview = d;
        this.imageEdit.data = d;
        if(this.$refs.cropper != undefined){
          this.$refs.cropper.replace(self.imageEdit.data);
        }
        setTimeout(function () {
            self.cropMove();
          }, 200);
      }else{
        this.imageEdit.data = "";
        if(this.$refs.cropper){
            this.$refs.cropper.replace(null);
        }
      }
    },
    cropMove() {
      if(this.$refs.cropper != undefined && this.$refs.cropper.getCroppedCanvas() != null){
        if(this.embedded){
            this.imageObject[this.imageField] = this.$refs.cropper.getCroppedCanvas().toDataURL('image/png', 0.8);
        }else{
            this.imageEdit.preview = this.$refs.cropper.getCroppedCanvas().toDataURL('image/png', 0.8);
        }
      }
    },
    rotateImageLeft(){
      this.$refs.cropper.rotate(90);
      this.cropMove();
    },
    rotateImageRight(){
      this.$refs.cropper.rotate(-90);
      this.cropMove();
    },
    flipImageX(){
      var d = this.$refs.cropper.getData()
      this.$refs.cropper.scaleX(d.scaleX*-1);
      this.cropMove();
    },
    flipImageY(){
      var d = this.$refs.cropper.getData()
      this.$refs.cropper.scaleY(d.scaleY*-1);
      this.cropMove();
    },
    setImage(e) {
      const file = e.target.files[0];
      if (!file.type.includes('image/')) {
        return;
      }
      this.readFile(file);
    },
    readFile(file){
      if (typeof FileReader === 'function') {
        var reader = new FileReader();
        var self = this;
        this.hasUploaded = true;
        reader.onload = function(e) {
          self.resizeDataUrl(e.target.result, 'image/png',self.imageEdit.maxwidth, self.imageEdit.maxheight, function(dataurl){
            self.imageEdit.data = dataurl;
            self.$refs.cropper.replace(dataurl);
            setTimeout(function () {
              if(self.embedded){
                self.cropImage();
              }else{
                self.cropMove();
              }
            }, 200);
          });
        }
        reader.readAsDataURL(file);
      }
    },
    openDialog() {
      document.getElementById('image-selector-upload').click();
    },
    getHasUploaded(){
      return this.hasUploaded;
    },
    dragEnterHandler(){
        this.showDrag = true;
        this.dragging = true;
    },
    dragHandler(){
        this.showDrag = true;
        this.dragging = true;
    },
    dragLeaveHandler(){
        this.dragging = false;
        var self = this;
        clearTimeout( this.dragTimeout );
            this.dragTimeout = setTimeout( function(){
            if( !self.dragging ){ self.showDrag = false;  }
        }, 200 );
    },
    dropHandler(ev){
        if(ev){
            ev.preventDefault();
            var file = null;
            if (ev.dataTransfer.items) {
                for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                  if (ev.dataTransfer.items[i].kind === 'file') {
                      file = ev.dataTransfer.items[i].getAsFile();
                      
                  }
                }
            } else {
                for (var j = 0; j < ev.dataTransfer.files.length; j++) {
                  file = ev.dataTransfer.files[j];
                }
            }
            if (!file.type.includes('image/')) {
                return;
            }
            this.readFile(file);
        }
        this.showDrag = false;
    },
  },
    mounted(){
        this.openPhotoEdit((s => this.$set(this.imageObject, this.imageField, s)),this.imageObject[this.imageField],this.ratio,this.maxWidth, this.maxHeight)
    },
  beforeDestroy() {
    },
}
</script>