<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <div >
          <table  class="table b-table table-hover-new table-bordered table-sm">
              <thead>
                <tr>
                <th class="tableHeader">{{$t('status.organization_header')}}</th>
                <th class="tableHeader">{{$t('status.start_header')}}</th>
                <th class="tableHeader">{{$t('status.end_header')}}</th>
                </tr>
            </thead>
              <tbody>
                <tr v-if="loading">
                  <td  colspan="3" class="center-text"><b-spinner label="Spinning" class="skinned-image"></b-spinner></td>
                </tr>
                  <tr v-if="!loading && (status.ongoing.length+status.upcoming.length==0)">
                        <td  colspan="3">{{$t('status.no_status')}}</td>
                    </tr>
                  <tr v-if="status.ongoing.length>0">
                        <th class="tableHeader" colspan="3">{{$t('status.ongoing_header')}}</th>
                    </tr>
                  <tr v-for="ongoing in status.ongoing" :key="ongoing.name">
                      <td :style="{'color': (ongoing.subscription_status>=1 && ongoing.subscription_status<=3)? '' : 'red'}">{{ongoing.name}}</td>
                      <td>{{ongoing.start}}</td>
                      <td>{{ongoing.end}}</td>
                    </tr>
                    <tr v-if="status.upcoming.length>0">
                        <th class="tableHeader" colspan="3">{{$t('status.upcoming_header')}}</th>
                    </tr>
                    <tr v-for="upcoming in status.upcoming" :key="upcoming.name">
                      <td :style="{'color': (upcoming.subscription_status>=1 && upcoming.subscription_status<=3)? '' : 'red'}">{{upcoming.name}}</td>
                      <td>{{upcoming.start}}</td>
                      <td>{{upcoming.end}}</td>
                    </tr>
              </tbody>
          </table>
      </div> 
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
  
</style>
<script>
    export default {
    name: "Status",
    data() {
      return {
        loading: false,
          status: {ongoing: [],upcoming:[]}
      }
    },
    methods: {
      getStatus() {
        this.loading = true;
        this.$http.post("/api/getstatus", {}).then((response) => {
          if (response.data.success) {
            this.status = response.data;
          }
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-status"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-status"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.getStatus();
    }
    }
</script>
