<template>
  <div>
    <Header :useruid="this.$route.params.useruid" :title="$t('categories.manage_categories')" :showDate="false"></Header>
    <div class="rel-grid-container">
      <div ></div>
      <div >
        <Tip :tipNumber="6"></Tip>
        <b-form-checkbox v-model="permissions.showDisabledCategories" id="show-disabled" class="fit-width" @change="getCategories">
            {{$t('categories.show_disabled')}}
          </b-form-checkbox>
        <table class="table b-table table-hover-new table-bordered table-sm">
          <thead>
            <tr>
              <th class="tableHeader">{{$t('categories.desired_categories')}}</th>
            </tr>
          </thead>
          <draggable tag="tbody" v-model="categories" item-key="id"  @end="endDragCat" @start="startDragCat">
            <template #item="{element}">
              <tr v-bind:class="{disabledClass : element.disabled, dragndrop: true}" class="clickable" v-on:click="editCategory(element)">
                <td>{{ element.name }}<span v-if="element.starred" class="star-image skinned-image" v-b-popover.hover.top="$t('categories.star_tooltip')"></span></td>
              </tr>
          </template>
          </draggable>
         
        </table>
        <div id="drag-note">{{$t('categories.drag_order')}}</div>
        <div id="new-button-wrapper" class="fit-width">
          <b-button class="action-button" v-on:click="newCategory" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
        </div>
        <div v-if="categories.length>0" id="enable-options" class="submit-box-2">
          <b-button class="action-button auto-margin" v-on:click="showEnableAll=true;" variant="clear-blue"><div class="new-button"></div><span>{{$t('categories.enable_all')}}</span></b-button>
          <b-button class="action-button auto-margin" v-on:click="showDisableAll = true;" variant="clear-blue"><div class="new-button"></div><span>{{$t('categories.disable_all')}}</span></b-button>
        </div>
        <b-modal centered v-model="showEnableAll" :title="$t('categories.enable_title')">
              <div class="center-text">
              {{$t('categories.enable_check')}}
            </div>
            <div slot="modal-footer" class="fit-content choice-buttons">
              <b-button class="action-button" v-on:click="showEnableAll = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="action-button" v-on:click="setAllEnabled" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal centered v-model="showDisableAll" :title="$t('categories.disable_title')">
              <div class="center-text">
              {{$t('categories.disable_check')}}
            </div>
            <div slot="modal-footer" class="fit-content choice-buttons">
              <b-button class="action-button" v-on:click="showDisableAll = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="action-button" v-on:click="setAllDisabled" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
          </b-modal>
      </div> 
      <div class="div-space"></div>
    </div>
    <Footer :useruid="this.$route.params.useruid"></Footer>
  </div>
</template>
<style scoped>
.choice-buttons{
  display:grid; grid-template-columns:auto auto;margin: auto;grid-gap:30px;
}
#enable-options{
  grid-gap: 50px;
}
#new-button-wrapper{
  margin: 5px auto;
}
#show-disabled{
  margin-right: 0px;margin-left: auto;
}
  #drag-note{
    float: right;font-size: 10px;
  }
  .star-image{
  background-image: url("../assets/icon-Star.png");
  height: 18px;
  width:19px;
   background-size: 100% 100%;
  background-repeat: no-repeat;
  vertical-align: middle;
    margin-left: 5px;
    margin-bottom: 5px;
    display: inline-block;
}
</style>
<script>
  import router from "../router"
    export default {
    name: "Categories",
    data() {
      return {
        categories: [],
        moving:false,
        showEnableAll: false,
        showDisableAll: false,
        useruid: ''
      }
    },
    methods: {
      getCategories() {
        this.$http.post("/api/organization/categories"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid}).then((response) => {
          if (response.data.success) {
            var s = 3;
            for(var i=0; i<response.data.categories.length; i++){
              if(response.data.categories[i].disabled ==0){
                response.data.categories[i].starred = true;
                s-=1;
                if(s==0){
                  break;
                }
              }
            }
            this.categories = response.data.categories.filter(v=> v.disabled==0 || this.permissions.showDisabledCategories);
          }
        }).catch((error) => {
          console.log(JSON.stringify(error));
        });
      },
      newCategory() {
        if (this.permissions.access) {
          router.push("/admin/category/0");
        }else if(this.useruid.length>0){
          router.push("/category/0/"+this.useruid);
        }
      },
      editCategory(item) {
        if (this.permissions.access) {
          router.push("/admin/category/" + item.id);
        }else if(this.useruid.length>0){
          router.push("/category/" + item.id+"/"+this.useruid);
        }
      },
      startDragCat(){
        this.moving = true;
      },
      endDragCat() {
        this.moving = false;
        var ranks = [];
        var s = 3;
        for (var i = 0; i < this.categories.length; i++) {
          ranks.push({ id: this.categories[i].id, rank: i });
          if(s>0 && this.categories[i].disabled ==0){
              this.categories[i].starred = true;
              s-=1;
            }else{
               this.categories[i].starred = false;
            }
        }
        this.$http.post("/api/organization/categories/rank"+(this.useruid==''? "" : "/byuseruid"), { ranks: ranks,useruid: this.useruid }).then(() => {
        }).catch(() => {
        });
      },
      setAllEnabled(){
        this.$http.post("/api/organization/categories/enable/all"+(this.useruid==''? "" : "/byuseruid"), { useruid: this.useruid}).then((response) => {
          if(response.data.success==true){
            
            for (var i = 0; i < this.categories.length; i++) {
              this.categories[i].disabled = 0;
            }
          }
          this.showEnableAll = false;
        }).catch(() => {
        });
      },
      setAllDisabled(){
        this.$http.post("/api/organization/categories/disable/all"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid }).then((response) => {
          if(response.data.success==true){
           
            for (var i = 0; i < this.categories.length; i++) {
              this.categories[i].disabled = 1;
            }
          }
           this.showDisableAll = false;
        }).catch(() => {
        });
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-categories"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.addClass(document.getElementById("menu-categories"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getCategories();
    }
    }
</script>
