<template>
 <span><a v-on:click="openTerms" class="open-link">{{$t('setup.view3_text4')}}</a> {{$t('setup.view3_text5')}} <a v-on:click="openPolicy" class="open-link">{{$t('setup.view3_text6')}}</a>

 </span>
</template>
<style scoped>
.open-link{
    cursor:pointer;
}
</style>
<script>
    //import pdf from 'vue-pdf'
    export default {
    name: "TermsAndCOnditions",
     
    //components: {
    //  pdf
    //},
  data() {
    return {
        showTermsModal: false,
        maxPages: 0,
        pdfPage: 1,
        loaded : false,
    };
  },
   mounted() {
  },
  methods: {
      openTerms(){
          window.open("https://canada.meetinghub.biz/terms.html", "_blank");
      },
      openPolicy(){
          window.open("https://canada.meetinghub.biz/privacy.html", "_blank");
      },
  }
}
</script>