<template>
<div class="input-group color-picker" ref="colorpicker">
    <div id="color-box" class="clickable"><span class="current-color" :style="{'background-color':colorValue+''}" @click.stop="togglePicker()"></span></div>

    <span id="color-picker" class="input-group-addon color-picker-container" :style="{'top' : top? '-250px': '40px'}">
        <chrome-picker :value="colors" @input="updateFromPicker" v-if="displayPicker" />
    </span>
</div>
</template>
<style scoped>
  #color-box{
    padding:5px;width:100%;height:100%;border: 1px solid #ced4da;border-radius: .25rem;
  }
  #color-picker{
    position: absolute; z-index:100;
  }
  .current-color{
    display: block;
    widows: 100%;
    height: 100%;
  }
</style>
<script>
   import { Chrome } from 'vue-color';
    export default {
    name: "colorpicker",
    components: {
      'chrome-picker': Chrome,
    },
     props: {color: {type: String, default: "#000000"}, top: {type: Boolean, default: true}, change: {type: Function, default: null}},
  data() {
    return {
      colors: {
        hex: '#000000',
      },
      colorValue: '',
      displayPicker: false

    };
  },
   mounted() {
    this.setColor(this.color || '#000000');
  },
  methods: {
    setColor(color) {
      this.updateColors(color);
      this.colorValue = color;
    },
    updateColors(color) {
      if (color.slice(0, 1) == '#') {
        this.colors = {
          hex: color
        };
      }
      else if (color.slice(0, 4) == 'rgba') {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
          hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3]
        };
      }
    },
    showPicker() {
      document.addEventListener('click', this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick);
      this.displayPicker = false;
     
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateFromInput() {
      this.updateColors(this.colorValue);
    },
    updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a == 1) {
        this.colorValue = color.hex;
      }
      else {
        this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
      }
      
    },
    documentClick(e) {
      var el = this.$refs.colorpicker,
        target = e.target;
      if (el !== target && !el.contains(target)) {
        this.hidePicker();
      }
    }
  },
  watch: {
    colorValue(val) {
      if (val) {
        this.updateColors(val);
        this.$emit('input', val);
      }
    },
    color(val) {
      this.setColor(val);
    }
  }
    }
</script>