<template>
  <div class="full-height">
    <div id="container" class="rel-grid-container">
        <div ></div>
        <div>
            <div >
                <div class="form-box grid-split-3column">
                    <span class="form-box-name">{{$t('setup.chapter_name')}}</span><b-form-input v-model="organization.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"  class="form-box-input"  required></b-form-input>
                    <span class="form-box-name">{{$t('setup.pres_email')}}</span><b-form-input  v-model="organization.email" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" ></b-form-input>
                    <span class="form-box-name">admin username</span><b-form-input v-model="organization.user_name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"  required></b-form-input>
                    <span class="form-box-name">password</span><b-form-input v-model="organization.user_pass" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" ></b-form-input>
                    <span class="form-box-name">{{$t('general.language_text')}}</span><b-form-select v-model="organization.language" :options="languageOptions" class="mb-3 form-box-input" />
                    <span class="form-box-name">{{$t('general.time_zone')}}</span>
                <b-form-select v-model="organization.meet_timezone" :options="timezones" value-field="value" text-field="name" class="mb-3 form-box-input" />
                    
                </div>
                <div v-if="showAlert">
                    <b-alert variant="danger"
                            dismissible
                            :show="showAlert"
                            @dismissed="showAlert=false">
                    {{alertText}}
                    </b-alert>
                </div>
                <b-button class="action-button auto-margin" v-on:click="createOrganization" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_submit')}}</span></b-button>
            </div>
        </div>
      <div class="div-space"></div>
    </div>
</div>
</template>
<style scoped>
#container{
  top:20px; height:100%;  max-width: 600px;margin: auto;    text-align: center; display: grid; grid-template-rows: auto max-content max-content auto;
}
</style>
<script>
    
    import router from "../router"
    export default {
    name: "CreateRegion",
    data() {
        return {
            organization:{
                name: "",
                email: "",
                user_name: '',
                user_pass: '',
                language: 'en',
                meet_timezone: "233"
            },
            languageOptions: [{ value: 'en', text: this.$t('general.english_text') },
          { value: 'fr', text: this.$t('general.french_text') }],
        timezones : [
          { name: "(GMT -12:00) Eniwetok, Kwajalein", value: "546" },
          { name: "(GMT -11:00) Midway Island, Samoa", value: "562" },
          { name: "(GMT -10:00) Hawaii", value: "583" },
          { name: "(GMT -9:30) Taiohae", value: "548" },
          { name: "(GMT -9:00) Alaska", value: "577" },
          { name: "(GMT -8:00) Pacific Time (US &amp; Canada)", value: "379" },
          { name: "(GMT -7:00) Mountain Time (US &amp; Canada)", value: "377" },
          { name: "(GMT -6:00) Central Time (US &amp; Canada), Mexico City", value: "375" },
          { name: "(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima", value: "376" },
          { name: "(GMT -4:30) Caracas", value: "90" },
          { name: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz", value: "374" },
          { name: "(GMT -4:00) Eastern Daylight Time, Toronto", value: "213" },
          { name: "(GMT -3:30) Newfoundland", value: "378" },
          { name: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "86" },
          { name: "(GMT -2:00) Mid-Atlantic", value: "411" },
          { name: "(GMT -1:00) Azores, Cape Verde Islands", value: "336"},
          { name: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "451" },
          { name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "433" },
          { name: "(GMT +2:00) Kaliningrad, South Africa", value: "446" },
          { name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "458" },
          { name: "(GMT +3:30) Tehran", value: "316" },
          { name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "245" },
          { name: "(GMT +4:30) Kabul", value: "275" },
          { name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "277" },
          { name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "251" },
          { name: "(GMT +5:45) Kathmandu, Pokhara", value: "279" },
          { name: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "259" },
          { name: "(GMT +6:30) Yangon, Mandalay", value: "330" },
          { name: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "246" },
          { name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "360" },
          { name: "(GMT +8:45) Eucla", value: "352" },
          { name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "+09:00" },
          { name: "(GMT +9:30) Adelaide, Darwin", value: "320" },
          { name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "328" },
          { name: "(GMT +10:30) Lord Howe Island", value: "356" },
          { name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "394" },
          { name: "(GMT +11:30) Norfolk Island", value: "552" },
          { name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "528" },
          { name: "(GMT +12:45) Chatham Islands", value: "530" },
          { name: "(GMT +13:00) Apia, Nukualofa", value: "527" }
        ],
        }
    },
    methods: {
        createOrganization(){
            this.$http.post("/api/organization/region/create", this.organization).then((response) => {
                if (response.data.success) {
                    console.log("success");
                    this.organization ={
                        name: "",
                        email: "",
                        user_name: '',
                        user_pass: '',
                        language: 'en',
                        meet_timezone: "233"
                    };
                }
            }).catch(() => { });
        },
        auth(){
        this.$http.get("/api/auth/ultimate")
          .then((response) => {
            if (response.data.authorized != true) {
              router.push("/login");
            }
          })
          .catch(() => {
            router.push("/login");
          });
      }
    },
    destroyed() {
      
    },
    mounted() {
        this.auth();
    }
}
</script>
