<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <div v-if="rolesFetched && template_region.id==0">
        <Tip :tipNumber="5"></Tip>
        <table class="table b-table table-hover-new table-bordered table-sm">
          <thead>
            <tr>
              <th class="tableHeader">{{$t('roles.role_text')}}</th>
              <th class="tableHeader center-text">{{$t('roles.num_members')}}</th>
              <th v-if="!permissions.organization.is_parent" class="tableHeader" >{{$t('roles.assigned_members')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="role in roles" :key="role.id" v-on:click="editRole(role)" v-bind:class="{ 'grey-row' : role.dis, clickable: !role.dis}">
              <td>{{role.name}}</td>
              <td class="role-members-column">
                <b-button v-if="role.members.length>0 && !permissions.organization.is_parent " variant="link" v-on:click.stop="showMembersOfRole(role.id)" class="unpadded-button"><b>{{role.members.length}}</b></b-button>
                <span v-if="role.members.length==0 && !permissions.organization.is_parent">{{role.members.length}}</span>
                <span v-if="permissions.organization.is_parent">{{role.num}}</span>
              </td>
              <td v-if="!permissions.organization.is_parent">
                  <div v-for="m in role.members" :key="m" >
                     <b-button variant="link" v-on:click.stop="goToMember(m)" class="unpadded-button">{{m.name}}</b-button> 
                  </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="submit-box-2">
          <b-button v-if="permissions.access" class="action-button" v-on:click="newRole" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
        </div>
      </div>
      <div v-if="rolesFetched && template_region.id>0" id="managed-by">
        {{ $t('global.managed_by', {region: template_region.name}) }}
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
#managed-by{
  text-align: center;font-size: 30px;
}
.unpadded-button{
  padding:0;border: none;
}
.role-members-column{
  text-align:center;font-weight:bold;
}
</style>
<script>
  import router from "../router"
  export default {
    name: "Roles",
    data() {
      return {
        roles: [],
        rolesFetched: false,
        template_region: {id: 0, name: ""}
      }
    },
    methods: {
      getRoles() {
        this.$http.post("/api/organization/roles/members", {}).then((response) => {
          if (response.data.success) {
            if(response.data.template_region){
              this.template_region = response.data.template_region;
            }else{
              this.roles = response.data.roles;
            }
            this.rolesFetched = true;
          }
        }).catch(() => {
        });
      },
      goToMember(m){
        if(m.id>0){
          router.push("/admin/member/"+m.id);
        }
      },
      showMembersOfRole(r){
        this.permissions.filterRoleId = r;
        router.push("/admin/members/");
      },
      newRole() {
        router.push("/admin/role/0");
      },
      editRole(item) {
        if (!item.dis) {
          router.push("/admin/role/" + item.id);
        }
      }
    },
    destroyed() {
      this.unsetMenu("chapter","roles");
    },
    mounted() {
      this.setMenu("chapter","roles");
      this.getRoles();
    }
  }
</script>
