<template>
  <div>
    <Header :useruid="this.$route.params.useruid" :title="isManager? $t('powerteam.manage_powerteam') : ($t('powerteam.powerteam_update')+': '+powerteam.title)" :showDate="true"></Header>
    <div class="rel-grid-container">
      <div ></div>
      <form v-on:submit.prevent="updatePowerteam" id="powerteam-form" class="form-fix">
        <div v-if="(useruid=='' || isManager)">
          <span  class="form-box-title">{{$t('powerteam.powerteam_title')}}</span>
          <div  class="form-box grid-fitfirst-2column">
            <span class="form-box-name">{{$t('powerteam.title_text')}}</span><b-form-input :state="powerteam.title_state"  v-model="powerteam.title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"></b-form-input>
             <span class="form-box-name">{{$t('powerteam.description_text')}}</span><b-form-textarea id="powerteam-description" v-model="powerteam.description" :rows="2" :maxlength="255"/>
            <span  class="form-box-name">{{$t('powerteam.picture_text')}}</span>
              <ImageSelector id="powerteam-image-selector" :imageObject="powerteam" :imageField="'photo'" :maxWidth="1000" :maxHeight="1000" :smallImageField="'photo'" :clearButton="true" :minText="true"/>
            <span v-if="(useruid=='' || isManager)" class="form-box-name">{{$t('powerteam.members_text')}}</span>
            <multiselect id="members-select" v-if="(useruid=='' || isManager)" v-model="value" :options="members" label="name" track-by="name" class="blueselect" :object="true" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="id" :close-on-select="false" :searchable="true">
              <template v-slot:option="{ option }">
                <div><span>{{ option.name }}</span>  <span >- {{ option.email.length>0 ? option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>
            <table id="powerteam-role-table" class="table b-table table-sm table-hover-new">
              <thead>
                <tr>
                  <th >{{$t('role.name_header')}}</th>
                  <th class="center-text" >{{$t('role.remove_header')}}</th>
                </tr>
              </thead>
              <draggable tag="tbody" v-model="value" item-key="id">
                <template #item="{element}">
                  <tr>
                    <td class="vert-align">{{element.name}}</td>
                    <td class="vert-align"><span class="trash-recog skinned-image" variant="blue" v-on:click="deleteMember(element)"></span></td>
                  </tr>
                </template>
              </draggable>
              </table>
              <div id="powerteam-drag-order" class="form-box-name">*{{$t('global.drag_order')}}</div>
            <span v-if="(useruid=='' || isManager) && value.length>0" class="form-box-name">{{$t('powerteam.manager_text')}}</span><b-form-select v-if="(useruid=='' || isManager) && value.length>0" v-model="powerteam.manager_id" :options="powerteamMembers" value-field="id" text-field="name"/>
            <div v-if="(useruid=='' || isManager) && value.length>0 && powerteam.manager_id>0" id="managers-note">{{$t('powerteam.manager_note')}}</div>
          </div>
        </div>
        <div>
          <span  class="form-box-title">{{$t('powerteam.activity_text')}}</span>
          <div  class="form-box grid-auto-1column">
             <BaseEditor v-model="powerteam.activity"/>
          </div>
        </div>
        <div >
          <Fileuploads :obj="powerteam" page="powerteam" :useruid="useruid"></Fileuploads>
        </div>
        <div v-if="showPresentationAlert">
            <b-alert variant="danger"
                     dismissible
                     :show="showPresentationAlert"
                     @dismissed="showPresentationAlert=false">
              <span v-html="alertText"></span>
            </b-alert>
          </div>
        <div v-if="showSuccess">
          <b-alert variant="success" :show="showSuccess">
            {{$t('powerteam.successful_save')}}
          </b-alert>
        </div>
        <div v-if="showAlert" id="powerteam-alert">
          <b-alert variant="warning" dismissible :show="showAlert" @dismissed="showAlert=false">
            {{$t('performance.error_type1')}}
          </b-alert>
        </div>
        <div v-if="showUpload">
          <b-alert class="alert-line-height" variant="warning" :show="showUpload">
            {{$t('powerteam.uploading_text')}}
            <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
          </b-alert>
        </div>
        <div class="submit-box-2" >
          <b-button v-on:click="showDelete=true;" v-if="powerteam.id>0 && (useruid=='' || isManager)" class="action-button" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          <b-button class="action-button" v-if="!showSuccess && (useruid=='' || isManager)" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button" type="submit" v-if="!showSuccess" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('powerteam.delete_title')">
          <p class="my-4">{{$t('powerteam.delete_text')}}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="deletePowerteam" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showSendEmailModal" centered :title="$t('powerteam.sendemail_title')">
          <p v-html="$t('powerteam.sendemail_text')"></p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="updatePowerteam" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_no')}}</span></b-button>
            <b-button class="action-button" v-on:click="sendEmail=true; updatePowerteam();" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
          </div>
        </b-modal>
      </form>
      <div class="div-space"></div>
    </div>
    <Footer :useruid="this.$route.params.useruid"></Footer>
  </div>
</template>
<style scoped>
#powerteam-activity{
  margin-bottom: 7px;
}
#managers-note{
  grid-column: 2/3;font-size:12px;
}
#powerteam-image-selector{
  margin-left:10px;margin-bottom: 10px;
}
#powerteam-description{
  margin-bottom: 7px;
}
#powerteam-form{
  width:800px;
}
#powerteam-role-table{
  grid-column:2/3;margin-bottom:0px;
}
#powerteam-drag-order{
  grid-column:2/3;font-size:12px;margin-top:0px;margin-bottom:20px;
}
#powerteam-alert{
  max-width: 600px;margin: auto;
}
.vert-align{
  vertical-align: middle;
}
#members-select{
  margin-bottom:10px;
}
.trash-recog {
    width: 30px;
  height: 30px;
  background-image: url("../assets/button-Close.png");
  border: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: block;
  margin: auto;
  text-align: center;
  cursor: pointer;
}

</style>
<script>
import Fileuploads from './Fileuploads.vue';
  import router from "../router"
  import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    export default {
    name: "Powerteam",
    components: {
      VueCropper,
      Fileuploads
    },
    watch: {
      'value': {
        handler: function (v) {
          this.powerteamMembers = v.filter(x => x.id>0);
          this.powerteamMembers.unshift({id: 0, name: this.$t('powerteam.no_manager')});
          var found = false;
          for(var i=0; i<this.powerteamMembers.length;i++){
            if(this.powerteam.manager_id == this.powerteamMembers[i].id){
              found = true;
            }
          }
          if(!found){
            this.powerteam.manager_id = 0;
          }
        },
        deep: true
      },
    },
      data() {
        return {
            showDelete: false,
            powerteam: {id: 0, manager_id: 0, title : '', description: "", photo: null, activity: "", title_state: null, assigned_ids: '',disabled: 0, files: [{ presentation_id: 0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null, new: true}] },
            useruid: '',
            showSuccess: false,
            members: [],
            isManager: false,
            organization_name: '',
            value: [],
            showUpload: false,
            showPresentationAlert: false,
            alertText: "",
            showSendEmailModal: false,
            sendEmailShown: false,
            sendEmail: false,
            original_manager_id: 0,
            powerteamMembers: []
        }
      },
    methods: {
      deleteMember(m){
        var index = this.value.indexOf(m);
          if (index > -1) {
            this.value.splice(index, 1);
          }
      },
      getPowerteam(){
        if(this.powerteam.id>0){
          this.powerteam.useruid = this.useruid;
           this.$http.post("/api/organization/powerteams/get"+(this.useruid==''? "" : "/byuseruid"), this.powerteam).then((response) => {
              if (response.data.success) {
                this.isManager = response.data.manager;
                this.original_manager_id = response.data.powerteam.manager_id;
                response.data.title_state=null;
                if(!response.data.powerteam.files) {
                  response.data.powerteam.files = [];
                }
                this.powerteam = response.data.powerteam;
                var ids = this.powerteam.assigned_ids.split(",");
                for(var i=0; i<ids.length; i++){
                  for(var j=0; j<this.members.length;j++){
                    if(ids[i] == (this.members[j].id+"")){
                        this.value.push(this.members[j]);
                        break;
                    }
                  }
                }
                for (var i = 0; i < this.powerteam.files.length; i++) {
                  this.$set(this.powerteam.files[i], 'file', null);
                  this.$set(this.powerteam.files[i], 'filename', "");
                }
                if(this.powerteam.files.length == 0){
                  this.powerteam.files.push({member_uid: this.useruid, presentation_id: 0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null, new: true});
                }
              }
            }).catch(() => {});
        }
      },
      arraysAreIdentical(arr1, arr2){
          if (arr1.length !== arr2.length) return false;
           arr1.sort((a, b) => (parseInt(a)> parseInt(b)) ? 1 : -1);
          arr2.sort((a, b) => (parseInt(a)> parseInt(b)) ? 1 : -1);
          for (var i = 0, len = arr1.length; i < len; i++){
              if (arr1[i] != arr2[i]){
                  return false;
              }
          }
          return true; 
      },
        updatePowerteam() {
            this.powerteam.title_state=null;
            var powerteamCheck = true;
            if(this.powerteam.title.length==0){
                powerteamCheck = false;
                this.powerteam.title_state = false;
            }
            if(powerteamCheck){
                var t = [];
                for(var i=0; i<this.value.length;i++){
                    t.push(this.value[i].id);
                }
               /* if(this.original_manager_id != this.powerteam.manager_id && !this.sendEmailShown && (this.useruid=='' || this.isManager) ){
                  this.showSendEmailModal = true;
                  this.sendEmailShown = true;
                  return;
                }*/
                this.powerteam.assigned_ids = t.join(",");
                this.powerteam.useruid = this.useruid;

                this.showAlert = false;
                this.showSuccess = false;
                this.alertText = this.$t('powerteam.alert_text');
                this.saveVariant = "";
                this.$http.post("/api/organization/powerteams/update"+(this.useruid==''? "" : "/byuseruid"), this.powerteam).then((response) => {
                  if (response.data.success) {
                    if(this.powerteam.files.length>0){
                      this.showUpload = true;
                      this.powerteam.id = response.data.id;
                      let formData = new FormData();
                      var presentationUploads = [];
                      for (var i = 0; i < this.powerteam.files.length; i++) {
                          if (this.powerteam.files[i].type == 1 && this.powerteam.files[i].file && this.powerteam.files[i].file != null) {
                              formData.append("files", this.powerteam.files[i].file);
                              this.powerteam.files[i].link = "";
                              this.powerteam.files[i].name = this.powerteam.files[i].file.name;
                              this.powerteam.files[i].file = null;
                              presentationUploads.push( this.powerteam.files[i]);
                          }else if (this.powerteam.files[i].link != ""){
                              presentationUploads.push( this.powerteam.files[i]);
                          }
                      }
                      if(this.sendEmail && (this.useruid=='' || this.isManager)){
                        this.$http.post("/api/organization/powerteams/sendemail"+(this.useruid==''? "" : "/byuseruid"), this.powerteam).then(() => {
                        });
                      }
                      formData.append("presentation_files", JSON.stringify(presentationUploads));
                      formData.append("hash", this.powerteam.hash);
                      formData.append("id", this.powerteam.id);
                      formData.append("useruid", this.useruid);
                      this.$http.post("/api/organization/powerteams/files/update"+(this.useruid==''? "" : "/byuseruid"), formData, {
                      headers: {
                          "Content-Type" : "multipart/form-data"
                      }
                      }).then((response) => {
                          this.showUpload = false;
                          this.saveVariant = "blue";
                          if (response.data.success == true) {
                              if(this.useruid.length>0 && !this.isManager){
                                this.showSuccess = true;
                              }else{
                                this.back();
                              }
                          } else {
                              this.showAlert = true;
                              this.alertText = response.data.error;
                          }
                      }).catch((errors) => {
                          this.showUpload = false;
                          this.saveVariant = "blue";
                          this.showAlert = true;
                          this.alertText = JSON.stringify(errors);
                      });
                    }else{
                        if(this.useruid.length>0 && !this.isManager){
                            this.showSuccess = true;
                          }else{
                            this.back();
                          }
                    }
                      
                  }else{
                     this.showUpload = false;
                      this.showAlert = true;
                      this.alertText = response.data.errors;
                  }
                }).catch((errors) => {
                    this.showUpload = false;
                    this.saveVariant = "blue";
                    this.showAlert = true;
                    this.alertText = JSON.stringify(errors);
                });
            }
          },
        deletePowerteam(){
          this.powerteam.useruid = this.useruid;
          this.$http.post("/api/organization/powerteams/delete"+(this.useruid==''? "" : "/byuseruid"), this.powerteam).then((response) => {
            if (response.data.success) {
              this.back();
            }
          }).catch(() => {});
        },
          back(){
            router.push(this.useruid==''? "/admin/powerteams": "/powerteams/"+this.useruid);
          },
      getMembers() {
        this.$http.post((this.useruid==''? "/api/organization/members" : "/api/powerteam/members/byuseruid"), {useruid: this.useruid, id: this.powerteam.id}).then((response) => {
          
            this.members = response.data.members;
            if(this.powerteam.id==0 && response.data.success){
              this.isManager = true;
            }
            this.getPowerteam();
        }).catch((errors) => {console.log(errors);});
    },
    
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-powerteams"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.powerteam.id = this.$route.params.id;
      this.getMembers();
      this.addClass(document.getElementById("menu-powerteams"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
    }
  }
</script>
