<template>
  <div> 
    <div class="rel-grid-container" >
      <div ></div>
      <div id="centreDiv" class="fit-width">  
        <Tip :tipNumber="14"></Tip>
        <div v-if="onboard && showAlert">
          <b-alert variant="danger"
                  dismissible
                  :show="showAlert"
                  @dismissed="showAlert=false">
            {{alertText}}
          </b-alert>
        </div>
        <form v-on:submit.prevent="update">
          <span class="form-box-title">{{$t('general.identification_text')}}</span>
          <div id="id-form-box" class="form-box">
            <span class="form-box-name">{{$t('general.name_text')}}</span><b-form-input :state="organization_state.name" v-model="organization.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" id="name-input" class="form-box-input" :disabled="!permissions.organization.is_parent" required maxlength="255"></b-form-input>
            <span v-if="permissions.organization.official" class="form-box-name">{{$t('general.website_text')}}</span><b-form-input v-if="permissions.organization.official" v-model="organization.website" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" maxlength="255"></b-form-input>
            <span class="form-box-name">{{$t('general.qr_code')}}</span><b-form-input  v-model="organization.apply_url" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"  class="form-box-input" maxlength="255"></b-form-input>
            <span class="form-box-name">{{$t('general.default_language')}}</span><b-form-select v-model="organization.language" :options="languageOptions" class="mb-3 form-box-input" value-field="abbrev" text-field="name"/>
          </div>

          <span v-if="!onboard" class="form-box-title">{{$t('general.meeting_location')}}</span>
          <div id="location-form-box" class="form-box" v-if="!onboard">
            <span class="form-box-name">{{$t('general.meeting_format')}}</span><b-form-select v-model="organization.meeting_format" :options="formatOptions" class="mb-3 form-box-input"/>
            <span class="form-box-name">{{$t('general.location_text')}}</span><b-form-input v-model="organization.location" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" maxlength="255"></b-form-input>
            <span class="form-box-name">{{$t('general.address_text')}}</span><b-form-input v-model="organization.address" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" maxlength="255"></b-form-input>
            <span class="form-box-name">{{$t('general.city_text')}}</span><b-form-input v-model="organization.city" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" maxlength="100"></b-form-input>
            <div id="postal-div"><span class="form-box-name">{{$t('general.postal_code')}}</span><b-form-input v-model="organization.postal_code" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" maxlength="10"></b-form-input></div>
            <span class="form-box-name">{{$t('general.country_text')}}</span><country-select class="custom-select form-box-input" v-model="organization.country"  :country="organization.country" />
            <span class="form-box-name" v-if="organization.country!=''">{{$t('general.region_text')}}</span><region-select v-if="organization.country!=''" class="custom-select form-box-input" v-model="organization.region"  :country="organization.country" :region="organization.region" />
          </div>

          <span class="form-box-title">{{$t('general.meeting_time')}}</span>
          <div id="times-anchor" class="form-box grid-split-3column" >
            <SetupTip class="setup-tip" :tipNumber="2"/>
            <span v-if="permissions.organization.official && !isCorporateConnections" class="form-box-name">{{$t('general.day_of_week')}}</span>
            <b-form-select v-if="permissions.organization.official && !isCorporateConnections" :state="organization_state.meet_day_of_week" v-model="organization.meet_day_of_week" :options="dayOptions" class="mb-3 form-box-input" required />
            <span class="form-box-name">{{$t('general.time_zone')}}</span>
            <b-form-select v-model="organization.meet_timezone" :options="timezoneOptions" value-field="value" text-field="name" class="mb-3 form-box-input" />
            <span v-if="permissions.organization.official && !isCorporateConnections" class="form-box-name">{{$t('general.meeting_start_networking')}}</span>
            <b-form-timepicker v-if="permissions.organization.official && !isCorporateConnections" minutes-step="5" :label-no-time-selected="$t('global.no_time')"  class="form-box-input time-picker" :state="organization_state.meet_pre_start" v-model="organization.meet_pre_start" :locale="$i18n.locale" required></b-form-timepicker>
            <span v-if="permissions.organization.official && !isCorporateConnections" class="form-box-name">{{$t('general.meeting_start_time')}}</span>
            <b-form-timepicker v-if="permissions.organization.official && !isCorporateConnections" minutes-step="5" :label-no-time-selected="$t('global.no_time')" class="form-box-input time-picker" :state="organization_state.meet_start" v-model="organization.meet_start" :locale="$i18n.locale" required></b-form-timepicker>
            <span v-if="permissions.organization.official && !isCorporateConnections" class="form-box-name">{{$t('general.end_time')}}</span>
            <b-form-timepicker v-if="permissions.organization.official && !isCorporateConnections" minutes-step="5" :label-no-time-selected="$t('global.no_time')" class="form-box-input time-picker" :state="organization_state.meet_end"  v-model="organization.meet_end" :locale="$i18n.locale"></b-form-timepicker>
            
            <span class="form-box-name">{{$t('general.weekly_presentation')}}</span>
            <div class="time-box">
              <span class="form-box-name">{{$t('general.min_text')}}</span><b-form-input v-model="organization.weekly_presentation_min" type="number" class="small-input" required/>
            </div>
            <div class="time-box">
              <span class="form-box-name">{{$t('general.sec_text')}}</span><b-form-input v-model="organization.weekly_presentation_sec" type="number" class="small-input" required/>
            </div>
            <span class="form-box-name">{{$t('general.speaker_presentation')}}</span>
            <div class="time-box">
              <span class="form-box-name">{{$t('general.min_text')}}</span><b-form-input v-model="organization.speaker_presentation_min" type="number" class="small-input" required />
            </div>
            <div class="time-box">
              <span class="form-box-name">{{$t('general.sec_text')}}</span>
              <b-form-input v-model="organization.speaker_presentation_sec" type="number" class="small-input" required/>
            </div>
            <span v-if="permissions.organization.official" class="form-box-name">{{$t('general.referral_round')}}</span>
            <div v-if="permissions.organization.official" class="time-box">
              <span class="form-box-name">{{$t('general.min_text')}}</span>
              <b-form-input v-model="organization.referral_min" type="number" class="small-input" required/>
            </div>
            <div v-if="permissions.organization.official" class="time-box">
              <span class="form-box-name">{{$t('general.sec_text')}}</span>
              <b-form-input v-model="organization.referral_sec" type="number" class="small-input" required/>
            </div>
          </div>
          <span class="form-box-title">{{$t('general.meeting_settings')}}</span>
          <div id="settings-form-box" class="form-box" >
            <b-form-checkbox v-model="organization.cron_speakers" class="form-box-input left-text" value="1" unchecked-value="0">
              {{$t('general.debug_mode')}}
            </b-form-checkbox>
            <b-form-checkbox v-if="permissions.organization.official && permissions.organization.allow_powerteams==1" v-model="organization.email_powerteams" class="form-box-input left-text" value="1" unchecked-value="0">
              {{$t('general.powerteam_notifications')}}
            </b-form-checkbox>
            <b-form-checkbox v-if="permissions.organization.official && !isCorporateConnections"  v-model="organization.cron_followup" class="form-box-input left-text" value="1" unchecked-value="0">
              {{$t('general.email_followup')}}
            </b-form-checkbox>
            <b-form-checkbox  v-model="organization.scroll_to_member" class="form-box-input left-text" value="1" unchecked-value="0">
              {{$t('general.auto_position')}}
            </b-form-checkbox>
            <b-form-checkbox v-if="permissions.organization.official && !isCorporateConnections" v-model="organization.disable_events_email" class="form-box-input left-text" :value="0" :unchecked-value="1">
              {{$t('general.send_events')}}
            </b-form-checkbox>
             <span v-if="permissions.organization.official" class="form-box-name">{{$t('general.bio_limits')}}</span>
            <div v-if="permissions.organization.official" class="time-box">
              <span class="form-box-name">{{$t('general.bio_recommended')}}</span><b-form-input id="recommended-words" v-model="organization.recommended_words" type="number" required/>
            </div>
            <div v-if="permissions.organization.official"    class="time-box">
              <div id="bio-cap">
                <b-form-checkbox v-model="organization.cap_words" value="1" unchecked-value="0">
                  {{$t('general.bio_cap')}}
                </b-form-checkbox>
              </div>
              <div >
                <b-form-input v-if="organization.cap_words==1" id="max-words" v-model="organization.max_words" type="number" required/>
              </div>
            </div>
            <span v-if="permissions.organization.official"  class="form-box-name">{{$t('general.prep_notes')}}</span><b-form-textarea v-if="permissions.organization.official" id="speaker-notes" v-model="organization.speaker_prep_notes" placeholder="" class="form-box-input" :rows="2" :max-rows="2" />
           <span v-if="permissions.organization.official && !isCorporateConnections"  class="form-box-name">{{$t('general.closing_quote')}}</span>
           <div v-if="permissions.organization.official && !isCorporateConnections" id="quote-managers-box" class="fit-width">
            <b-form-radio v-model="organization.quote_to_speakers" value="0" >
                {{$t('general.manager_below')}}
              </b-form-radio>
              <b-form-radio v-model="organization.quote_to_speakers" value="1">
                {{$t('general.featured_speaker')}}
              </b-form-radio>
          </div>  
            <span v-if="!permissions.organization.official && !permissions.organization.rst"  class="form-box-name">{{$t('region.min_applicants')}}</span><b-form-input v-if="!permissions.organization.official && !permissions.organization.rst" id="min-applicants" :min="organization.parent_min_applicants"  v-model="organization.min_applicants" type="number" required pattern="[^<>]*" :title="$t('global.pattern_title')"></b-form-input>
           <div v-if="analytics.length>0" id="chapter-values">
             {{$t('general.chapter_values')}}
           </div>
           <div v-if="analytics.length>0" id="analytics-box" class="fit-content">
             <div v-for="analytic in analytics" :key="analytic.id" class="analytic-wrapper">
               <span class="form-box-name">{{analytic.name}}</span><b-form-input v-model="analytic.value" class="analytic-input" />
             </div>
           </div>
          </div>
          <span class="form-box-title" v-if="permissions.organization.official && !isCorporateConnections" >{{$t('general.members_assigned_to')}}</span>
          <div id="delegate-anchor" v-if="permissions.organization.official && !isCorporateConnections" class="form-box" >
            <SetupTip class="setup-tip"   :tipNumber="4"/>
            <span class="form-box-name">{{$t('general.email_bios')}}</span>
             <multiselect class="blueselect member-multiselect" v-model="bio_emails" :options="members" label="name" :placeholder="$t('global.select_members')" :noOptionsText="$t('global.empty_list')"  mode="tags" trackBy="name" valueProp="id" :close-on-select="false" :searchable="true">
              <template v-slot:option="{ option }">
                <div><span>{{ option.name }}</span> - <span>{{ option.email.length>0 ? option.email : $t('global.no_email')  }}</span></div>
                </template>
            </multiselect>
            <span class="form-box-name">{{$t('general.email_attendance')}}</span>
            <multiselect class="blueselect member-multiselect" v-model="emails" :options="members" label="name" :placeholder="$t('global.select_members')" :noOptionsText="$t('global.empty_list')"  mode="tags" trackBy="name" valueProp="id" :close-on-select="false" :searchable="true">
              <template v-slot:option="{ option }">
                <div><span>{{ option.name }}</span> - <span>{{ option.email.length>0 ? option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>
            <span class="form-box-name">{{$t('general.quotes_to')}}</span>
            <multiselect class="blueselect member-multiselect" v-model="quote_emails" :options="members" label="name" :placeholder="$t('global.select_members')" :noOptionsText="$t('global.empty_list')"  mode="tags" trackBy="name" valueProp="id" :close-on-select="false" :searchable="true">
              <template v-slot:option="{ option }">
                <div><span>{{ option.name }}</span> - <span>{{ option.email.length>0 ? option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>
           
            <span class="form-box-name">{{$t('general.enters_vp')}}</span>
            <multiselect class="blueselect member-multiselect" v-model="tracking_emails" :options="members" label="name" :placeholder="$t('global.select_members')" :noOptionsText="$t('global.empty_list')"  mode="tags" trackBy="name" valueProp="id" :close-on-select="false" :searchable="true">
              <template v-slot:option="{ option }">
                <div><span>{{ option.name }}</span> - <span>{{ option.email.length>0 ? option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>
            <span  class="form-box-name">{{$t('general.events_emails')}}</span>
            <multiselect class="blueselect member-multiselect" v-model="events_emails" :options="members" label="name" :placeholder="$t('global.select_members')" :noOptionsText="$t('global.empty_list')"  mode="tags" trackBy="name" valueProp="id" :close-on-select="false" :searchable="true">
              <template v-slot:option="{ option }">
                <div><span>{{ option.name }}</span> - <span>{{ option.email.length>0 ? option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>
             
            <span  class="form-box-name">{{$t('general.newsletter_emails')}}</span>
            <multiselect  class="blueselect member-multiselect" v-model="newsletter_emails" :options="members" label="name" :placeholder="$t('global.select_members')" :noOptionsText="$t('global.empty_list')"  mode="tags" trackBy="name" valueProp="id" :close-on-select="false" :searchable="true">
              <template v-slot:option="{ option }">
                <div><span>{{ option.name }}</span> - <span>{{ option.email.length>0 ? option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>

            <span  class="form-box-name">{{$t('general.categories_emails')}}</span>
            <multiselect  class="blueselect member-multiselect" v-model="categories_emails" :options="members" label="name" :placeholder="$t('global.select_members')" :noOptionsText="$t('global.empty_list')"  mode="tags" trackBy="name" valueProp="id" :close-on-select="false" :searchable="true">
              <template v-slot:option="{ option }">
                <div><span>{{ option.name }}</span> - <span>{{ option.email.length>0 ? option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>

            <span  class="form-box-name">{{$t('general.speakers_emails')}}</span>
            <multiselect  class="blueselect member-multiselect" v-model="speakers_emails" :options="members" label="name" :placeholder="$t('global.select_members')" :noOptionsText="$t('global.empty_list')"  mode="tags" trackBy="name" valueProp="id" :close-on-select="false" :searchable="true">
              <template v-slot:option="{ option }">
                <div><span>{{ option.name }}</span> - <span>{{ option.email.length>0 ? option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>

            <span v-if="(permissions.organization.id == 3 || permissions.organization.allow_powerteams==1)" class="form-box-name">{{$t('general.powerteam_emails')}}</span>
            <multiselect v-if="(permissions.organization.id == 3 || permissions.organization.allow_powerteams==1)" class="blueselect member-multiselect" v-model="powerteam_emails" :options="members" label="name" :placeholder="$t('global.select_members')" :noOptionsText="$t('global.empty_list')"  mode="tags" trackBy="name" valueProp="id" :close-on-select="false" :searchable="true">
              <template v-slot:option="{ option }">
                <div><span>{{ option.name }}</span> - <span>{{ option.email.length>0 ? option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>
          </div>
          
          <div v-if="permissions.organization.official && !isCorporateConnections" >
            <span class="form-box-title">{{$t('general.social_media_links')}}</span>
            <div id="media-form-box" class="form-box">
              <div id="media-select">
                  <span class="form-box-name">{{$t('general.add_channel')}}</span>
                  <multiselect class="blueselect " v-model="media_links" :options="mediaOptions" label="name" :placeholder="$t('global.select_members')" :noOptionsText="$t('global.empty_list')" :object="true"  mode="tags" trackBy="name" valueProp="channel_id" :close-on-select="false" :searchable="true">
                    <template v-slot:tag="{ option, handleTagRemove}">
                      <div class="multiselect-tag is-user">
                        {{ option.name }}
                        <span class="multiselect-tag-remove" @click="handleTagRemove(option, $event)">
                        <span class="multiselect-tag-remove-icon"></span>
                        </span>
                      </div>
                    </template>
                    <template v-slot:option="{ option }">
                      <div><img class="image-preview" :src="option.image"> <span>{{ option.name  }}</span></div>
                    </template>
                  </multiselect>
                </div>
                  <span class="form-box-name center-text" >{{$t('general.selected_media')}}</span>
                  <table class="table b-table  table-sm table-hover-new ">
                    <thead>
                      <tr>
                        <th >{{$t('general.channel_header')}}</th>
                        <th class="left-text">{{$t('general.url_header')}}</th>
                        <th >{{$t('general.remove_header')}}</th>
                      </tr>
                    </thead>
                    <draggable v-model="media_links" item-key="channel_id" tag="tbody">
                      <template #item="{element}">
                        <tr>
                          <td><img class="image-preview" :src="element.image"></td>
                          <td><b-form-input v-model="element.link" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="media-link" maxlength="255"></b-form-input></td>
                          <td><b-button class="trash-button" variant="blue" v-on:click="deleteLink(element)"></b-button></td>
                        </tr>
                        </template>
                    </draggable>
                  </table>
            </div>
          </div>
           <div v-if="isCorporateConnections" >
            <span class="form-box-title">{{$t('general.chapter_mission')}}</span>
            <div id="mission-form-box" class="form-box">
                <b-form-textarea v-model="organization.mission_statement" placeholder="" :rows="3"/>
            </div>
          </div>
          <div v-if="!onboard && showAlert">
          <b-alert variant="danger"
                  dismissible
                  :show="showAlert"
                  @dismissed="showAlert=false">
            {{alertText}}
          </b-alert>
        </div>
          <div class="submit-box-2" v-if="!onboard">
            <b-button class="action-button" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </form>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>

<style scoped>
.media-link{
  max-width: 200px;
}
#min-applicants{
  max-width: 300px;
}

#delegate-anchor{
  grid-template-columns: max-content auto auto ;grid-row-gap: 10px;position:relative;
}
.analytic-input{
  width:250px;
}
.analytic-wrapper{
  display:grid;grid-template-columns: auto 250px;
}
#analytics-box{
  display:grid; grid-column: 1/4; grid-template-columns: 1fr;margin:auto;
}
#chapter-values{
  width:100%;text-align:center;grid-column: 1/4;
}
#quote-managers-box{
  display:grid;grid-template-columns: auto auto;margin: auto 10px;grid-gap: 20px;grid-column: 2/4;
}
#speaker-notes{
  margin-bottom: 10px;
}
#recommended-words, #max-wrods{
  max-width: 100px;
}
#bio-cap{
  text-align:left;padding-top:8px;margin-left:10px;
}
#media-select{
  display: grid;
  grid-template-columns: max-content auto;
}
#media-form-box{
  grid-template-columns: auto;
}
.image-preview{
  height:40px;
}
#mission-form-box{
  grid-template-columns:auto;position:relative;
}
#name-input{
  max-width: 300px;
}
#location-form-box{
  grid-template-columns: 25% 33% 42%;
}
#id-form-box{
  grid-template-columns: 30% 70%;position:relative;
}
#settings-form-box{
  grid-template-columns: auto auto auto ;grid-row-gap: 10px;
}
.small-input{
  width:150px;
}
#centreDiv {
  text-align: center;
  margin: auto;
}
#postal-div {
       display: grid;
       grid-template-columns: 50% auto;
     }

  .member-multiselect{
    grid-column: 2/4;
  }
</style>
<script>
  import router from "../router"

  export default {
    name: "General",
    watch: {
      '$i18n.locale': {
        handler: function () {
           this.changeLanguage();
        },
        deep: true
      }
    },
    props:{
      onboard: Boolean,
    },
    data() {
      return {
        formatOptions: [{value: 0, text: this.$t('general.none_text')},{value: 1, text: this.$t('general.online_text')},{value: 2, text: this.$t('general.inperson_text')},{value: 3, text: this.$t('general.hybrid_text')}],
        regionChapters: [],
        mediaOptions:  [],
        region2: '',
        region: '',
        country: '',
        saveVariant: "blue",
        savedSuccess: false,
        organization : {
          name: "",
          website: "",
          location: "",
          address: "",
          city: "",
          postal_code: "",
          country: 'CA',
          region: 'ON',
          meet_interval_days: 0,
          meet_day_of_week: 0,
          meet_timezone: "",
          language: 'en',
          categories_num: 0,
          weekly_presentation_min: 1,
          weekly_presentation_sec: 0,
          speaker_presentation_min: 1,
          speaker_presentation_sec: 0,
          referral_min: 1,
          referral_sec: 0,
          recommended_words: 100
        },
        organization_state:{
          name: null,
          meet_start: null,
          meet_pre_start: null,
          meet_day_of_week: null,
          tracking_periods: null,
          meet_end: null
        },
        showAlert: false,
        alertText: "",
        intervalOptions: [
          //{ value: -1, text: 'Please select an option' },
          { value: 0, text: this.$t('general.interval_option_1') },
          //{ value: 1, text: 'Bi-weekly' },
          //{ value: 2, text: 'Monthly' }
        ],
        trackingOptions: [
          { value: 1, text: this.$t('general.tracking_option_1') },
          { value: 2, text: this.$t('general.tracking_option_2') },
          { value: 4, text: this.$t('general.tracking_option_4') },
          { value: 8, text: this.$t('general.tracking_option_8') },
          { value: 16, text: this.$t('general.tracking_option_16') },
          { value: 32, text: this.$t('general.tracking_option_32') },
          { value: 64, text: this.$t('general.tracking_option_64') },
        ],
        dayOptions: [
          { value: -1, text: this.$t('general.day_option_0') },
          { value: 0, text: this.$t('general.day_option_1') },
          { value: 1, text: this.$t('general.day_option_2') },
          { value: 2, text: this.$t('general.day_option_3') },
          { value: 3, text: this.$t('general.day_option_4') },
          { value: 4, text: this.$t('general.day_option_5') },
          { value: 5, text: this.$t('general.day_option_6') },
          { value: 6, text: this.$t('general.day_option_7') }
        ],
        languageOptions: [],
        timezones : [
          { name: "(GMT -12:00) Eniwetok, Kwajalein", value: "546" },
          { name: "(GMT -11:00) Midway Island, Samoa", value: "562" },
          { name: "(GMT -10:00) Hawaii", value: "583" },
          { name: "(GMT -9:30) Taiohae", value: "548" },
          { name: "(GMT -9:00) Alaska", value: "577" },
          { name: this.$t('timezone.pacific'), value: "379" },
          { name: this.$t('timezone.mountain'), value: "377" },
          { name: this.$t('timezone.central'), value: "375" },
          { name: this.$t('timezone.eastern'), value: "376" },
          { name: this.$t('timezone.atlantic'), value: "374" },
           { name: this.$t('timezone.eastern_daylight'), value: "213" },
          { name: "Newfoundland Time", value: "378" },
          { name: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "86" },
          { name: "(GMT -2:00) Mid-Atlantic", value: "411" },
          { name: "(GMT -1:00) Azores, Cape Verde Islands", value: "336"},
          { name: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "451" },
          { name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "433" },
          { name: "(GMT +2:00) Kaliningrad, South Africa", value: "446" },
          { name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "458" },
          { name: "(GMT +3:30) Tehran", value: "316" },
          { name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "245" },
          { name: "(GMT +4:30) Kabul", value: "275" },
          { name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "277" },
          { name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "251" },
          { name: "(GMT +5:45) Kathmandu, Pokhara", value: "279" },
          { name: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "259" },
          { name: "(GMT +6:30) Yangon, Mandalay", value: "330" },
          { name: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "246" },
          { name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "360" },
          { name: "(GMT +8:45) Eucla", value: "352" },
          { name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "320" },
          { name: "(GMT +9:30) Adelaide, Darwin", value: "320" },
          { name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "328" },
          { name: "(GMT +10:30) Lord Howe Island", value: "356" },
          { name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "394" },
          { name: "(GMT +11:30) Norfolk Island", value: "552" },
          { name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "528" },
          { name: "(GMT +12:45) Chatham Islands", value: "530" },
          { name: "(GMT +13:00) Apia, Nukualofa", value: "527" }
        ],
        timezoneOptions: [],
        emails: [],
        bio_emails: [],
        quote_emails: [],
        tracking_emails: [],
        tracking_nums: [],
        events_emails: [],
        newsletter_emails: [],
        categories_emails: [],
        speakers_emails: [],
        powerteam_emails: [],
        members: [],
        original_language: 'en',
        media_links: [],
        analytics: [],
        showPhotoEdit: false,
        tempPhoto: "",
        imgSrc: '',
        quoteSendOptions: [{value: 0, text: this.$t('general.manager_below')},{value: 1, text: this.$t('general.featured_speaker')}]
      }
        
    },
    methods: {
      getAnalytics() {
        this.$http.post("/api/organization/analytics/get", { }).then((response) => {
          if (response.data.success) {
            this.analytics = response.data.analytics.filter(v => !v.isParent && v.type==1);
          }
        }).catch(() => {
        });
      },
      updateAnalytics() {
        this.$http.post("/api/organization/analytics/update", {analytics: this.analytics }).then(() => {
        }).catch((errors) => {
          console.log(errors);
          this.$bvToast.show('serverError');
        });
      },
      deleteLink(m){
        var index = this.media_links.indexOf(m);
        if (index > -1) {
          this.media_links.splice(index, 1);
        }
      },
      changeLanguage() {
        this.intervalOptions = [
          { value: 0, text: this.$t('general.interval_option_1') },
        ];
        this.trackingOptions= [
          { value: 1, text: this.$t('general.tracking_option_1') },
          { value: 2, text: this.$t('general.tracking_option_2') },
          { value: 4, text: this.$t('general.tracking_option_4') },
          { value: 8, text: this.$t('general.tracking_option_8') },
          { value: 16, text: this.$t('general.tracking_option_16') },
          { value: 32, text: this.$t('general.tracking_option_32') },
          { value: 64, text: this.$t('general.tracking_option_64') },
        ];
        this.tracking_nums = [];
        for(var i=0; i<this.trackingOptions.length;i++){
          if((this.organization.tracking_periods & Math.pow(2,i)) != 0){
            this.tracking_nums.push(this.trackingOptions[i]);
          }
        }
        this.dayOptions = [
          { value: -1, text: this.$t('general.day_option_0') },
          { value: 0, text: this.$t('general.day_option_1') },
          { value: 1, text: this.$t('general.day_option_2') },
          { value: 2, text: this.$t('general.day_option_3') },
          { value: 3, text: this.$t('general.day_option_4') },
          { value: 4, text: this.$t('general.day_option_5') },
          { value: 5, text: this.$t('general.day_option_6') },
          { value: 6, text: this.$t('general.day_option_7') }
        ];
        this.timezones[5].name = this.$t('timezone.pacific');
        this.timezones[6].name =  this.$t('timezone.mountain');
        this.timezones[7].name =  this.$t('timezone.central');
        this.timezones[8].name =  this.$t('timezone.eastern');
        this.timezones[9].name =  this.$t('timezone.atlantic');

        this.getLanguages();
      },
      getTimeZones() {
        this.$http.post("/api/organization/timezones/regional", {}).then((response) => {
          if (response.data.success === true) {
            for(var i=0; i<this.timezones.length; i++){
              if(response.data.timezone_ids_string.indexOf(this.timezones[i].value)>-1){
                this.timezoneOptions.push(this.timezones[i]);
              }
            }
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      getLanguages(){
        this.$http.post("/api/organization/languages", {}).then((response) => {
          if (response.data.success === true) {
            this.languageOptions = response.data.languages;
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      
      checkForm(){
        this.organization_state ={
          name: null,
          meet_start: null,
          meet_pre_start: null,
          meet_day_of_week: null,
          meet_end: null
        };
        this.showAlert = false;
        this.alertText = "";
        var allgood = true;
        if(this.organization.name.length == 0){
          allgood = false;
          this.organization_state.name = false;
        }
        if(this.organization.meet_day_of_week < 0){
          allgood = false;
          this.organization_state.meet_day_of_week = false;
        }
        if(this.organization.meet_start == null || this.organization.meet_start == ""){
          allgood = false;
          this.organization_state.meet_start = false;
        }
        if(this.organization.meet_end == null || this.organization.meet_end == ""){
          allgood = false;
          this.organization_state.meet_end = false;
        }
        if(this.organization.meet_pre_start == null || this.organization.meet_pre_start == ""){
          allgood = false;
          this.organization_state.meet_pre_start = false;
        }
        if(!allgood){
          this.showAlert = true;
          this.alertText = this.$t("general.alert_text")
        }
        return allgood;
      },
      update(e) {
        this.savedSuccess = false;
        if(!this.checkForm()){
          return;
        }

        if(e){
          e.preventDefault();
        }
        this.organization.email_list = this.emails.join(",");
        this.organization.bio_email_list = this.bio_emails.join(",");
        this.organization.quote_email_list = this.quote_emails.join(",");
        this.organization.period_data_emails = this.tracking_emails.join(",");
        this.organization.manages_events_list= this.events_emails.join(",");
        this.organization.manages_newsletter_list= this.newsletter_emails.join(",");
        this.organization.manages_categories_list= this.categories_emails.join(",");
        this.organization.manages_speakers_list= this.speakers_emails.join(",");
        this.organization.manages_powerteams_list = this.powerteam_emails.join(",");
        this.organization.tracking_periods = 0;
        for(var i=0; i<this.tracking_nums.length;i++){
          this.organization.tracking_periods |= this.tracking_nums[i].value;
        }
        this.updateAnalytics();
        this.original_language = this.organization.language;
        this.organization.media_links = this.media_links;
        this.organization.postal_code = this.organization.postal_code.substring(0,20);

        this.$http.post("/api/organization/update", this.organization).then((response) => {
          if (response.data.success === true) {
            this.savedSuccess = true;
            this.$root.$emit('orgChange', this.organization.name);
            this.saveVariant = 'blue';
            if(!this.onboard){
              this.back();
            }
          } else {
            this.showAlert = true;
            this.alertText = response.data.error.message;
            this.savedSuccess = false;
            this.saveVariant = 'danger';
          }
        }).catch((errors) => {
          console.log(errors);
          this.$bvToast.show('serverError');
        });
      },
       back(){
            router.push("/admin/dashboard");
          },
      pull() {
        this.$http.post("/api/organization/get", {}).then((response) => {
          if (response.data.success) {
            this.organization = response.data.organization;
            this.original_language = this.organization.language;
            this.organization.photo = this.organization.banner;

            this.media_links = response.data.media_links;
            this.emails = this.organization.email_list? this.organization.email_list.split(",") : [];
            this.bio_emails = this.organization.bio_email_list ? this.organization.bio_email_list.split(",") : [];
            this.quote_emails = this.organization.quote_email_list? this.organization.quote_email_list.split(",") : [];
            this.tracking_emails = this.organization.period_data_emails? this.organization.period_data_emails.split(",") : [];
            this.events_emails = this.organization.manages_events_list? this.organization.manages_events_list.split(",") : [];
            this.newsletter_emails = this.organization.manages_newsletter_list? this.organization.manages_newsletter_list.split(",") : [];
            this.categories_emails = this.organization.manages_categories_list? this.organization.manages_categories_list.split(",") : [];
            this.speakers_emails = this.organization.manages_speakers_list? this.organization.manages_speakers_list.split(",") : [];
            this.powerteam_emails = this.organization.manages_powerteams_list? this.organization.manages_powerteams_list.split(",") : [];

            this.tracking_nums = [];
            for(var i=0; i<this.trackingOptions.length;i++){
              if((this.organization.tracking_periods & Math.pow(2,i)) != 0){
                this.tracking_nums.push(this.trackingOptions[i]);
              }
            }
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      getMembers() {
        this.$http.post("/api/organization/members/email/select", {}).then((response) => {
          this.members = response.data.members;
          this.pull();
        }).catch(() => {});
      },
      getRegionChapters() {
        this.$http.post("/api/region/chapters", {}).then((response) => {
          this.regionChapters = response.data.chapters;
          this.regionChapters.unshift({id: 0, name: this.$t('general.no_join_meeting')});
        }).catch(() => {});
      },
      getMediaOptions() {
        this.$http.post("/api/media/types/get", {}).then((response) => {
          this.mediaOptions = response.data.types;
        }).catch(() => {});
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-general"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-general"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getMembers();
      this.getLanguages();
      this.getTimeZones();
      this.getAnalytics();
      this.getRegionChapters();
      this.getMediaOptions();
    }  
  }
</script>
