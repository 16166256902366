<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <div>
          <Tip :tipNumber="11"></Tip>
        <table class="table b-table table-hover-new table-bordered table-sm">
          <thead>
            <tr>
              <th class="tableHeader">{{$t('goals.goal_text')}}</th>
              <th class="tableHeader center-text">{{$t('goals.target_value')}}</th>
              <th class="tableHeader center-text">{{$t('goals.last_update')}}</th>
              <th class="tableHeader center-text">{{$t('goals.last_value')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="goal in goals" :key="goal.id" v-on:click="editGoal(goal)" v-bind:class="{ disabledClass : goal.disabled}">
              <td>{{goal.title}}</td>
              <td class="center-text">{{goal.target}}</td>
              <td class="center-text">{{goal.day}}</td>
              <td class="center-text">{{(goal.value != null? goal.value : "")}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="submit-box-2">
        <b-button v-if="permissions.access" class="action-button" v-on:click="newGoal" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
    
  import router from "../router"
    export default {
      name: "Goals",
      data() {
        return {
          goals: []
        }
      },
    methods: {
      getGoals() {
        this.$http.post("/api/goals/get", {}).then((response) => {
          if (response.data.success) {
            this.goals = response.data.goals;
          }
        }).catch(() => {
        });
      },
      newGoal() {
        router.push("/admin/goal/0");
      },
      editGoal(item) {
        if (!item.dis) {
          router.push("/admin/goal/" + item.id);
        }
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-goals"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-goals"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getGoals();
    }
    }
</script>
