<template>
    <div>
        <div class="rel-grid-container">
        <div ></div>
        <div>
            <div id="region-select-box">
                <span class="form-box-name">{{$t('region.from_text')}}</span><b-form-select v-model="move.region" :options="regions" value-field="id" text-field="name" class="region-select input-vmargins" />
                    <span class="next-button skinned-image"></span>
                <span class="form-box-name">{{$t('region.to_text')}}</span><b-form-select :disabled="move.region==0" v-model="move.to_region" :options="regions.filter(v => v.id != move.region && (v.official == regions.filter(h => h.id == move.region)[0].official || v.id == 0))" value-field="id" text-field="name" class="region-select input-vmargins" />
            </div>
            <div class="grid-auto-3column">
                <b-table v-if="move.region>0" class="table-hover-new fit-height" small bordered :items="chapters" :fields="fields">
                <template v-slot:cell(move)="data">
                    <b-form-checkbox v-model="data.item.move" class="auto-margin">
                    </b-form-checkbox>
                </template>
                </b-table>
                <span v-if="move.region>0 && move.to_region>0" class="next-button skinned-image"></span>
                <b-table v-if="move.to_region>0" class="table-hover-new fit-height" small bordered :items="chapters2" :fields="fields2">
                </b-table>
            </div>
            <div v-if="showUpload">
                <b-alert class="alert-line-height" variant="warning" :show="showUpload">
                {{$t('global.please_wait')}}
                <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
                </b-alert>
            </div>
            <b-button class="action-button auto-margin" :disabled="showUpload" v-if="chapters.filter(v => v.move).length>0 && move.to_region>0 && move.region>0" v-on:click="moveChapters" variant="clear-blue"><div class="next-button"></div><span>{{$t('region.button_move')}}</span></b-button>
        </div>          
        <div class="div-space"></div>
      </div>
    </div>
  </template>
<style scoped>
    #region-select-box{
        display: grid;
        grid-template-columns: max-content max-content auto max-content max-content;
        max-width: 800px;
        margin: 30px auto;
    }
    .region-select{
        width:300px
    }
</style>
  <script>
      export default {
        name: "MoveChapters",
        data() {
          return {
            showUpload: false,
            chapters: [],
            chapters2: [],
            regions: [],
            move: {region: 0, chapters: [], to_region: 0},
            fields: [
            
            { key: 'move',
              label: this.$t('region.move_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align":"center"},
              tdClass: "actions-centre"
            },
            {
              key: 'name',
              label: this.$t('region.chapter_name'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
          ],
          fields2: [
            {
              key: 'name',
              label: this.$t('region.chapter_name'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
          ],
          }
        },
        watch: {
        'move.region': {
            handler: function () {
                if(this.move.region>0){
                    this.getChapters();
                }
                if(!(this.move.region in this.regions.filter(v => v.id != this.move.region && (v.official == this.regions.filter(h => h.id == this.move.region)[0].official || v.id == 0)))){
                    this.move.to_region = 0;
                }
            },
            deep: true
        },
        'move.to_region': {
            handler: function () {
                if(this.move.to_region>0){
                    this.getChapters2();
                }
            },
            deep: true
        }
        
        },
      methods: {
        getRegions(){
            this.move=  {region: 0, chapter: 0, to_region: 0};
            this.$http.post("/api/superadmin/regions/formove", {}).then((response) => {
            if (response.data.success) {
                this.regions = response.data.regions;
                this.regions.unshift({id: 0, name: this.$t('region.select_region')});
            }
            }).catch(() => {
        });
      },
      getChapters(){
        this.$http.post("/api/superadmin/chapters", {region: this.move.region}).then((response) => {
          if (response.data.success) {
            for(var chapter of response.data.chapters){
                chapter.move = false;
            }
            this.chapters = response.data.chapters;
          }
        }).catch(() => {
        });
      },
      getChapters2(){
        this.$http.post("/api/superadmin/chapters", {region: this.move.to_region}).then((response) => {
          if (response.data.success) {
            for(var chapter of response.data.chapters){
                chapter.move = false;
            }
            this.chapters2 = response.data.chapters;
          }
        }).catch(() => {
        });
      },
      moveChapters(){
        this.move.chapters = this.chapters.filter(v => v.move).map(v => parseInt(v.id));
        if(this.move.chapters.length>0){
            this.showUpload = true;
            this.$http.post("/api/superadmin/chapters/move", this.move).then((response) => {
                this.showUpload = false;
            if (response.data.success) {
                this.$bvToast.toast(this.$t('region.chapters_moved'), {
                    title: this.$t('region.success_text'),
                    variant: 'success',
                    solid: true
                });
                this.getChapters();
                this.getChapters2();
            }
            }).catch(() => {
                this.showUpload = false;
            });
        }
      },
      },
      destroyed() {
        this.removeClass(document.getElementById("menu-move"), "active-menu");
        this.removeClass(document.getElementById("menu-admin"), "active-menu");
      },
      mounted() {
        this.getRegions();
        this.addClass(document.getElementById("menu-move"), "active-menu");
        this.addClass(document.getElementById("menu-admin"), "active-menu");
      }
      }
  </script>
  