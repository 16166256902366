<template>
<div class="editor">
  <div v-if="editor">
    <button  v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': editor.isActive('bold') }" v-on:click.prevent="editor.chain().focus().toggleBold().run()"><b>B</b></button>
    <button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': editor.isActive('italic') }" v-on:click.prevent="editor.chain().focus().toggleItalic().run()"><i>I</i></button>
    <button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': editor.isActive('underline') }" v-on:click.prevent="editor.chain().focus().toggleUnderline().run()"><u>U</u></button>
    <button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': editor.isActive('superscript') }" v-on:click.prevent="editor.chain().focus().toggleSuperscript().run()">S<sup>s</sup></button>
    <button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }" v-on:click.prevent="editor.chain().focus().toggleHeading({ level: 1 }).run()">H1</button>
    <button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }" v-on:click.prevent="editor.chain().focus().toggleHeading({ level: 2 }).run()">H2</button>
    <button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': editor.isActive({ textAlign: 'left' })}"  @click.prevent="editor.chain().focus().setTextAlign('left').run()">
      <img class="editor-icon" src="../assets/left_align.png" />
    </button>
    <button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': editor.isActive({ textAlign: 'center' })}" @click.prevent="editor.chain().focus().setTextAlign('center').run()">
      <img class="editor-icon" src="../assets/center_align.png" />
    </button>
    <button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': editor.isActive({ textAlign: 'right' })}" @click.prevent="editor.chain().focus().setTextAlign('right').run()">
      <img class="editor-icon" src="../assets/right_align.png" />
    </button>
    <button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': editor.isActive('bulletList') }" v-on:click.prevent="editor.chain().focus().toggleBulletList().run()">
      <img class="editor-icon" src="../assets/bullet_list.png" />
    </button>
    <button v-if="!smallFormat && showLink" @click.prevent="setLink" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': editor.isActive('link')}">
      <img class="editor-icon" src="../assets/link.png" />
    </button>
    <input v-if="!smallFormat" type="color" style="margin:auto;" @input="editor.chain().focus().setColor($event.target.value).run()" :value="editor.getAttributes('textStyle').color">
  </div>
    <editor-content :class="{'editor__content': !smallFormat, 'editor__content-small': smallFormat, 'disabled': disabled}" class="fit-height blur-editor editor-style" :style="{'min-height': minheight+'px'}" :editor="editor" />
    <div v-if="editor">
      <span v-if="suggestedChars>0">{{$t('entry.recommended_text')+" "+suggestedChars+" "+$t('entry.words_text')}}</span>
      <span v-if="wordLimit>0" class="char-limit-note">{{$t('entry.user_bio')}} <span :style="{color: num_color}">{{ editor.storage.wordCount.words() }}/{{ wordLimit }}</span> {{$t('entry.words_text')}}</span>
    </div>
</div>
</template>
<style scoped>
.disabled{
  background-color: #eee;
}
.char-limit-note{
  float:right;
}
.editor-style{
  width:100%;overflow: auto; margin-top: 6px;padding:10px;
}
.editor-icon{
  height:20px;
}
.blur-editor{
    border: 1px solid #ccc;
}
.blur-editor:focus{
    box-shadow: 0 0 2px 5px #bfdeff;
    border: 1px solid #62b9f5;
}
.tiptap-button{
    color:black;
    background-color: white;
    border:none;
    width: 40px;
    padding: 5px 0px;
    border-radius: 5px;
}
.tiptap-button:hover{
    color: white;
    background-color: gray;
}
.is-active{
    background-color: lightgray;
}


</style>
<script>
import { Editor, EditorContent,VueRenderer } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Superscript from '@tiptap/extension-superscript'
import { Color } from '@tiptap/extension-color'
import Link from '@tiptap/extension-link'
import WordCount from './WordCount.js'
import Mention from '@tiptap/extension-mention'
import tippy from 'tippy.js'

import MentionList from './MentionList.vue'
    export default {
      compatConfig: {
        MODE: 3,
        FEATURE_ID_A: true
      },
    name: "BaseEditor",
     props: {smallFormat: {type: Boolean, default: false},
      disabled: {type: Boolean, default: false},
      showLink: {type: Boolean, default: false},
      minheight: {type: Number, default: 100},
      modelValue: {
        type: String,
        default: '',
      },
      wordLimit: {
        type: Number,
        default: 0
      },
      suggestedChars: {
        type: Number,
        default: 0
      },
      variables: {type: Array, default: []}
    },
     components: {
      EditorContent
    },
    emits: ['update:modelValue'],
     watch: {
      modelValue(value) {
        if (this.editor.getHTML() === value) {
          return
        }
        this.editor.commands.setContent(value, false)
      },
      disabled(value) {
        if(this.editor){
          var self = this;
          this.$nextTick(function(){
            self.editor.setEditable(!value);
          });
        }
      },
    },
  data() {
    return {
      editor: null,
    };
  },
  methods: {
      insertText(text){
        const { state } = this.editor;
        var t = this.editor.getHTML();
        
        var a = state.selection.$anchor.nodeBefore != null? state.selection.$anchor.nodeBefore.text : "";
        var b = state.selection.$anchor.nodeAfter != null? state.selection.$anchor.nodeAfter.text: "";

        var parts = t.split(a+b);
        if(parts.length == 2){
          this.editor.commands.setContent(parts[0]+a+text+b+parts[1]);
        }else{
          this.editor.commands.setContent(t+text);
        }
        this.$emit('update:modelValue', this.editor.getHTML());
      },
      insertVariable(variable){
        var x = '<span data-type="mention" class="mention" data-id="'+variable.id+'" data-label="'+variable.name+'" contenteditable="false">@'+variable.name+'</span>';
        this.insertText(x);
      },
      setLink(){
        const previousUrl = this.editor.getAttributes('link').href
        const url = window.prompt(this.$t("newsletter.link_url"), previousUrl)

        // cancelled
        if (url === null) {
          return
        }

        // empty
        if (url === '') {
          this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
          return
        }

        // update link
        this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
      }

      /*

      return (
        <>
          <button onClick={setLink} className={editor.isActive('link') ? 'is-active' : ''}>
            setLink
          </button>
          <button
            onClick={() => editor.chain().focus().unsetLink().run()}
            disabled={!editor.isActive('link')}
          >
            unsetLink
          </button>
          <EditorContent editor={editor} />
        </>
      )*/
  },

  mounted() {
    this.editor =new Editor({
        parseOptions: {preserveWhitespace: 'full'},
        extensions: [
          StarterKit,
          Color,
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),,
          Underline,
          TextStyle,
          Superscript,
          Link.configure({
            autolink: true,
          }),,
          WordCount.configure({
            limit: this.wordLimit,
          }),
          Mention.configure({
            renderLabel({ options, node }) {
              return `${options.suggestion.char}${node.attrs.label}`
            },
            HTMLAttributes: {
              class: 'mention',
            },
            suggestion: {
              items: ({ query }) => {
                return this.variables.filter(item => item.name && item.name.toLowerCase().includes(query.toLowerCase()))
                /*return [
                  'Lea Thompson', 'Cyndi Lauper', 'Tom Cruise', 'Madonna', 'Jerry Hall', 'Joan Collins', 'Winona Ryder', 'Christina Applegate', 'Alyssa Milano', 'Molly Ringwald', 'Ally Sheedy', 'Debbie Harry', 'Olivia Newton-John', 'Elton John', 'Michael J. Fox', 'Axl Rose', 'Emilio Estevez', 'Ralph Macchio', 'Rob Lowe', 'Jennifer Grey', 'Mickey Rourke', 'John Cusack', 'Matthew Broderick', 'Justine Bateman', 'Lisa Bonet',
                ].filter(item => item.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5)*/
              },

              render: () => {
                let component
                let popup

                return {
                  onStart: props => {
                    component = new VueRenderer(MentionList, {props, editor: props.editor, })
                    if (!props.clientRect) {
                      return
                    }

                    popup = tippy('body', {
                      getReferenceClientRect: props.clientRect,
                      appendTo: () => document.body,
                      content: component.element,
                      showOnCreate: true,
                      interactive: true,
                      trigger: 'manual',
                      placement: 'bottom-start',
                    })
                  },

                  onUpdate(props) {
                    component.updateProps(props)

                    if (!props.clientRect) {
                      return
                    }

                    popup[0].setProps({
                      getReferenceClientRect: props.clientRect,
                    })
                  },

                  onKeyDown(props) {
                    if (props.event.key === 'Escape') {
                      popup[0].hide()

                      return true
                    }
                    return component.ref?.onKeyDown(props)
                  },

                  onExit() {
                    popup[0].destroy()
                    component.destroy()
                  },
                }
              },
            },
          }),
        ],
        content: this.modelValue,
        onUpdate: () => {
          this.$emit('update:modelValue', this.editor.getHTML())
        },
      })
      var self = this;
      this.$nextTick(function(){
        self.editor.setEditable(!self.disabled);
      });
  },
    
  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>