<template>
  <div>
    <div class="rel-grid-container top-spacing">
      <div ></div>
      <div>
          <Tip :tipNumber="7"></Tip>
          <b-alert :show="debug_mode" class="fit-width auto-margin" variant="danger">{{ $t('general.debug_message1') }}<br/>{{ $t('general.debug_message2') }}</b-alert>
        <div class="auto-margin">
          <span class="form-box-title">{{$t('notifications.send_manual')}}</span>
          <div class="form-box grid-auto-1column">
            <b-table class="table-hover-new" small bordered :items="sendOptions" :fields="fields" :tbody-tr-class="rowClass">
              <template v-slot:cell(text)="data" >
                  <span :style="{'font-weight': (sendOptionData[data.item.id]? sendOptionData[data.item.id].enabled : true)? 'bold' :'300'}" v-html="data.item.text"></span>
                </template>
              
            <template v-slot:cell(value)="data" >
              <b-form-checkbox v-if="sendOptionData[data.item.id]? sendOptionData[data.item.id].enabled : true" v-model="sendOptionSelected" :value="data.item.value" class="fit-width auto-margin">
              </b-form-checkbox>
            </template>
            <template v-slot:cell(members)="data" >
               <div v-if="sendOptionData[data.item.id] && sendOptionData[data.item.id].message">
                  <div>{{sendOptionData[data.item.id].message}}</div>
                </div>
              <div v-if="sendOptionData[data.item.id] && sendOptionData[data.item.id].members">
                 <div v-for="member in sendOptionData[data.item.id].members" :key="member.name" class="clickable">
                   <div :style="{color: (member.email && member.email.length==0? 'red' : '')}" v-b-popover.hover.left="member.email && member.email.length>0? member.email : $t('notifications.missing_email')">{{member.name}}</div>
                 </div>
              </div>
               <div v-if="sendOptionData[data.item.id] && sendOptionData[data.item.id].sections">
                 <div v-for="section in sendOptionData[data.item.id].sections" :key="section.name" class="clickable">
                   <div>{{section.name}}:<span v-for="member in section.members" :key="member.name" class="clickable"><span :style="{color: (member.email.length==0? 'red' : ''), 'margin-left': '10px'}" v-b-popover.hover.left="member.email.length>0? member.email : $t('notifications.missing_email')">{{member.name}}</span>{{(member == section.members[section.members.length-1]? "": ",")}}</span>
                   </div>
                 </div>
              </div>
               
              </template>
          </b-table>
            <div id="reset-note">{{$t('notifications.reset_note')}}</div>
            <div id="dashboard-picker">
              <span class="center-text" >{{$t('notifications.date_text')}}</span>
              <div v-if="email_date" id="email-date">{{$d(new Date(email_date.split("-").join("/") + " 00:01"), 'day_text')}}</div>
            </div>
            <div id="alert-wrapper">
              <div v-if="showAlert">
                <b-alert
                  variant="danger"
                  dismissible
                  :show="showAlert"
                  @dismissed="showAlert=false"
                >{{alertText}}</b-alert>
              </div>
              <div v-if="showSuccess">
                <b-alert
                  variant="success"
                  dismissible
                  :show="showSuccess"
                  @dismissed="showSuccess=false"
                >{{successText}}</b-alert>
              </div>
            </div>
            <b-button id="send-email-button" class="action-button" v-on:click="sendEmail" variant="clear-blue"><div id="send-email-button-icon" class="email-button"></div><span>{{$t('notifications.send_emails')}}</span></b-button>
          </div>
        </div>
      </div>
      <div class="div-space"></div>
    </div>
    </div>
</template>
<style scoped>
#send-email-button-icon{
  width: 30px!important;height: 30px!important;
}
#send-email-button{
  margin: auto;font-weight: bold;line-height: 30px;
}
#alert-wrapper{
  width:100%;margin-top: 30px;
}
#email-date{
  text-align: center;color: #555;
}
#reset-note{
  font-size: 14px; color: #777;text-align:center;
}
#email_checkboxes {
  color: black;
}
#dashboard-picker {
    margin: 10px auto;
    display: grid;
    margin-top:30px;
    width:400px;
  }
  
</style>
<script>
    export default {
    name: "Notifications",
    watch: {
    email_date: {
      handler: function() {
        this.getEnabledEmails();
      },
      deep: true
    },
     '$i18n.locale': {
        handler: function () {
          this.fields[0].label = this.$t('notifications.select_header');
          this.fields[1].label = this.$t('notifications.notification_email');
          this.fields[2].label = this.$t('notifications.automatically_occurs');
          this.fields[3].label = this.$t('notifications.send_to');

          //this.sendOptionData["share"] = {enabled: true, message: this.$t('notifications.share_message')};
          //this.sendOptionData["followup"] = {enabled: true, message: this.$t('notifications.followup_message')};
          //this.sendOptionData["events"] = {enabled: true, message: this.$t('notifications.share_message')};
          this.setSendOptions();
          this.getEnabledEmails();
     
        }
      },
  },
    data() {
      return {
        notification_time: "11:00AM",
        debug_mode: false,
        meet_day_of_week: 0,
          showAlert:false,
          alertText:"",
          successText:"",
          showSuccess:false,
          orgid:0,
          email_date: "",
         sendOptionSelected: [],
         fields: [
         { key: 'value',
          label: this.$t('notifications.select_header'),
          thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff","text-align":"center" }
        },
         { key: 'text',
          label: this.$t('notifications.notification_email'),
          thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
        },
        { key: 'receiver',
          label: this.$t('notifications.to_who'),
          thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
        },
        { key: 'members',
          label: this.$t('notifications.send_to'),
          thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
        },
        { key: 'occurs',
          label: this.$t('notifications.automatically_occurs'),
          thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
        },
      ],
      sendOptionData: [],
      sendOptions: [],
      }
    },
    methods: {
      rowClass(item) {
        if (!item || !this.sendOptionData[item.id]) return
        if (!this.sendOptionData[item.id].enabled) return 'italizedClass'
      },
      setSendOptions(){
        if(!this.isCorporateConnections){
          this.sendOptions= [
          {
            value: 5,
            text: this.$t("notifications.email_drop_down_1"),
            occurs: this.$t("notifications.email_occurs7"),
            receiver: this.$t("notifications.president_text"),
            id: 'president'
          },
          {
            value: 10,
            text: this.$t("notifications.email_drop_down_10"),
            occurs: this.$t("notifications.email_occurs3", {time: this.notification_time}),
            receiver: this.$t("notifications.managers_text"),
            id: 'performance'
          },
          {
            value: 15,
            text: this.$t("notifications.email_drop_down_15"),
            occurs: this.$t("notifications.email_occurs4", {time: this.notification_time}),
            receiver: this.$t("notifications.managers_text"),
            id: 'speakersmanage'
          },
          {
            value: 12,
            text: this.$t("notifications.email_drop_down_12"),
            occurs: this.$t("notifications.email_occurs4", {time: this.notification_time}),
            receiver: this.$t("notifications.managers_text"),
            id: 'emanage'
          },
          {
            value: 16,
            text: this.$t("notifications.email_drop_down_16"),
            occurs: this.$t("notifications.email_occurs4", {time: this.notification_time}),
            receiver: this.$t("notifications.managers_text"),
            id: 'catmanage'
          },
          {
            value: 18,
            text: this.$t("notifications.email_drop_down_18"),
          occurs: this.$t("notifications.email_occurs4", {time: this.notification_time}),
            receiver: this.$t("notifications.managers_text"),
            id: 'powermanage'
          },
          {
            value: 9,
            text: this.$t("notifications.email_drop_down_9"),
            occurs: this.$t("notifications.email_occurs3", {time: this.notification_time}),
            receiver:  this.$t("notifications.managers_text"),
            id: 'quote'
          },
          {
            value: 17,
            text: this.$t("notifications.email_drop_down_17"),
            occurs: this.$t("notifications.email_occurs7"),
            receiver: this.$t("notifications.managers_text"),
            id: 'attmanage'
          },

          {
            value: 14,
            text: this.$t("notifications.email_drop_down_14"),
            occurs: this.$t("notifications.email_occurs2"),
            receiver: this.$t("notifications.managers_text"),
            id: 'nmanage'
          },
          {
            value: 2,
            text: this.$t("notifications.email_drop_down_2"),
            occurs: this.$t("notifications.email_occurs2"),
            receiver: this.$t("notifications.managers_text"),
            id: 'attendance'
          },
          {
            value: 1,
            text: this.$t("notifications.email_drop_down_3"),
            occurs: this.$t("notifications.email_occurs3", {time: this.notification_time}),
            receiver: this.$t("notifications.featured_speakers"),
            disabled: true,
            id: 'speakers'
          },
          {
            value: 4,
            text: this.$t("notifications.email_drop_down_4"),
            occurs: this.$t("notifications.email_occurs3", {time: this.notification_time}),
            receiver: this.$t("notifications.section_speakers"),
            id: 'preparers'
          },
          {
            value: 19,
            text: this.$t("notifications.email_drop_down_19"),
            occurs: this.$t("notifications.email_occurs4", {time: this.notification_time}),
            receiver: this.$t("notifications.members_text"),
            id: 'powersetup'
          },
          {
            value: 3,
            text: this.$t("notifications.email_drop_down_5"),
            occurs: this.$t("notifications.email_occurs9"),
            receiver: this.$t("notifications.members_text"),
            id: 'bios'
          },
          {
            value: 6,
            text: this.$t("notifications.email_drop_down_6"),
            occurs: this.$t("notifications.email_occurs2"),
            receiver: this.$t("notifications.members_text"),
            id: 'shareSpeakers'
          },
          {
            value: 8,
            text: this.$t("notifications.email_drop_down_8"),
            occurs: this.$t("notifications.email_occurs2"),
            receiver: this.$t("notifications.members_text"),
            id: 'shareSections'
          },
          {
            value: 13,
            text: this.$t("notifications.email_drop_down_13"),
            occurs: this.$t("notifications.email_occurs6"),
            receiver: this.$t("notifications.members_text"),
            id: 'newsletter'
          },
          {
            value: 11,
            text: this.$t("notifications.email_drop_down_11"),
            occurs: this.$t("notifications.email_occurs5", {time: this.notification_time}),
            receiver: this.$t("notifications.members_text"),
            id: 'events'
          },
          {
            value: 7,
            text: this.$t("notifications.email_drop_down_7"),
            occurs: this.$t("notifications.email_occurs2"),
            receiver: this.$t("notifications.members_text"),
            id: 'followup'
          },
          {
            value: 22,
            text: this.$t("notifications.email_drop_down_22"),
            occurs: this.$t("notifications.email_occurs8", {time: this.notification_time}),
            receiver: this.$t("notifications.members_text"),
            id: 'corevalue'
          },
        ];
        }else{
          this.sendOptions= [
          {
            value: 17,
            text: this.$t("notifications.email_drop_down_17"),
            occurs: this.$t("notifications.email_occurs7"),
            receiver: this.$t("notifications.managers_text"),
            id: 'attmanage'
          },

          {
            value: 14,
            text: this.$t("notifications.email_drop_down_14"),
            occurs: this.$t("notifications.email_occurs2"),
            receiver: this.$t("notifications.managers_text"),
            id: 'nmanage'
          },
          {
            value: 2,
            text: this.$t("notifications.email_drop_down_2"),
            occurs: this.$t("notifications.email_occurs2"),
            receiver: this.$t("notifications.managers_text"),
            id: 'attendance'
          },
          {
            value: 1,
            text: this.$t("notifications.email_drop_down_3"),
            occurs: this.$t("notifications.email_occurs3", {time: this.notification_time}),
            receiver: this.$t("notifications.featured_speakers"),
            disabled: true,
            id: 'speakers'
          },
          {
            value: 4,
            text: this.$t("notifications.email_drop_down_4"),
            occurs: this.$t("notifications.email_occurs3", {time: this.notification_time}),
            receiver: this.$t("notifications.section_speakers"),
            id: 'preparers'
          },
          {
            value: 3,
            text: this.$t("notifications.email_drop_down_5"),
            occurs: this.$t("notifications.email_occurs7"),
            receiver: this.$t("notifications.members_text"),
            id: 'bios'
          },
          {
            value: 6,
            text: this.$t("notifications.email_drop_down_6"),
            occurs: this.$t("notifications.email_occurs2"),
            receiver: this.$t("notifications.members_text"),
            id: 'shareSpeakers'
          },
          {
            value: 8,
            text: this.$t("notifications.email_drop_down_8"),
            occurs: this.$t("notifications.email_occurs2"),
            receiver: this.$t("notifications.members_text"),
            id: 'shareSections'
          },
          {
            value: 13,
            text: this.$t("notifications.email_drop_down_13"),
            occurs: this.$t("notifications.email_occurs6"),
            receiver: this.$t("notifications.members_text"),
            id: 'newsletter'
          },
        ];
        }
      },
      getMeetDay() {
      this.$http
        .post("/api/organization/get/meet_day_of_week", {})
        .then(response => {
          if (response.data.success === true) {
            this.meet_day_of_week = (response.data.meet_day_of_week + 1) % 7;
          }
        })
        .catch(() => {});
    },
      sendEmail() {this.successText = "Emails sent to:";
      this.showAlert = false;
      this.showSuccess = false;
      this.alertText = "";
      for (var i = 0; i < this.sendOptionSelected.length; i++) {
        switch (this.sendOptionSelected[i]) {
          case 1:
            this.sendSpeakerEmails();
            break;
          case 2:
            this.sendAttendance();
            break;
          case 3:
            this.sendBioEmails();
            break;
          case 4:
            this.sendPrepareEmail();
            break;
          case 5:
            this.sendScriptsEmail();
            break;
          case 6:
            this.sendShareEmails();
            break;
          case 7:
            this.sendFollowupEmails();
            break;
          case 8:
            this.sendShareSectionEmails();
            break;
          case 9:
            this.sendQuoteEmails();
            break;
          case 10:
            this.sendPerformanceEmail();
            break;
          case 11:
            this.sendEventsEmail();
            break;
          case 12:
            this.sendManageEventsEmail();
            break;
          case 13:
            this.sendNewsletterEmail();
            break;
          case 14:
            this.sendNewsletterManageEmail();
            break;
          case 15:
            this.sendSpeakersManageEmail();
            break;
          case 16:
            this.sendCategoriesManageEmail();
            break;
          case 17:
            this.sendAttendanceManageEmail();
            break;
          case 18:
            this.sendPowerTeamsManageEmail();
            break;
          case 19:
            this.sendPowerTeamsSetupEmail();
            break;
          case 20:
            this.sendTopicSetupEmail();
            break;
          case 21:
            this.sendTopCategoriesEmail();
            break;
          case 22:
            this.sendCoreValueEmail();
            break;
          case 0:
          default:
            break;
        }
      }
      this.sendOptionSelected = [];
    },
    sendGenericEmail(type) {
      this.$http
        .post("/api/organization/" + type + "/email", { date: this.email_date })
        .then(response => {
          if (response.data.success === true && response.data.message) {
            this.showSuccess = true;
            this.successText +=
              (this.successText.at(this.successText.length - 1) == ":"
                ? " "
                : ",") + response.data.message;
          } else if (response.data.success === false && response.data.error) {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        })
        .catch(() => {});
    },
    sendTopCategoriesEmail(){
      this.sendGenericEmail("topcategories");
    },
    sendSpeakersManageEmail(){
      this.sendGenericEmail("speakersmanage");
    },
    sendCategoriesManageEmail(){
      this.sendGenericEmail("categoriesmanage");
    },
    sendQuoteEmails() {
      this.sendGenericEmail("quote");
    },
    sendAttendance() {
      this.sendGenericEmail("attendance");
    },
    sendFollowupEmails() {
      this.sendGenericEmail("followup");
    },
    sendSpeakerEmails() {
      this.sendGenericEmail("speaker");
    },
    sendBioEmails() {
      this.sendGenericEmail("speakerbios");
    },
    sendShareEmails() {
      this.sendGenericEmail("share");
    },
    sendShareSectionEmails() {
      this.sendGenericEmail("sharesection");
    },
    sendScriptsEmail() {
      this.sendGenericEmail("scripts");
    },
    sendPrepareEmail() {
      this.sendGenericEmail("prepare");
    },
    sendPerformanceEmail() {
      this.sendGenericEmail("performance");
    },
    sendEventsEmail() {
      this.sendGenericEmail("events");
    },
    sendManageEventsEmail(){
      this.sendGenericEmail("eventsmanage");
    },
    sendNewsletterEmail(){
      this.sendGenericEmail("newsletter");
    },
    sendNewsletterManageEmail(){
      this.sendGenericEmail("newslettermanage");
    },
    sendAttendanceManageEmail(){
      this.sendGenericEmail("attendancemanage");
    },
    sendPowerTeamsManageEmail(){
      this.sendGenericEmail("powermanage");
    },
    sendPowerTeamsSetupEmail(){
      this.sendGenericEmail("powersetup");
    },
    sendTopicSetupEmail(){
      this.sendGenericEmail("topicsetup");
    },
    sendCoreValueEmail(){
      this.sendGenericEmail("corevalue");
    },
    getEnabledEmails() {
      this.$http.post("/api/organization/enabled/emails", { date: this.email_date })
        .then(response => {
          if (response.data.success === true) {
            this.notification_time = response.data.emails.organization.notification_time;
            this.setSendOptions();
            this.sendOptionData = response.data.emails;
            this.sendOptionData["shareSpeakers"] = {enabled: this.sendOptionData["speakers"].sharefile, message: this.sendOptionData["speakers"].sharefile? this.$t('notifications.share_message') : this.$t('notifications.disabled_presentations')};
            this.sendOptionData["shareSections"] = {enabled: this.sendOptionData["preparers"].sharefile, message: this.sendOptionData["preparers"].sharefile? this.$t('notifications.share_message') : this.$t('notifications.disabled_presentations')};
            var eventsEnabled = this.sendOptionData["emanage"].members.length>0;
            this.sendOptionData["events"] = {enabled: eventsEnabled, message: eventsEnabled? this.$t('notifications.share_message'): this.sendOptionData["emanage"].message};
            var newsEnabled = this.sendOptionData["nmanage"].members.length>0;
            this.sendOptionData["newsletter"] = {enabled: newsEnabled, message: newsEnabled? this.$t('notifications.share_message') : this.sendOptionData["nmanage"].message};
            this.debug_mode = response.data.emails.debug_mode==1;
            if(response.data.emails.organization.quote_to_speakers>0){
              this.sendOptions[6].receiver= this.$t("notifications.featured_speakers");
            }

            if(response.data.emails.organization.disable_events_email>0){//15
              this.sendOptions[15].occurs = (response.data.emails.organization.layout.indexOf("22")>=0? this.$t('notifications.disabled_newsletter') : this.$t('notifications.disabled_text'));
            }
            if(response.data.emails.organization.disable_share_sections>0){//13
              this.sendOptions[13].occurs = (response.data.emails.organization.layout.indexOf("6")>=0? this.$t('notifications.disabled_newsletter') : this.$t('notifications.disabled_text'));
            }
            if(response.data.emails.organization.disable_share_speakers>0){//12
              this.sendOptions[12].occurs = (response.data.emails.organization.layout.indexOf("5")>=0? this.$t('notifications.disabled_newsletter') : this.$t('notifications.disabled_text'));
            }

            
          }
        })
        .catch(() => {});
    },
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return weekday !== this.meet_day_of_week;
    },
     getDates() {
      this.$http.post("/api/organization/dates", {}).then(response => {
          if (response.data.success === true) {
            this.orgid = response.data.id;
            this.email_date = response.data.next_formatted;
          }
        })
        .catch(() => {});
    },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-notifications"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-notifications"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.setSendOptions();
      this.getDates();
      this.getMeetDay();
    }
}
</script>
