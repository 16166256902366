<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <div >
        <div id="section-selector" class="fit-width">
          <span class="form-box-name">{{$t('organizations.show_region_status')}} </span>
          <b-form-select class="input-vmargins" required v-model="permissions.regionsFilter.subscription_status" :options="statusOptions" />
          <span class="form-box-name">{{$t('organizations.name_text')}} </span>
          <b-form-input v-model="permissions.regionsFilter.name" type="text" class="region-search-input" ></b-form-input>
          <span class="form-box-name">{{$t('organizations.chapter_name')}} </span>
          <b-form-input v-model="permissions.regionsFilter.chapter_name" type="text" class="region-search-input" ></b-form-input>
          <span class="form-box-name">{{$t('organizations.member_email')}} </span>
          <b-form-input v-model="permissions.regionsFilter.member_email" type="text" class="region-search-input" ></b-form-input>
          <span class="form-box-name">{{$t('organizations.country_name')}} </span>
          <b-form-select class="input-vmargins" required v-model="permissions.regionsFilter.country_id" :options="countries" value-field="id" text-field="name"/>
          <span class="form-box-name">{{$t('organizations.type_text')}} </span>
          <b-form-select class="input-vmargins" required v-model="permissions.regionsFilter.core_type" :options="core_types"/>
          <b-button class="action-button fourth-column auto-margin" v-on:click="clearFilters" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_clear')}}</span></b-button>
        </div>
        <b-table class="table-hover-new" small bordered :items="regions" :filter="permissions.regionsFilter" :filter-function="tableFilter" :fields="permissions.country? countryFields : fields" v-on:row-clicked="editRegion">
          <template v-slot:cell(logAs)="data">
            <div class="region-actions-column">
              <b-button :disabled="data.item.subscription_status==1 && permissions.country" class="region-action-button" size="sm" @click.stop="loginAsChapter(data.item)" variant="light-blue-outline">
              {{$t('organizations.login_as')}}
            </b-button>
              <!--<b-button v-if="data.item.subscription_status==0" class="region-action-button" size="sm" @click.stop="inviteRegion(data.item)" variant="light-blue-outline">
              {{$t('organizations.invite_text')}}
            </b-button>-->
              
            </div>
          </template>
          <template v-slot:cell(active)="data">
            {{data.item.active>0? data.item.active : ''}}
          </template>
          <template v-slot:cell(chapters)="data">
            {{data.item.chapters>0? data.item.chapters : ''}}
          </template>
          <template v-slot:cell(startups)="data">
            {{data.item.startups>0? data.item.startups : ''}}
          </template>
          <template v-slot:cell(charge)="data">
            <span v-if="data.item.subscription_status>=0 && data.item.subscription_status< statusList.length" :style="{'color': statusList[data.item.subscription_status].color}">{{statusList[data.item.subscription_status].text}}</span>
          </template>
        </b-table>
        <div v-if="permissions.access" class="submit-box-2 fit-width">
          <b-button class="action-button auto-margin" v-on:click="newRegion" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
          <b-button class="action-button" v-on:click="exportActiveChapters" variant="clear-blue"><div class="import-button"></div><span>{{$t('organizations.export_active')}}</span></b-button>
          <div v-if="exportFile.filename.length>0 && showSuccess" class="fit-width auto-margin"> 
              <b-button class="action-button" v-on:click="downloadChapterExport" variant="clear-blue" ><div class="import-button"></div><span>{{$t('attendance.download_export')}}</span></b-button>
              <a id="export-file" :download="exportFile.filename" :href="exportFile.content" hidden></a>
            </div>
        </div>
      </div> 
      <b-modal id="modal-center" v-model="showInviteSent" centered :title="$t('organizations.invitation_title')">
            <div>
              <span>{{$t('organizations.region_invitation_sent')}}:</span>
              <br/><br/>
              <div v-for="contact in sent_contacts" :key="contact.email">
                  {{contact.name+" ("+contact.email+")"}}
              </div>
            </div>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="action-button" v-on:click="showInviteSent = false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal id="modal-center" v-model="showInviteModal" centered :title="$t('organizations.invite_confirm_title')">
            <div>
              {{$t('organizations.invite_region_confirm_text')}}
            </div>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="action-button" v-on:click="showInviteModal = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="action-button" v-on:click="inviteConfirmed" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_confirm')}}</span></b-button>
            </div>
          </b-modal>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
.region-action-button{
  position:relative;font-size:16px;
}
.region-actions-column{
  display:grid; grid-template-columns: repeat(2,minmax(auto,100px)); grid-gap: 20px;
}
.region-search-input{
  width:300px;max-width: 300px;
}
#section-selector{
  display:grid; grid-template-columns:repeat(4,max-content);margin:auto;margin-bottom:30px; grid-gap:10px;
}
</style>
<script>
  import router from "@/router"
    export default {
    name: "Regions",
    data() {
      return {
        countries: [],
        showInviteModal: false,
        inviteRegionId: 0,
         showInviteSent: false,
         sent_contacts: [],
        regions: [],
        core_types: [{text: 'All', value: -1}, {text: this.$t('organizations.non_core'), value: 0},{text: this.$t('organizations.core_text'), value: 1}],
        statusOptions: [{text: 'All', value: -1},{text: this.$t('subscription.imported_text'), value: 0},{text: this.$t('subscription.pilot_text'), value: 1},{text: this.$t('subscription.trial_text'), value: 2},{text: this.$t('subscription.active_text'), value: 3},{text: this.$t('subscription.inactive_text'), value: 4}],
        statusList: [{text: this.$t('subscription.imported_text'), value: 0,color: '#000000'},{text: this.$t('subscription.pilot_text'), value: 1,color: '#15d406'},{text: this.$t('subscription.trial_text'), value: 2,color: '#15d406'},{text: this.$t('subscription.active_text'), value: 3,color: '#15d406'},{text: this.$t('subscription.inactive_text'), value: 4,color: '#ed0942'}],
        moving:false,
        fields: [{
              key: 'name',
              label: this.$t('region.name_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'country',
              label: this.$t('region.country_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            { key: 'active',
              label: '# '+this.$t('region.active_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
            { key: 'chapters',
              label: '# '+this.$t('region.total_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
            { key: 'startups',
              label: '# '+this.$t('region.startup_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
            { key: 'charge',
              label: this.$t('region.status_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
             { key: 'logAs',
              label: "",
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
          ],
          countryFields: [{
              key: 'name',
              label: this.$t('region.name_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'active',
              label: '# '+this.$t('region.active_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
            { key: 'chapters',
              label: '# '+this.$t('region.total_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
            { key: 'startups',
              label: '# '+this.$t('region.startup_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
            { key: 'charge',
              label: this.$t('region.status_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
             { key: 'logAs',
              label: "",
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
          ],
          exportFile: {filename: '', content: ''},
          
      }
    },
    methods: {
      downloadChapterExport(){
        document.getElementById("export-file").click();
      },
      exportActiveChapters(){
        this.$http.post("/api/superadmin/activechapters/export", {}).then((response) => {
          if (response.data.success === true) {
            this.exportFile = response.data.file;
            this.showSuccess = true;
          }else{
            this.showAlert = true;
          }
        }).catch(() => { });
      },
        clearFilters(){
            this.permissions.regionsFilter= {name: '', chapter_name: '', subscription_status: -1,member_email: "", country_id: 0, core_type : -1};
        },
        tableFilter(row, filter){
          //.filter(v => (permissions.regionFilter== '' || v.name.toLowerCase().includes(permissions.regionFilter.toLowerCase())) && (permissions.regionsTypeId==-1 || permissions.regionsTypeId==v.subscription_status))
            if((filter.name.length==0 || row.name.toLocaleLowerCase().includes(filter.name.toLocaleLowerCase())) &&
            (filter.chapter_name.length==0 || row.chapter_names.toLocaleLowerCase().includes(filter.chapter_name.toLocaleLowerCase())) && 
            (filter.member_email.length==0 || row.chapter_emails.toLocaleLowerCase().includes(filter.member_email.toLocaleLowerCase())) && 
            (filter.subscription_status==-1 || row.subscription_status == filter.subscription_status) && 
            (filter.country_id ==0 || row.country_id == filter.country_id) &&
            (filter.core_type == -1 || row.core == filter.core_type)){
                return true;
            }
            return false;
        },
      inviteRegion(org){
         this.inviteRegionId = org.id;
         this.showInviteModal = true;
      },
      inviteConfirmed(){
        if(this.inviteRegionId>0){
          this.$http.post("/api/invite/region", {id: this.inviteRegionId}).then((response) => {
             this.inviteRegionId = 0;
            this.showInviteModal = false;
            if(response.data.success){
              this.showInviteSent = true;
              this.sent_contacts = response.data.contacts;
            }
        }).catch((errors) => {console.log(errors);});
        }
      },
      loginAsChapter(org){
        this.$http.post("/api/login/tochild", {id: org.id}).then((response) => {
            if(response.data.success){
              this.permissions.access = response.data.permissions.access;
              this.permissions.admin = response.data.permissions.admin;
              this.permissions.organization = response.data.permissions.organization;
              this.permissions.user = response.data.permissions.user;
              document.title = this.$t('global.document_title_admin') +" "+ this.permissions.organization.name;
              router.push("/admin/regiondash");
              this.$root.$emit('userChange', "");
            }
        }).catch((errors) => {console.log(errors);});
      },
      getRegions() {
        this.$http.post("/api/superadmin/regions", {}).then((response) => {
          if (response.data.success) {
            this.regions = response.data.regions;
          }
        }).catch(() => {
        });
      },
      newRegion() {
        if (this.permissions.access) {
          router.push("/admin/region/0");
        }
      },
      editRegion(item) {
        if (this.permissions.access) {
          router.push("/admin/region/" + item.id);
        }
      },
      getCountries() {
        this.$http.post("/api/superadmin/countries", {}).then((response) => {
          if (response.data.success) {
            this.countries = response.data.countries;
            this.countries.unshift({id: 0, name: "All"})
          }
        }).catch((e) => {console.log(e);
        });
      },
    },
     destroyed() {
      this.removeClass(document.getElementById("menu-regions"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-regions"), "active-menu");
      this.getCountries();
      this.getRegions();
    }
    }
</script>
