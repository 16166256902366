<template>
  <div v-on:dragover="dragHandler" v-on:dragleave="dragLeaveHandler" v-on:dragenter="dragEnterHandler" class="full-height">
    <Header :useruid="this.$route.params.useruid"  :title="$t('preparer.manage_section')" ></Header>
    <div v-if="needsDate" id="need-date">{{$t('global.need_date')}}</div>
    <div v-if="sectionReturned && sectionFound && !needsDate" id="preparer-box">
      <Tip :tipNumber="24"></Tip>
      <div>
        <form v-on:submit.prevent="uploadFiles" >
          <div id="preparer-descriotion-box">
            <div class="fit-width" id="preparer-section-description">
              <span id="section-name" class="form-box-name">{{$t('preparer.section_name')}}:</span> <b>{{section.section_name}}</b>
            </div>
          </div>

          <div class="preparer-instructions main-colour">{{$t('preparer.instructions1')}}</div>
          <div class="preparer-instructions main-colour">{{$t('preparer.instructions2')}}</div>
          
          <div>
            <span class="form-box-title">{{$t('preparer.settings_box')}}</span>
            <div class="form-box grid-auto-2column">
              <span required  class="form-box-name">{{$t('preparer.topic_text')}}</span><b-form-input  v-model="section.topic" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"  class="form-box-input"></b-form-input>
              <div id="share-files-checkbox">
              <b-form-checkbox  v-model="section.sharefile">{{$t('preparer.share_files')}}</b-form-checkbox>
            </div>
            </div>
          </div>
          
          <div>
            <Fileuploads :obj="section" page="section" :useruid="useruid"></Fileuploads>
          </div>

          <div v-if="showAlert">
            <b-alert variant="danger"
                     dismissible
                     :show="showAlert"
                     @dismissed="showAlert=false">
              {{alertText}}
            </b-alert>
          </div>
          <div v-if="showSuccess">
            <b-alert variant="success"
                     dismissible
                     :show="showSuccess"
                     @dismissed="showSuccess=false">
              {{$t('presentation.thanks_message_1')}}<br />
              {{$t('presentation.thanks_message_2')}}<br /><br />
              {{$t('presentation.thanks_message_3')}}<br /><br />
              {{$t('presentation.thanks_message_4')}}
            </b-alert>
          </div>
          <div v-if="showUpload">
            <b-alert class="alert-line-height" variant="warning" :show="showUpload">
              {{$t('preparer.uploading_text')}}
              <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
          <div class="submit-box-2">
            <b-button v-if="!showUpload && !showSuccess" class="action-button" type="submit" :disabled="showUpload" variant="clear-blue"><div class="save-button"></div><span>{{section.files.filter(v=>v.type==1).length>0? $t('global.button_save_upload') : $t('global.button_save')}}</span></b-button>
          </div>
        </form>
      </div>
      <div class="div-space"></div>
    </div>
    <div v-if="sectionReturned && !sectionFound && !needsDate" class="full-area valign-grid">
        <div></div>
        <div id="preparer-not-found">
            <b-alert variant="warning" :show="!sectionFound" id="preparer-not-found-alert">
                {{$t('preparer.not_found1')}}<br/>
                {{$t('preparer.not_found2')}}
            </b-alert>
        </div>
        <div></div>
    </div>
    <Footer :useruid="this.$route.params.useruid"></Footer>
  </div>
</template>
<style scoped>
#preparer-not-found-alert{
  width: 100%;text-align:center;color:black;
}
#preparer-not-found{
  max-width: 600px;margin:auto;width: 100%;text-align:center;
}
#share-files-checkbox{
  margin-left: 20px;margin-top: 5px;grid-column:2/3;
}
#section-name{
  margin: 0px 5px;
}
#preparer-descriotion-box{
  background-color: #eeeeee;padding: 10px;
}
.preparer-instructions{
  text-align:center;color: rgb(0, 174, 239);
}
#need-date{
  text-align: center;font-size: 20px;margin-top:50px;
}
#preparer-section-description{
  display: grid;grid-template-columns: max-content max-content;margin: auto;
}
#preparer-box{
  top: 40px;position: relative;margin: auto;width: 800px;
}
</style>
<script>
     import Fileuploads from './Fileuploads.vue';
    export default {
    name: "Preparer",
    components: {
      Fileuploads
    },
      data() {
        return {
          needsDate: false,
          presentVariant : "primary",
          subVariant: "outline-primary",
          showSuccess: false,
          saveVariant: 'blue',
          showAlert: false,
          showUpload: false,
          alertText: "",
          load: true,
          organization_name: "",
          section: {name: "", section_name: "", next_meeting: "", files: [], sharefile: false, topic: ""},
          showDrag: false,
          dragTimeout: null,
          dragging: false,
          sectionReturned: false,
          sectionFound: false,
          useruid: '',
        }
      },
    methods: {
      uploadFiles() {
        this.showUpload = true;
        this.saveVariant = "";

        let formData = new FormData();
        var presentationUploads = [];
        for (var i = 0; i < this.section.files.length; i++) {
          if (this.section.files[i].type == 1 && this.section.files[i].file && this.section.files[i].file != null) {
            formData.append("files", this.section.files[i].file);
            this.section.files[i].link = "";
            this.section.files[i].name = this.section.files[i].file.name;
            this.section.files[i].file = null;
            presentationUploads.push( this.section.files[i]);
          }else if (this.section.files[i].link != ""){
            presentationUploads.push( this.section.files[i]);
          }
        }
        formData.append("section_files", JSON.stringify(presentationUploads));
        formData.append("hash", this.section.hash);
        formData.append("sharefile", this.section.sharefile);
        formData.append("topic", this.section.topic);
        formData.append("useruid", this.useruid);
        this.$http.post("/api/organization/section/update/files/byhash"+(this.useruid==''? "" : "/byuseruid"), formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then((response) => {
          this.showUpload = false;
          this.saveVariant = "blue";
          if (response.data.success == true) {
            this.showSuccess = true;
            this.updateFileLinks();
          }
        }).catch(() => {
          this.showUpload = false;
          this.saveVariant = "blue";
          this.$bvToast.show('serverError');
        });
      },

      updateFileLinks(){
        this.$http.post("/api/organization/section/get/files"+(this.useruid==''? "" : "/byuseruid"), { hash : this.section.hash, useruid: this.useruid}).then((response) => {
              if (response.data.success) {
                this.section.files = [];
                response.data.section.sharefile = response.data.section.sharefile==1;
                this.section = response.data.section;
                for (var i = 0; i < this.section.files.length; i++) {
                  this.$set(this.section.files[i], 'file', null);
                  this.$set(this.section.files[i], 'filename', "");
                }
                if(this.section.files.length == 0){
                  this.section.files.push({ section_id: 0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null , new: true});
                }
              }
            }).catch((errors) => {
              console.log(errors);
            });
      },
          getSectionFiles() {
            this.sectionReturned = false;
            this.sectionFound = false;
            this.section.hash = this.$route.params.h;
            this.$http.post("/api/organization/section/get/files"+(this.useruid==''? "" : "/byuseruid"), { hash : this.section.hash, useruid: this.useruid}).then((response) => {
              if (response.data.success) {
                response.data.section.sharefile = response.data.section.sharefile==1;
                this.section = response.data.section;
                for (var i = 0; i < this.section.files.length; i++) {
                  this.$set(this.section.files[i], 'file', null);
                  this.$set(this.section.files[i], 'filename', "");
                }
                if(this.section.files.length == 0){
                  this.section.files.push({ section_id: 0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null , new: true});
                }
                this.sectionFound = true;
                this.sectionReturned = true;
              } else {
                this.needsDate = response.data.needsDate;
                this.section.files = [];
                this.sectionFound = false;
                this.sectionReturned = true;
              }
            }).catch((errors) => {
              this.sectionFound = false;
              this.sectionReturned = true;
              console.log(errors);
            });
          },
    },
    mounted() {
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.setLanguageIfAvailable();
      this.getSectionFiles();
    }
    }
</script>
