<template>
  <div id="unautherized-page-wrapper">
      <div></div>
     <b-alert id="unautherized-page-alert" variant="warning" :show="true" class="fit-width">
            <span v-html="$t('global.unauthorized_alert')"></span>
    </b-alert>
    <div></div>
  </div>
</template>
<style scoped>
#unautherized-page-alert{
  margin:auto;text-align:center;color: black;
}
#unautherized-page-wrapper{
  height:100%;width:100%;display: grid; grid-template-rows: auto max-content auto;
}
</style>
<script>
export default {
  name: "Unauthorized",
}
</script>
