<template>
  <div id="app">
    <router-view/>
    <!--<div v-if="!cookieChoiceMade" id="cookies-choice">
    </div>-->
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
    return {
      cookieChoiceMade: true
    }
  },
  methods:{
  },
  mounted(){
    if(!this.$cookies.isKey("cookies_enabled")){
      this.cookieChoiceMade = false;
    }
  }
}
</script>

<style>
#cookies-choice{
  width:100%;height:200px;position: absolute;bottom:0;right:0px;
}
#cookie-bar{
  width: 90%;
    height: 50px;
    position: absolute;
    bottom: 10%;
    right: 0px;
    background-color: lightgray;
    border: 1px solid lightgrey;
    left: 5%;
    border-radius: 5px;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
