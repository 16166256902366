<template>
  <div>
    <div class="rel-grid-container top-spacing">
      <div ></div>
      <div>
        <div id="members-text">{{$tc(permissions.organization.official? 'members.members_text' : 'members.applicants_text', members_num)}}</div>
        <div v-if="permissions.organization.official" id="role-select">
          <span class="form-box-name">{{$t('members.show_role')}} </span>
          <b-form-select required v-model="permissions.filterRoleId" :options="roles" value-field="id" text-field="name" class="mb-3 input-vmargins" />
        </div>
        <SetupTip class="setup-tip" :tipNumber="3"/>
        <table v-if="permissions.access" class="table b-table table-bordered table-sm table-hover-new">
          <thead>
            <tr>
              <th class="tableHeader center-text" v-if="!permissions.organization.official">{{$t('members.move_header')}} </th>
              <th class="tableHeader">{{$t('members.name_header')}} </th>
              <th class="tableHeader" v-if="!permissions.organization.official">{{$t('members.city_header')}} </th>
              <th class="tableHeader">{{$t('members.category_header')}} </th>
              <th class="tableHeader">{{$t('members.company_header')}} </th>
              <th v-if="permissions.organization.official" class="tableHeader">{{$t('members.role_header')}} </th>
              <th class="tableHeader center-text" v-if="permissions.admin==1">{{$t('members.admin_header')}}</th>
            </tr>
          </thead> 
          <tbody>
            <tr v-bind:id="'member_'+member.id" class="dragndrop" v-for="member in members" :key="member.id" v-bind:class="{ disabledClass : member.disabled}" v-on:click="editMember(member)">
              <td @click.stop="1==1;" v-if="!permissions.organization.official">
                <b-form-checkbox  class="fit-width auto-margin" v-model="member.move">
                </b-form-checkbox>
              </td>
              <td > <div class="grid-fitfirst-2column"><span>{{member.name}}</span>
                <span :id="'member_login'+member.id" class="member-login image-colour-shift" v-if="permissions.admin==1 && member.has_pass"></span>
                <b-popover :target="'member_login'+member.id" triggers="hover" >
                  <template #title>{{$t('members.has_login')}}</template>
                  <div>{{$t('members.email_text')+': '+member.email}}</div>
                  <div>{{member.access==1? $t('members.member_access1') : (member.management_access==1? $t('members.member_access2') : $t('members.member_access3'))}}</div>
                </b-popover>
              </div>
                </td>
                <td v-if="!permissions.organization.official">
                  {{member.city}}
              </td>
              <td>{{member.category}}</td>
              <td>{{member.company}}</td>
              <td v-if="permissions.organization.official">
                {{member.roles? (member.roles + (member.sub_role.length>0? ", "+$t('member.sub_role')+": "+member.sub_role : '')) : ''}}
                <b-popover :ref="'member_'+member.id" :target="'member_'+member.id" triggers="hover" @show="onPopoverShow(member)">
                  <img id="member-thumbnail" v-if="member.thumbnail && member.thumbnail.length>0" :src="member.thumbnail"/>
                  <span v-if="!(member.thumbnail && member.thumbnail.length>0)">{{$t('members.no_member_photo')}} </span>
                </b-popover>
              </td>
              <td v-if="permissions.admin==1" class="center-text">
                <b-button id="login-as-button" size="sm" @click.stop="selectedMember=member;showLoginAs=true;" variant="light-blue-outline">
                  {{$t('organizations.login_as')}}
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="showAlert">
        <b-alert variant="danger" dismissible :show="showAlert"  @dismissed="showAlert=false">
          {{alertText}}
        </b-alert>
      </div>
      <div v-if="showSuccess">
        <b-alert variant="success" dismissible :show="showSuccess"  @dismissed="showSuccess=false">
          {{$t('members.import_success')}}
        </b-alert>
      </div>
      <div v-if="showUpload">
        <b-alert class="alert-line-height" variant="warning" :show="showUpload">
          {{$t('preparer.uploading_text')}}
          <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
        </b-alert>
      </div>
      <b-modal centered v-model="showLoginAs" :title="$t('members.login_as_title')">
        <div class="center-text" v-html="$t('members.login_as_text')">
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="showLoginAs=false" variant="clear-blue">
            <div class="cancel-button"></div>
            <span>{{$t('global.button_no')}}</span>
          </b-button>
          <b-button class="action-button" v-on:click="loginAsMember" variant="clear-blue">
            <div class="save-button"></div>
            <span>{{$t('global.button_yes')}}</span>
          </b-button>
        </div>
      </b-modal>
      <b-modal centered v-model="showMoveModal" :title="$t('members.move_member')">
            <div class="left-text">
              <span class="form-box-name" >{{$t('region.move_to_chapter')}}:</span><b-form-select id="chapter-select" v-model="move_chapter" :options="startup_chapters" value-field="id" text-field="name"/>
              <div v-if="move_chapter>0">
                {{$t('members.move_confirm')}}:<br/>
                <div v-for="applicant in members.filter(v => v.move)" :key="applicant.id">{{applicant.name}}</div>
              </div>
            </div>
            <div slot="modal-footer" class="fit-content footer-button-style">
              <b-button class="action-button" v-on:click="showMoveModal = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="action-button" v-if="move_chapter>0" v-on:click="moveApplicants" variant="clear-blue"><div class="save-button"></div><span>{{$t('region.button_move')}}</span></b-button>
            </div>
          </b-modal>
      <b-modal id="slide-modal-2"  v-model="showMemberSelection" centered :title="$t('members.import_title')">
        <div >
          <div id="members-selection-filters-box" class="fit-width">
            <span class="form-box-name">{{$t('members.name_header')}}</span>
            <b-form-input v-model="memberFilter.name" type="text" class="medium-input" />
            <span class="form-box-name member-selection-header">{{$t('members.category_header')}}</span>
            <b-form-input v-model="memberFilter.category" type="text" class="medium-input" />
            <span class="form-box-name member-selection-header">{{$t('members.organization_header')}}</span>
            <b-form-input v-model="memberFilter.chapter" type="text" class="medium-input" />
            <b-button class="action-button" @click="clearMemberFilters()" variant="clear-blue">
              <div class="cancel-button"></div>
              <span>
              {{$t('organizations.clear_filters')}}
              </span>
            </b-button>
          </div>
         <b-table class="table-hover-new clickable" small bordered :items="regionMembers" :fields="memberFields" :per-page="perPage" :current-page="currentPage"
          @filtered="onFiltered" :filter="memberFilter" :filter-function="tableFilter">
              <template v-slot:cell(import)="data">
                <b-form-checkbox class="fit-width auto-margin" v-model="data.item.import">
                </b-form-checkbox>
              </template>
        </b-table>
        <b-pagination v-if="totalRows>perPage"  v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0" ></b-pagination>
        <div id="member-exists-note">*{{$t('organizations.member_exists')}}</div>
        </div>
      <div slot="modal-footer" class="submit-box-2">
        <b-button class="action-button" v-on:click="showMemberSelection=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        <b-button class="action-button" v-on:click="importSelectedMembers" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_import')}}</span></b-button>
      </div>
    </b-modal>
      <div class="submit-box-2">
        <b-button v-if="members.filter(v => v.move).length>0 && !permissions.organization.official" class="action-button" v-on:click="showMove" variant="clear-blue"><div class="move-button"></div><span>{{$t('global.button_move')}}</span></b-button>
        <b-button v-if="!onboard && permissions.access && no_logins" class="action-button" v-on:click="showImportMembers" variant="clear-blue"><div class="import-button"></div><span>{{$t('members.assign_members')}}</span></b-button>
        <b-button v-if="!onboard && permissions.access" class="action-button" v-on:click="importPage" variant="clear-blue"><div class="import-button"></div><span>{{$t('members.import_members')}}</span></b-button>
        <b-button v-if="permissions.access" class="action-button" v-on:click="newMember" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
      </div>
      <div class="div-space"></div>
    </div>
    </div>
</template>

<style scoped>
#member-exists-note{
  color:rgba(100,180,100,.8);float:right;
}
.member-selection-header{
  margin-left: 20px;
}
#members-selection-filters-box{
  display:grid; grid-template-columns: repeat(7,max-content);margin:auto;
}
#chapter-select{
  margin: 5px;
}
#member-thumbnail{
  height:150px;width:auto;
}
#login-as-button{
  position:relative;font-size:14px;white-space: nowrap;
}
.member-login{
      width: 20px;
    height: 20px;
    display: inline-block;
    background-size: 100%;
    margin-left: 5px;
    background-image: url("../assets/button-Login.png");
    margin: auto 5px;
}
#role-select{
  display:grid; grid-template-columns:auto auto; width: 400px;margin:auto;
}
#members-text{
  text-align: center;font-size: 20px;padding: 10px;font-weight:bold;
}
</style>
<script>
  import router from "../router"
  
    export default {
    name: "Members",
     props:{
      onboard: Boolean,
    },
    watch: {
      'permissions.filterRoleId': {
        handler: function () {
          this.getMembers();
        },
        deep: true
      },
      '$i18n.locale': {
        handler: function () {
          if(this.roles.length>0){
            this.roles[0].name = this.$t('members.all_roles');
          }
          this.getMembers();
          this.getRoles();
        },
        deep: true
      }
    },
    data() {
      return {
        startup_chapters: [],
        memberFilter: {name: "", category: "", chapter: ""},
        perPage: 10,
        currentPage:1,
        totalRows:0,
        importMembers: [],
        no_logins: 0,
        regionMembers: [],
        allMembers: [],
        showMemberSelection: false,
        showMoveModal: false,
        selectedMember: null,
        showLoginAs: false,
        members: [],
        showUpload: false,
        showAlert: false,
        saveVariant: "",
        alertText: "",
        showSuccess: false,
        members_num: 0,
        roles: [],
        move_chapter: 0,
        memberFields:  [
             { key: 'import',
              label: this.$t('organizations.import_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
              tdClass: "actions-centre"
            },
            
            { key: 'name',
              label: this.$t('members.name_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
              tdClass: "bolded-text"
            },
            { key: 'category',
              label: this.$t('members.category_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            { key: 'company',
              label: this.$t('members.company_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            { key: 'org',
              label: this.$t('members.organization_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
            },
          ],
      }
    },
    methods: {
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        //this.currentPage = 1;
      },
      clearMemberFilters(){
        this.memberFilter= {name: "", category: "", chapter: ""};
      },
      tableFilter(row, filter){
        if((filter.name.length==0 || row.name.toLocaleLowerCase().includes(filter.name.toLocaleLowerCase())) &&
          (filter.category.length==0 || row.category.toLocaleLowerCase().includes(filter.category.toLocaleLowerCase())) &&
          (filter.chapter.length==0 || row.org.toLocaleLowerCase().includes(filter.chapter.toLocaleLowerCase()))){
          return true;
        }
        return false;
      },
      getImportMembers() {
        this.regionMembers = [];
        this.$http.post("/api/organization/members/forimport",{chapter_id: this.permissions.organization.id}).then((response) => {
            for (var i = 0; i < response.data.members.length; i++) {
              response.data.members.import = false;
            }
            this.totalRows = response.data.members.length;
            this.regionMembers = response.data.members;
        }).catch((errors) => {console.log(errors);});
      },
      importSelectedMembers(){
        this.importMembers = this.regionMembers.filter(v => v.import);
        this.$http.post("/api/organization/members/copy", { members: this.importMembers }).then((response) => {
           this.showMemberSelection=false;
           this.$bvToast.toast(this.$t('organizations.members_imported'), {
            variant: 'success',
            solid: true
            });
            this.importMembers = [];
            if (response.data.success) {
             this.getMembers();
            }
          }).catch((errors) => {
             this.showMemberSelection=false;
          this.importMembers = [];
          });
        
      },
      showImportMembers(){
        this.getImportMembers();
        this.showMemberSelection = true;
      },
      moveApplicants(){
        var applicants = this.members.filter(v => v.move);
        this.$http.post("/api/organization/applicants/move", { applicants: applicants, to_chapter: this.move_chapter }).then((response) => {
           this.showMoveModal = false;
            if (response.data.success) {
              
             this.members = this.members.filter(v => !v.move);
             this.members_num = this.members.filter(v => v.disabled==0).length;
            }
          }).catch((errors) => {
             this.showMoveModal = false;
            console.log(errors);
          });
      },
      showMove(){
        this.$http.post("/api/organization/siblings/get", {}).then((response) => {
            if (response.data.success) {
              response.data.chapters.unshift({id: 0, name: this.$t('region.select_chapter')});
              this.startup_chapters = response.data.chapters;
              this.showMoveModal = true;
            }
          }).catch((errors) => {
            console.log(errors);
          });
      },
      loginAsMember(){
        if(this.selectedMember){
          this.$http.post("/api/login/tomember", {id: this.selectedMember.id}).then((response) => {
            if(response.data.success){
              this.permissions.access = response.data.permissions.access;
              this.permissions.admin = response.data.permissions.admin;
              this.permissions.organization = response.data.permissions.organization;
              this.permissions.user = response.data.permissions.user;
              var self = this;
              document.title = this.$t('global.document_title_admin') +" "+ this.permissions.organization.name;
              if(self.permissions.admin ==0 && self.permissions.access == 0){
                  self.$http.post("/api/member/getmanagepermissions", {}).then((response) => {
                    if (response.data.success && response.data.some_access) {
                      router.push("/userdash");
                    }else{
                      router.push("/memberinfo/"+self.permissions.user.uid);
                    }
                  }).catch(() => {
                    router.push("/memberinfo/"+self.permissions.user.uid);
                  });
                }else {
                  router.push("/admin/dashboard");
                }
             }
             this.$root.$emit('userChange', "");
        }).catch((errors) => {console.log(errors);});
        }
        
      },
      onPopoverShow(member) {
        if (!member.gotThumbnail) {
          this.$http.post("/api/member/get/photo", { id: member.id }).then((response) => {
            if (response.data.success) {
              this.$set(member, "thumbnail", response.data.photo);
              this.$set(member, "gotThumbnail", true);
              if(this.$refs['member_'+member.id][0]){
                this.$refs['member_'+member.id][0].updateContent();
              }
            }
          }).catch((errors) => {
            console.log(errors);
          });
        }
      },
      getMembers() {
        this.$http.post("/api/organization/members/withlogindetails" + (this.permissions.filterRoleId > 0 ? "/byrole" : ""), this.permissions.filterRoleId > 0 ? { role_id: this.permissions.filterRoleId} :{}).then((response) => {
            this.members_num = 0;
            for (var i = 0; i < response.data.members.length; i++) {
              response.data.members[i].move = false;
              if (response.data.members[i].disabled===0) {
                this.members_num += 1;
              }
            }
            this.members = response.data.members;
        }).catch((errors) => {console.log(errors);});
      },
      newMember() {
        if (this.permissions.access) {
          router.push("/admin/member/0");
        }
      },
      editMember(item) {
        if (this.permissions.access) {
          router.push("/admin/member/" + item.id);
        }
      },
      importPage() {
        router.push("/admin/import");
      },
      getRoles() {
        this.$http.post("/api/organization/roles", {}).then((response) => {
          if (response.data.success) {
            this.roles = response.data.roles;
            this.roles.unshift({id: 0, name: this.$t('members.all_roles')});
          }
        }).catch(() => { });
      },
      getAllMembers() {
        this.$http.post("/api/members/all", {}).then((response) => {
          if (response.data.success) {
            this.allMembers = response.data.members;
          }
        }).catch(() => { });
      },
      getLoginsAllowed() {
        this.$http.post("/api/organization/get/no_logins", {}).then((response) => {
          if (response.data.success) {
            this.no_logins= response.data.no_logins;
            if(this.no_logins==1){
              this.getAllMembers() ;
            }
          }
        }).catch(() => { });
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-members"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-members"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getRoles();
      this.getMembers();
      this.getLoginsAllowed();
    }
    }
</script>
