<template>
  <div>
    <Header :useruid="this.$route.params.useruid" :title="$t('quote.manage_quote')" :showDate="true"></Header>
    <Header :speakerhash="this.$route.params.useruid" :title="$t('quote.manage_quote')" :showDate="true"></Header>
    <div id="quote-page-wrapper">
      <div class="div-space"></div>
      <div>
        <form v-on:submit.prevent="updateQuote" id="quote-form">
          <div v-if="!expired">
            <span class="form-box-title">{{$t('quote.quote_text')}}</span>
            <div id="quote-form-box" class="form-box">
              <div id="quote-type-options" class="fit-width grid-fit-2column auto-margin">
                <b-form-radio v-model="quote.show_image" value="0">
                    {{$t('quote.use_image')}}
                </b-form-radio>
                <b-form-radio v-model="quote.show_image" value="1">
                    {{$t('quote.use_text')}}
                </b-form-radio>
              </div>
              <div v-if="quote.show_image==0" class="grid-auto-2column">
                <ImageSelector :imageObject="quote" :imageField="'image'" :maxWidth="1000" :maxHeight="1000" :smallImageField="'image'" :clearButton="true"/>
              </div>
              <div v-if="quote.show_image==1" >
                <BaseEditor v-model="quote.content" />
              </div>
              <div v-if="quote.show_image==1" id="quote-source-wrapper">
                  <span id="quote-source-text" class="form-box-name">{{$t('quote.source_text')}}</span><b-form-input v-model="quote.source" :state="quote.source.length>0? null : false" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"></b-form-input>
              </div>
            </div>
          </div>
          
          <div v-if="showAlert">
            <b-alert variant="danger"
                     dismissible
                     :show="showAlert"
                     @dismissed="showAlert=false">
              {{alertText}}
            </b-alert>
          </div>
          <div v-if="showSuccess">
            <b-alert variant="success"
                     dismissible
                     :show="showSuccess"
                     @dismissed="showSuccess=false">
              {{$t('quote.thanks_message_1')}}
            </b-alert>
          </div>
          <div class="submit-box-2" v-if="!expired">
            <b-button v-if="allowDelete && useruid ==''" v-on:click="deleteQuote" class="action-button" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            <b-button  class="action-button" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </form>
      </div>
      <div class="div-space"></div>
    </div>
    <Footer v-if="!expired" :useruid="this.$route.params.useruid"></Footer>
  </div>
</template>
<style scoped>
#quote-source-text{
  white-space: nowrap;
}
#quote-source-wrapper{
  display:grid; grid-template-columns: 30% 70%;margin-top: 10px;
}
#quote-form{
  width: 600px;margin:auto;
}
#quote-page-wrapper{
  top: 40px;position: relative;
}
#quote-form-box{
  grid-template-columns: auto;display: block;
}
#quote-type-options{
  grid-gap: 20px;
}
#presentation-logo-box{
  display: grid;grid-template-columns: max-content max-content max-content;grid-gap: 20px;margin: auto;
}
</style>
<script>
import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
import router from "../router"
import StarterKit from '@tiptap/starter-kit'
    export default {
    name: "Quote",
    components: {
      VueCropper,
    },
    props:{
      chapter_id: {type: Number, default: 0},
      quoteObject: {type: Object, default: {id: -1}}
    },
      data() {
        return {
          image_type: 0,
          maxwidth: 0,
          presentVariant : "primary",
          subVariant: "outline-primary",
          showSuccess: false,
          saveVariant: 'blue',
          showAlert: false,
          alertText: "",
          quote: {id: 0, content: '', source: '', hash: '', image: '', userhash: ''},
          organization_name: "",
          next_meeting: "",
          useruid: '',
          expired: false,
          allowDelete: false
        }
      },
        methods: {
          updateQuote() {
            if(this.quote.show_image==0){
              this.quote.source = "";
              this.quote.content = "";
            }else{
              this.quote.image = null;
            }
            if(this.quote.show_image==1 && (this.quote.source == undefined || this.quote.source == null || this.quote.source.length==0)){
              this.showAlert = true;
              this.alertText = this.$t("region.alert_text");
              return;
            }else if(this.quote.show_image==0 && (this.quote.image == null || this.quote.image == undefined || this.quote.image.length==0)){
              this.showAlert = true;
              this.alertText = this.$t("region.alert_text");
              return;
            }
            if(this.quote.content == undefined || this.quote.content == null){
              this.quote.content = "";
            }

            this.quote.useruid = this.useruid;
            this.quote.org = this.chapter_id;
            this.$http.post("/api/organization/quote/update"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), this.quote).then((response) => {
              this.showUpload = false;
              this.saveVariant = "blue";
              if (response.data.success == true) {
                if(this.useruid == ''){
                  if(this.chapter_id>0){
                    this.quoteObject.id=-1;
                  }else{
                    router.push("/admin/quotes");
                  }
                }else{
                  this.showSuccess = true;
                }
              } else {
                this.showAlert = true;
                this.alertText = response.data.error;
              }
              }).catch((errors) => {
                this.showUpload = false;
                this.saveVariant = "blue";
                this.showAlert = true;
                this.alertText = JSON.stringify(errors);
            });
          },
          deleteQuote(){
            if(this.useruid != ""){
              return;
            }
            this.quote.org = this.chapter_id;
            this.$http.post("/api/organization/quote/delete"+(this.chapter_id>0? "/aschild" : ""), this.quote).then((response) => {
              if (response.data.success) {
                 if(this.chapter_id>0){
                    this.quoteObject.id=-1;
                  }else{
                    router.push("/admin/quotes");
                  }
              }
            }).catch(() => {
            });
          },
          getQuote() {
            this.expired = false;
            this.quote.useruid = this.useruid;
            this.quote.org = this.chapter_id;
            if(this.chapter_id>0){
              this.quote.id = this.quoteObject.id;
            }
            this.$http.post("/api/organization/quote/get"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), this.quote).then((response) => {
              if (response.data.success) {
                  response.data.quote.show_image = (response.data.quote.image)? 0 : 1;
                  this.quote = response.data.quote;
                  this.organization_name = this.quote.name;
                  this.next_meeting = this.quote.next_meeting;
              }else{
                this.showAlert = true;
                this.alertText = this.$t('quote.link_expired');
                this.expired = true;
              }
            }).catch(() => {
             this.showAlert = true;
              this.alertText = this.$t('quote.link_expired');
              this.expired = true;
            });
          },
    },
    mounted() {
      this.setLanguageIfAvailable();
      if(this.$route.params.useruid){
         this.useruid = this.$route.params.useruid;
      }
      if(this.$route.params.id){
         this.quote.id = this.$route.params.id;
         this.allowDelete = true;
      }
      this.getQuote();
    }
}
</script>
