<template>
  <div class="full-height">
    <Header :speakerhash="this.$route.params.h" :showDate="!isTopic" :title="$t(pageType +'.speaker_setup')" ></Header>
    <div  v-if="speakerFound && speakerReturned" id="presentation-main-wrapper">
      <Tip :tipNumber="23"></Tip>
      <div>
        <form v-on:submit.prevent="checkFileThenupload" id="presentation-form">
          <div id="presentation-info-box">
            <div class="fit-width" id="speaker-description">
              <span id="speaker-name" class="form-box-name">{{$t(pageType +'.speaker_name')}}:</span>
              <b>{{presentation.name}}</b>
              <span v-if="presentation.uploaded" id="last-updated" class="form-box-name">{{$t(pageType +'.last_updated')}}:</span>
              <span v-if="presentation.uploaded">{{$d(new Date(presentation.uploaded.split("-").join("/").replace("Z", " GMT")), 'long')}}</span>
            </div>
          </div>
          <div class="presentation-instruction">{{$t(pageType +'.instructions1')}}</div>
          <div class="presentation-instruction">{{$t(pageType +'.instructions2')}}</div>
         
          <div v-if="!isTopic">
            <span class="form-box-title">{{$t(pageType +'.speaker_bio')}}</span>
            <div class="form-box" id="presentation-bio-wrapper">
              <BaseEditor v-model="presentation.bio" :charLimit="!isTopic && bio.cap_words == 1? bio.max_words : 0" :suggestedChars="bio.recommended_words"/>
            </div>
            <span v-if="isCorporateConnections" class="form-box-title">{{$t(pageType +'.topic_audience')}}</span>
            <div v-if="isCorporateConnections" class="form-box" >
              <span class="form-box-name">{{$t('presentation.topic_text')}}</span><b-form-input v-model="presentation.topic" max="255" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
                <span class="form-box-name">{{$t('presentation.target_audience')}}</span><b-form-textarea id="target-audience" v-model="presentation.target_audience" placeholder="" :rows="3" class="form-box-input"/>
                <span class="form-box-name">{{$t('presentation.value_proposition')}}</span><b-form-textarea v-model="presentation.value_proposition" placeholder="" :rows="3" class="form-box-input"/>
              </div>
          </div>
           <div>
            <Fileuploads :obj="presentation" :page="pageType" :useruid="useruid"></Fileuploads>
          </div>
          <div v-if="showAlert">
            <b-alert variant="danger"
                     dismissible
                     :show="showAlert"
                     @dismissed="showAlert=false">
              <span v-html="alertText"></span>
            </b-alert>
          </div>
          <div v-if="showSuccess">
            <b-alert variant="success"
                     dismissible
                     :show="showSuccess"
                     @dismissed="showSuccess=false">
              {{$t(pageType +'.thanks_message_1')}}<br />
              {{$t(pageType +'.thanks_message_2')}}<br /><br />
              {{$t(pageType +'.thanks_message_3')}}<br /><br />
              {{$t(pageType +'.thanks_message_4')}}
            </b-alert>
          </div>
          <div v-if="showUpload">
            <b-alert class="alert-line-height" variant="warning" :show="showUpload">
              {{$t(pageType +'.uploading_text')}}
              <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
          <div class="submit-box-2">
            <b-button v-if="!showUpload && !showSuccess" class="action-button" type="submit" :disabled="showUpload" variant="clear-blue"><div class="save-button"></div><span>{{presentation.type==1? $t('global.button_save_upload') : $t('global.button_save')}}</span></b-button>

          </div>
        </form>
      </div>
      <div class="div-space"></div>
    </div>
    <div v-if="!speakerFound && speakerReturned" class="full-area valign-grid">
      <div></div>
    <div id="presentation-not-found">
        <b-alert variant="warning" :show="!speakerFound" id="presentation-not-found-alert">
            {{$t(pageType +'.not_found1')}}<br/>
            {{$t(pageType +'.not_found2')}}
        </b-alert>
    </div>
    <div></div>
    </div>
    <Footer :speakerhash="this.$route.params.h"></Footer>
  </div>
</template>
<style scoped>
#presentation-not-found-alert{
  width: 100%;text-align:center;color:black;
}
#presentation-not-found{
  max-width: 600px;margin:auto;width: 100%;text-align:center;
}
#presentation-info-box{
  background-color: #eeeeee;padding: 10px;
}
#presentation-form{
  width: 800px;margin:auto;
}
#presentation-main-wrapper{
  top: 40px;position: relative;
}
#target-audience{
  margin-bottom: 10px;
}
#speaker-name{
  margin: 0px 5px;
}
#last-updated{
  margin: 0px 5px;
}
#presentation-bio-wrapper{
  grid-template-columns: auto;display: block;
}
.presentation-instruction{
  text-align:center;color: rgb(0, 174, 239);
}
#speaker-description{
  display: grid;grid-template-columns: max-content max-content;margin: auto;
}

#presentation-logo-box{
  display: grid;grid-template-columns: max-content max-content max-content;grid-gap: 20px;margin: auto;
}
</style>
<script>
import router from "../router"
import Fileuploads from './Fileuploads.vue';
    export default {
    name: "Presentation",
    components: {
      Fileuploads
    },
    watch: {
      'presentation.type': {
        handler: function () {
          if (!this.load) {
            this.presentation.url = "";
          } else {
            this.load = false;
          }
        },
        deep: true
      },
      
    },
      data() {
        return {
          presentVariant : "primary",
          subVariant: "outline-primary",
          showSuccess: false,
          saveVariant: 'blue',
          showAlert: false,
          showUpload: false,
          alertText: "",
          presentation: { type: -1, url: "", hash: "", bio: "", name: "", day: "", file: null, filename: "", sharefile: 0, topic: "", target_audience: "", value_proposition: "", note: ""},
          load: true,
          organization_name: "",
          showOverMax: false,
          showedOverMax: false,
          bio: { recommended_words: 150, max_words: 200, cap_words: 0 },
          speakerReturned: false,
          speakerFound: false,
          showDrag: false,
          dragTimeout: null,
          dragging: false,
          pageType: 'presentation',
          urlType: 'speaker',
          isTopic: false,
        }
      },
        methods: {
          checkFileThenupload(){
            if(this.presentation.type == 1){
              if(this.presentation.url && this.presentation.file == null){
                this.uploadPP();
                return;
              }
              var input = document.getElementById('fileid');
              if (input.files.length > 0) {
                  var file = input.files[0];
                  var fr = new FileReader();
                  var self = this;
                  fr.onload = function () {
                      self.uploadPP();
                  };
                  fr.onerror = function (e) {
                      if (e.target.error.name == "NotFoundError") {
                          self.showAlert=true;
                          self.alertText = self.$t('global.file_deleted');
                      }
                  }
                  fr.readAsText(file);
              } else {
                  this.showAlert = true;
                  this.alertText = this.$t(pageType +'.presentation_error2');
              }
            }else{
              this.uploadPP();
            }
            
          },
          uploadPP() {
            this.showAlert = false;
            if(this.presentation.type<0 || this.presentation.type===false){
              this.showAlert = true;
              this.alertText = this.$t(this.pageType +'.presentation_error1');
              return;
            }else if(this.presentation.type==2 && this.presentation.url.length == 0){
              this.showAlert = true;
              this.alertText = this.$t(this.pageType +'.presentation_error3');
              return;
            }

            this.showUpload = true;
            
            this.alertText = this.$t(this.pageType +'.alert_text');
            this.saveVariant = "";

            let formData = new FormData();
            var presentationUploads = [];
            for (var i = 0; i < this.presentation.files.length; i++) {
              if (this.presentation.files[i].type == 1 && this.presentation.files[i].file && this.presentation.files[i].file != null) {
                formData.append("files", this.presentation.files[i].file);
                this.presentation.files[i].link = "";
                this.presentation.files[i].name = this.presentation.files[i].file.name;
                this.presentation.files[i].file = null;
                presentationUploads.push( this.presentation.files[i]);
              }else if (this.presentation.files[i].link != ""){
                presentationUploads.push( this.presentation.files[i]);
              }
            }
            formData.append("speaker_files", JSON.stringify(presentationUploads));
            formData.append("type", this.presentation.type);
            formData.append("url", this.presentation.url);
            formData.append("hash", this.presentation.hash);
            formData.append("note", this.presentation.note);
            formData.append("topic", this.presentation.topic);
            formData.append("target_audience", this.presentation.target_audience);
            formData.append("value_proposition", this.presentation.value_proposition);
            formData.append("bio", this.presentation.bio);
            formData.append("uploaded", this.presentation.uploaded);
            formData.append("sharefile", this.presentation.sharefile);

            this.$http.post("/api/organization/"+this.urlType+"/update/byhash", formData, {
              headers: {
                "Content-Type" : "multipart/form-data"
              }
            }).then((response) => {
              this.showUpload = false;
              this.saveVariant = "blue";
              if (response.data.success == true) {
                this.showSuccess = true;
                this.updateFiles();
                //this.presentation.uploaded = new Date().toISOString().replace("T"," ");//full_datetime;
              } else {
                this.showAlert = true;
                this.alertText = response.data.error;
              }
              }).catch((errors) => {
                this.showUpload = false;
                this.saveVariant = "blue";
              this.showAlert = true;
              this.alertText = JSON.stringify(errors);
            });
          },
          updateFiles(){
            this.$http.post("/api/organization/speaker/get", this.presentation).then((response) => {
              if (response.data.success) {
                this.presentation.files= [];
                this.presentation.files = response.data.speaker.files;
                if(!this.presentation.files){
                  this.presentation.files = [];
                }

                for (var i = 0; i < this.presentation.files.length; i++) {
                  this.$set(this.presentation.files[i], 'file', null);
                  this.$set(this.presentation.files[i], 'filename', "");
                }
                if(this.presentation.files.length == 0){
                  this.presentation.files.push({ speaker_id: 0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null, new: true });
                }
              }else{
                this.speakerFound = false;
              }
            }).catch((errors) => {
              
              console.log(errors);
            });
          },
          getPresentation() {
            this.speakerReturned = false;
            this.presentation.hash = this.$route.params.h;
            this.$http.post("/api/organization/speaker/get", this.presentation).then((response) => {
              this.speakerReturned = true;
              if (response.data.success) {
                this.speakerFound = true;
                response.data.speaker.sharefile = response.data.speaker.sharefile==1;
                this.presentation = response.data.speaker;
                if (!this.presentation.bio) {
                  this.presentation.bio = "";
                }
                if(!this.presentation.files){
                  this.presentation.files = [];
                }

                for (var i = 0; i < this.presentation.files.length; i++) {
                  this.$set(this.presentation.files[i], 'file', null);
                  this.$set(this.presentation.files[i], 'filename', "");
                }
                if(this.presentation.files.length == 0){
                  this.presentation.files.push({ speaker_id: 0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null, new: true });
                }
                this.organization_name = response.data.organization_name;
              }else{
                this.speakerFound = false;
              }
            }).catch((errors) => {
              this.speakerReturned = true;
               this.speakerFound = false;
              console.log(errors);
            });
          },
          getBioCap() {
            this.presentation.hash = this.$route.params.h;
            this.$http.post("/api/organization/get/biocap/byspeaker", { hash: this.presentation.hash }).then((response) => {
              if (response.data.success === true) {
                this.bio.recommended_words = response.data.bio.recommended_words;
                this.bio.max_words = response.data.bio.max_words;
                this.bio.cap_words = response.data.bio.cap_words;
              }
            }).catch(() => { });
          }
    },
    mounted() {
      if(this.$route.path.includes('topic')){
        this.pageType = 'topic';
        this.urlType = 'topic';
        this.isTopic = true;
      }
      this.setLanguageIfAvailable();
      this.getBioCap();
      this.getPresentation();
    }
    }
</script>
