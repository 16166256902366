<template>
<div>
    <header >
			<nav class="z-depth-1">
				<div class="nav-wrapper">
					<a v-on:click="goHome()" class="brand-logo left clickable"><img id="terms-logo" src="../assets/logo-MeetingHubwSlogan2020.png"></a>
				</div>
			</nav>
		</header>
    <div class="scaleable-wrapper" id="scaleable-wrapper">
			<div id="main-template" class="z-depth-1" >
				<div>
					<div id="terms-wrapper">
						
	<div class="text-size3 center">MEETING HUB TERMS AND CONDITIONS OF USE</div>
	<p>These Meeting HUB Terms and Conditions of Use form a binding contract between you ("<strong>Client</strong>", "<strong>you</strong>", or "<strong>your</strong>") and MEETING HUB INC. ("<strong>Meeting HUB</strong>", "<strong>we</strong>", or "<strong>us</strong>"). These Terms governs your access to and use of this website and the Software made available on this Site (collectively, the Services”).</p><p></p>
	<p>THESE TERMS TAKES EFFECT WHEN YOU ACCESS OR USE THE SERVICES. BY ACCESSING OR USING THE SERVICES YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THESE TERMS; (B) REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, POWER, AND AUTHORITY TO ENTER INTO THE AGREEMENTS DESCRIBED IN THESE TERMS AND, IF ENTERING INTO AGREEMENTS FOR AN ORGANIZATION, THAT YOU HAVE THE LEGAL AUTHORITY TO BIND THAT ORGANIZATION; AND (C) ACCEPT THESE TERMS AND AGREE THAT YOU ARE LEGALLY BOUND BY THEM.</p>
	<p>IF YOU DO NOT AGREE TO THESE TERMS, YOU MUST NOT ACCESS OR USE THE SERVICES.</p>
<p>1.	<span class="underline">Authorized Access</span></p>
<p>(a)	If you are using or intend to use the Software and have been issued a user name and password, then you or someone on your behalf has executed a Meeting HUB Subscription Agreement (“<strong>Subscription Agreement</strong>”) which governs the cost of and payment for your subscription and access to the Services. Only individuals authorized by us may use the Software and your use requires that we have, pursuant to a valid Subscription Agreement, previously provided you with a user name and password (“<strong>Access Credentials</strong>”) to access the Software.</p>
<p>(b)	Subject to and conditional upon your compliance with these Terms, Meeting HUB hereby grants to you, a non-exclusive, non-sublicensable, non-assignable, royalty-free license to access and use the Services solely for your lawful and legitimate internal business purposes. For clarity, the Services, inclusive of all third party products and/or services, is licensed to you and not sold.</p>
<p>(c)	You agree to fully cooperate with Meeting HUB in connection with Meeting HUB’s provisioning and your use of the Services. You agree that the Services will not be used by you in any manner that: (i) violates, or in a manner that facilitates the violation of, any applicable law, rule or regulation; (ii) violates, or facilitates the violation of, the rights of any third party, including, without limitation, the intellectual property or privacy rights of any third party; (iii) negatively affects Meeting HUB or its systems or networks; or (iv) negatively affects a third party’s use and enjoyment of the Services or any other services provided by Meeting HUB.</p>
<p>(d)	You further agree not to: (i) reproduce, duplicate, copy, sell, resell or exploit access to the Services, use the Services, or any part thereof, including, but not limited to the HTML, Cascading Style Sheet (“CSS”) or any visual design elements contained therein, without the express written permission from Meeting HUB; (ii) modify, reverse engineer, adapt or otherwise tamper with the Services or modify another website so as to falsely imply that it is associated with Meeting HUB or the Services; or (iii) knowingly use the Services to upload, post, host, or transmit unsolicited bulk email “Spam”, short message service “SMS” messages, viruses, self-replicating computer programs “Worms” or any code of a destructive or malicious nature.</p>
<p>2.	<span class="underline">Title</span></p>
<p>(a)	Except as provided herein, Meeting HUB and its third party providers, reserve all right, title and interest in and to the Services and all intellectual property rights contained therein, including but not limited to unpatented inventions, patent applications and patents if any, all design rights, copyrights, trademarks, service marks, trade names, domain name rights, know-how and other trade secrets, and all derivatives thereof. No rights are granted other than the right of access and use, as expressly set forth herein.</p>
<p>(b)	All trademarks, service marks and trade names of Meeting HUB belong to Meeting HUB. All trademarks, service marks and trade names of third parties belong to such third parties. No trademark, service mark or trade name appearing on or as part of the Services may be used by you without the prior written consent and agreement of Meeting HUB or its third party providers, as applicable, which consent is in the complete and unfettered discretion of Meeting HUB or such third party, as applicable.</p>
<p>3.	<span class="underline">Changes to the Services</span></p>
<p>(a)	We reserve the right, in our sole discretion, to make any changes to the Services and materials relating thereto that we deem necessary or useful in order to: (i) maintain or enhance: (A) the quality or delivery of our services to our clients, generally; (B) the competitive strength of or market for our services; or (C) the cost efficiency or performance of the Services; or (ii) to comply with applicable law.</p>
<p>(b)	You acknowledge and agree that the Services may become unavailable to you during periods where: (i) you have been notified that there will be a scheduled downtime to permit servicing or upgrading of the Services; (ii) there is downtime or degradation of the Services resulting from an event of Force Majeure (as described in section 12(f) of these Terms; (iii) any other circumstances beyond our reasonable control which result in downtime or failure of the Services, including use by you of the information technology infrastructure from which you access the Services (your “Systems”), misuse of the hosted services provided by your internet service provider or your use of the Services other than in express compliance with these Terms; and (iv) a suspension or termination of access to, or use of, the Services by you permitted by these Terms or the Subscription Agreement.</p>
<p>4.	<span class="underline">Your Responsibilities</span></p>
<p>(a)	Access Credentials have been generated by Meeting HUB and provided to you for your internal use only and you will not sell, transfer or sublicense them to any other entity or person, with the exception of authorized agents or subcontractors performing work on your behalf. In no event will Meeting HUB be liable for any unauthorized use or misuse of your Access Credentials.</p>
<p>(b)	We will implement appropriate safeguards to assist in the prevention of unauthorized access to the use or disclosure of your data that is used or entered as part of your use of the Services. However, you acknowledge and agree that you have and will retain sole responsibility for: (i) all information, instructions and materials provided by you or on your behalf in connection with the Services; (ii) your Systems; (iii)  the security and use of the Access Credentials used by you and to access the Services; and (iv) all access to and use of the Services indirectly by or through your Systems or using your Access Credentials, whether such access or use was made with or without your knowledge or consent, and including all results obtained from, and all conclusions, decisions and actions based on, such access or use.</p>
<p>(c)	You will employ all physical, administrative and technical controls, screening and security procedures and other safeguards necessary to: (i) securely administer the distribution and use of all Access Credentials used to access the Services and protect against any unauthorized access to, or use of, the Services; and (ii) control the content and use of your data, including the uploading or other provision of data for processing by the Services.</p>
<p>5.	<span class="underline">Termination and Expiry</span></p>
<p>(a)	<span class="underline">Our Rights to Disable Access.</span> We may, directly or indirectly, and by use of a disabling device or any other lawful means, suspend, terminate or otherwise deny access to, or use of, all or any part of the Services or related materials by you or any other person or entity, without incurring any resulting obligation or liability, if: (i) we receive a governmental order that expressly or by reasonable implication requires us to do so; or (ii) we believe, in our sole discretion, that: (A) you have failed to comply with, any material provision of these Terms, accessed or used the Services beyond the scope of the rights granted or for a purpose not authorized under these Terms or in any manner that does not comply with any instruction or requirement we communicate to you; (B) you have been, or are likely to be involved in any fraudulent, misleading or unlawful activities relating to or in connection with the Services; or (C) these Terms expire or are otherwise terminated in connection with your use of Services.</p>
<p>(b)	<span class="underline">Termination by Meeting HUB.</span> In connection with your use of Services, Meeting HUB may terminate these Terms if we fail to receive the applicable subscription fees for your continued use of the Services pursuant to the applicable Subscription Agreement.</p>
<p>(c)	<span class="underline">Termination for Material Breach.</span> Either of us may terminate these Terms with immediate effect by delivering notice of the termination to the other party, if: (i) the other party fails to perform, has made or makes any inaccuracy in, or otherwise materially breaches, any of its obligations, covenants, or representations set out in these Terms and the failure, inaccuracy, or breach continues for a period of 30 calendar days after the injured party delivers notice to the breaching party reasonably detailing the breach; (ii) a dissolution, liquidation, reorganization of debts, proposal to creditors, insolvency, or bankruptcy is made by or in respect of the other party; or (iii) the other party violates any applicable local, provincial or federal laws, including but not limited to applicable export control laws.</p>
<p>(d)	<span class="underline">Expiry.</span> These Terms automatically expire at the end of the period of time for which subscription payments for your use of the Services have been paid to Meeting HUB, as set out under the applicable Subscription Agreement.</p>
<p>(e)	<span class="underline">No Limitation of Rights.</span> This Section 4 does not limit any of our other rights or remedies, whether at law, in equity or under these Terms.</p>
<p>6.	<span class="underline">Effect of Termination or Expiry</span></p>
<p>	Client must immediately cease all use of the Services upon the effective date of the termination or expiry, as set out in Section 4. You will have 30 days from the date of termination or expiry to retrieve any of your data that you wish to keep.</p>
<p>7.	<span class="underline">Limitation of Liability</span></p>
<p>(c)	NEITHER OF US WILL BE LIABLE TO THE OTHER PARTY FOR BREACH-OF-CONTRACT DAMAGES SUFFERED BY THE OTHER PARTY THAT ARE REMOTE OR SPECULATIVE, OR THAT COULD NOT HAVE REASONABLY BEEN FORESEEN. IN NO EVENT WILL MEETING HUB BE LIABLE TO YOU FOR: (A) LOSS OF PRODUCTION, USE, BUSINESS, REVENUE OR PROFIT OR DIMINUTION IN VALUE; (B) IMPAIRMENT, INABILITY TO USE OR LOSS, INTERRUPTION OR DELAY OF THE SERVICES; (C) LOSS, DAMAGE, CORRUPTION OR RECOVERY OF YOUR DATA; (D) BREACH OF DATA OR SYSTEM SECURITY; OR (E) CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, AGGRAVATED, PUNITIVE OR EXEMPLARY DAMAGES, REGARDLESS OF WHETHER WE WERE ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.</p>
<p>(d)	IN NO EVENT WILL OUR AGGREGATE LIABILITY TO YOU UNDER OR IN CONNECTION WITH THESE TERMS OR ITS SUBJECT MATTER, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY AND OTHERWISE, EXCEED $100.00. THE FOREGOING LIMITATION APPLIES NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.</p>
<p>(e)	THE LIMITATIONS SET FORTH IN THIS SECTION 6 APPLY TO ALL CAUSES OF ACTION IN THE AGGREGATE. YOU ACKNOWLEDGE AND AGREE THAT THIS SECTION 6 REPRESENTS A REASONABLE ALLOCATION OF RISK AND THAT, IN THE ABSENCE OF THESE LIMITATIONS OF LIABILITY, THESE TERMS WOULD BE SUBSTANTIALLY DIFFERENT.</p>
<p>8.	<span class="underline">Disclaimers</span></p>
<p>(a)	Meeting HUB makes no representation, warranty or guarantee that the Services: (i) is or will be reliable, suitable, available, accurate or complete; (ii) will operate uninterrupted or error-free, or that it is appropriate for your needs; (iii) is or will be secure, uninterrupted, error-free or operate properly or seamlessly with your computer or other systems, software and/or data. Meeting HUB has no obligation whatsoever to correct any errors or defects in the Services.</p>
<p>(b)	The Services are provided “as is” and Meeting HUB will not have any liability to Region or any Authorized Users for the failure to use the Services on any specific platform or device. Performance and use of the Services may vary from computer to computer, and device to device, and may be affected by a variety of factors, such as your location, the availability or bandwidth available through and/or speed of the Internet connection used.</p>
<p>9.	<span class="underline">Indemnification</span></p>
<p>You agree to hold harmless and indemnify Meeting HUB and its affiliates, officers, agents, and employees from any third-party claim, suit or action (including, without limitation, governmental action, fines or penalties) arising from or related to the use of the Services provided hereunder and/or your violation of these Terms, including any liability or expense arising from claims, losses, damages, suits, judgments, administrative hearings, litigation costs and legal fees.</p>
<p>10.	<span class="underline">General Provisions</span></p>
<p>(a)	<span class="underline">Amendment.</span> These Terms may not be amended, modified or supplemented without the express written consent and agreement of Meeting HUB.</p>
<p>(b)	<span class="underline">Assignment.</span> Client must not assign or otherwise transfer any of its rights, or delegate or otherwise transfer any of its obligations or performance, under these Terms, in each case whether voluntarily, involuntarily, by operation of law or otherwise, without our prior written consent, which consent will not be unreasonably withheld or delayed. For purposes of the preceding sentence, and without limiting its generality, any amalgamation, arrangement or reorganization involving Client will be deemed to be a transfer of rights, obligations or performance under these Terms for which our prior written consent is required. No delegation or other transfer will relieve Client of any of its obligations or performance under these Terms. Any purported assignment, delegation or transfer in violation of this Section 12(b) is void. These Terms are binding upon and enure to the benefit of both Client and Meeting HUB and their respective permitted successors and assigns.</p>
<p>(c)	<span class="underline">Governing Law.</span> These Terms will be governed, construed, and enforced in accordance with the laws of Ontario, Canada, without regard to its conflict of laws rules.</p>
<p>(d)	<span class="underline">Severability.</span> If any part of these Terms is declared unenforceable or invalid, the remainder will continue to be valid and enforceable.</p>
<p>(e)	<span class="underline">Waiver.</span> The failure or neglect by a party to enforce any of the rights under these Terms will not be deemed to be a waiver of that party's rights.</p>
<p>(f)	<span class="underline">Force Majeure.</span> A party will not be liable for any failure of or delay in the performance of these Terms for the period that such failure or delay is: (i) beyond that party’s reasonable control, (ii)  materially affects the performance of any of its obligations under these Terms, and (iii) could not reasonably have been foreseen or provided against; but a party’s failure or delay to perform these Terms will not be excused for reasons resulting solely from general economic conditions or other general market effects.</p>
					</div>
					<br/>
					<div class="footer footer-text-colour center">
						© 2024 BNI Global, LLC <br/>
                        {{$t('login.use_subject')}} <a v-on:click="goTerms()" class="clickable footer-link" target="_blank"><u>{{$t('login.terms_conditions')}}</u></a> {{$t('login.and_text')}} <a class="clickable footer-link" v-on:click="goPrivacy()" target="_blank"><u>{{$t('login.privacy_policy')}}</u></a>.<br/>
                        {{$t('login.powered_by')}} <i>Meeting HUB</i>
					</div>
				</div>
			</div>
		</div>
</div>
</template>
<style scoped>
#terms-wrapper{
    padding:40px;padding-bottom:70px;background-color:#fafafa;
}
#terms-logo{
    height:76px;
}
.left{
    float: left !important;
}
.brand-logo{
    position: absolute;
    color: #fff;
    display: inline-block;
    font-size: 2.1rem;
    padding: 0;
    top: 28px;
    left: 26px;
}
.nav-wrapper{
    position: relative;
    background-color: #FFF!important;
    font-size: 16px;
    height: 117px!important;
    width: 100%;
    margin: auto;
    padding: 10px 125px;
}
html{
        line-height: 1.5;
        font-family: Metropolis-Regular;
    font-weight: normal;
    color: rgba(0,0,0,0.87);
    -webkit-text-size-adjust: 100%;
}
header{
    max-width: 1280px;
    margin: auto;
}
nav{
    position: relative;
    background-color: #FFF!important;
    font-size: 16px;
    height: 117px!important;
    width: 100%;
    margin: auto;
}
@media (min-width: 0){
    #main-template {
        font-size: 14px;
    }
}
@media (min-width: 992px){
    #main-template {
        font-size: 14.5px;
    }
}
@media (min-width: 1200px){
    #main-template {
        font-size: 15px;
    }
}
p{
        display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.scaleable-wrapper{
    overflow: hidden;
}
#main-template{
        width: 1280px;
    position: relative;
    margin: auto;
    max-width: 100%;
}
.z-depth-1{
    -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

.text-size3{
    font-size: 20px;
    line-height: 1.1em;
}
    .center{
        text-align: center;
    }
    .underline{
        text-decoration: underline;
    }
</style>
<script>
     import router from "../router"
    export default {
    name: "Terms",
  data() {
    return {
     
    };
  },
  methods: {
       goHome(){
           router.push("/login");
       },
       goTerms(){
           router.push("/terms");
       },
       goPrivacy(){
           router.push("/privacy");
       }
  },
   mounted() {
      
  },
}
</script>