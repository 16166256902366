<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <div>
        <table class="table b-table table-hover-new table-bordered table-sm">
          <thead>
            <tr>
              <th class="tableHeader">{{$t('contacttype.english_name')}}</th>
              <th class="tableHeader">{{$t('contacttype.name_text')}}</th>
            </tr>
          </thead>
          <draggable tag="tbody" v-model="contacttypes" item-key="id"  @end="endDrag">
            <template #item="{element}">
              <tr class="dragndrop clickable" v-on:click="editContacttype(element)">
                <td >{{ element.name_en }}</td>
                <td>{{ element.name }}</td>
              </tr>
            </template>
          </draggable>
        </table>
          <div id="drag-note">{{$t('contacttype.drag_order')}}</div>
      </div>
      <div class="submit-box-2">
        <b-button v-if="permissions.access" class="action-button" v-on:click="newContacttype" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
      </div>
      <div class="div-space"></div>
    </div>
    <b-modal centered v-model="showTypeEdit" :title="selectedType.id>0? $t('contacttype.type_edit') :$t('contacttype.type_new') " >
        <div id="contact-type-info-box">
          <span class="form-box-name">{{$t('contacttype.name_text')}}</span><b-form-input v-model="selectedType.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input"></b-form-input>
         <b-form-checkbox class="contact-type-checkbox" v-model="selectedType.chapter_assignable">
            {{$t('contacttype.chapter_assignable')}}
          </b-form-checkbox>
          <b-form-checkbox v-if="isCorporateConnections" class="contact-type-checkbox" v-model="selectedType.auto_register">
            {{$t('contacttype.auto_register')}}
          </b-form-checkbox>
        </div>
        <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="deleteType" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          <b-button class="action-button" v-on:click="showTypeEdit = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button"  v-on:click="updateType" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
      </b-modal>
  </div>
</template>
<style scoped>
.contact-type-checkbox{
  grid-column:2/4;
}
 #contact-type-info-box{
  display:grid;
 }
</style>
<script>
    export default {
      name: "ContactTypes",
      data() {
        return {
          contacttypes: [],
          selectedType: {chapter_assignable: true,auto_register: false},
          showTypeEdit: false
        }
      },
      watch: {
      '$i18n.locale': {
        handler: function () {
          this.getContactTypes();
        }
      }
    },
    methods: {
     getContactTypes(){
        this.$http.post("/api/contact/types/get/edit", {}).then((response) => {
          for(var type of response.data.types){
            type.chapter_assignable = type.chapter_assignable==1;
            type.auto_register = type.auto_register==1;
          }
          this.contacttypes = response.data.types;
          
        }).catch(() => {
        });
      },
      deleteType(){
        this.$http.post("/api/contact/types/delete", {id: this.selectedType.id}).then((response) => {
            this.showTypeEdit = false;
            if(response.data.success){
                this.getContactTypes();
            }
            
        }).catch(() => {
            this.showTypeEdit = false;
        });
      },
      updateType(){
        this.$http.post("/api/contact/types/update", this.selectedType).then((response) => {
            this.showTypeEdit = false;
            if(response.data.success){
              this.getContactTypes();
            }
        }).catch(() => {
            this.showTypeEdit = false;
        });
      },
      newContacttype() {
          this.selectedType = {id: 0, name: "", chapter_assignable: true,auto_register: false};
        this.showTypeEdit = true;
      },
      editContacttype(item) {
        this.selectedType = {id: item.id, name: item.name, chapter_assignable: item.chapter_assignable,auto_register: item.auto_register};
        this.showTypeEdit = true;
      },
       endDrag() {
        var ranks = [];
        for (var i = 0; i < this.contacttypes.length; i++) {
          ranks.push({ id: this.contacttypes[i].id, rank: i });
        }
        this.$http.post("/api/contact/types/rank", { ranks: ranks}).then(() => {}).catch(() => {});
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-contacttypes"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-contacttypes"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getContactTypes();
    }
    }
</script>
