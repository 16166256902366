<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <div >
        <b-table class="table-hover-new" small bordered :items="languages" :fields="fields" v-on:row-clicked="editLanguage">
          <template v-slot:cell(flag)="data">
            <img :src="getImgUrl('flags/'+data.item.flag+'.png')" v-bind:alt="data.item.flag+' flag'">
          </template>
        </b-table>
        <div v-if="permissions.access" id="languages-button-wrapper" class="submit-box-2">
          <b-button class="action-button auto-margin" v-on:click="newLanguage" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
        </div>
      </div> 
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
#languages-button-wrapper{
  width:100%;
}
</style>
<script>
  import router from "@/router"
    export default {
    name: "Language",
    data() {
      return {
        languages: [],
        moving:false,
        fields: [
            {
              key: 'name',
              label: this.$t('language.name_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'abbrev',
              label: this.$t('language.abbrev_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
             { key: 'flag',
              label: this.$t('language.flag_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", "text-align":"center" },
              tdClass: "actions-centre"
            },
          ],
      }
    },
    methods: {
      getLanguages() {
        this.$http.post("/api/superadmin/languages", {}).then((response) => {
          if (response.data.success) {
            this.languages = response.data.languages;
          }
        }).catch(() => {
        });
      },
      newLanguage() {
        if (this.permissions.access) {
          router.push("/admin/language/0");
        }
      },
      editLanguage(item) {
        if (this.permissions.access) {
          router.push("/admin/language/" + item.id);
        }
      },
      startDragCat(){
        this.moving = true;
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-languages"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-languages"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.getLanguages();
    }
    }
</script>
